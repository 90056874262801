import React from 'react';
import {
Button,
Modal,
Card,
Col,
Form,
Input,
message,
Row,
Spin,
Table,
Select,
Space,
Popconfirm,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const SpecialAttributeMaster = () => {
const [isModalOpen, setIsModalOpen] = useState(false);
const [saving, setSaving] = useState(false);

// Edit Data for editing existing record
const [editData, setEditData] = useState(null);

const [isLoading, setIsLoading] = useState(null);
const authToken = getToken();
const [Data, setData] = useState([]);
const [dataform] = Form.useForm();
const [showForm, setShowForm] = useState(false);
const [current, setCurrent] = useState(1);
const [pageSize, setPageSize] = useState(10);
const [pageCount, setPageCount] = useState(0);
const [total, setTotal] = useState(0);
const [searchInput, setSearchInput] = useState('');
//options for form
// const [supplierList, setSupplierList] = useState([]);

const [filterCloumnData, setFilterCloumnData] = useState([]);
const [filterInfo, setFilterInfo] = useState({});
const [sortedInfo, setSortedInfo] = useState('');

//filter global
const [searchText, setSearchText] = useState('');
const [filterScope, setFilterScope] = useState('all');
let [filteredData, setFilteredData] = useState([]);

const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
const filterScopeOptionsArray = [
"all",
"specialAttribute_name",
"description",

];

let data1 = [];

const fetchData = async (token) => {
setIsLoading(true);
try {
const response = await fetch(
`${API}/special-attributes?sort[0]=createdAt:desc`,
{
headers: { Authorization: `${BEARER} ${token}` },
}
);
const responseData = await response.json();
if (responseData.error) {
message.error('Something went wrong, please try again');
} else {
// return;
data1 = responseData.data.map((entry) => {




return {
key: entry.id,
id: entry.id,
"specialAttribute_name" : entry.attributes.specialAttribute_name,
"description" : entry.attributes.description,
};
});

console.log('original Data:', responseData);
console.log('current Data after modification:', data1);

setData(data1);
}
} catch (error) {
console.error(error);
message.error('Error while fetching Data!');
} finally {
setIsLoading(false);
}
};

// Disabled: no need to map supplier to fabric
// selecting the dropdowns data
// const fetchSupplier = async (token) => {
// let supplierData2 = [];
// setIsLoading(true);
// try {
// const response = await fetch(`${API}/suppler-masters?`, {
// headers: { Authorization: `${BEARER} ${token}` },
// });
// const responseData = await response.json();
// console.log(responseData);
// if (responseData.error) {
// message.error('Something went wrong, please try again');
// } else {
// supplierData2 = responseData.data.map((entry) => {
// return {
// id: entry.id,
// supplierName: entry.attributes.supplierName,

// // launch_masters: entry.attributes.launch_masters,
// };
// });

// setSupplierList(supplierData2);
// console.log('original supplier Data:', responseData);
// console.log('modified supplier Data:', supplierData2);
// }
// } catch (error) {
// console.error(error);
// message.error('Error while fetching Data!');
// } finally {
// setIsLoading(false);
// }
// }; // end function fetch

useEffect(() => {
if (authToken) {
fetchData(authToken);
// Disabled: no need to map supplier to fabric
}
}, []);

// for opening add or edit form with data or change in the reference of data form when form is closed or page is reloaded
useEffect(() => {
//dataform data
console.log(editData);
dataform.setFieldsValue(editData);
}, [editData, dataform]);

// show the modal
const showModal = () => {
setIsModalOpen(true);
};

// hide and reset add/edit form inside modal
const handleOk = () => {
dataform.resetFields();
setEditData(null);
setIsModalOpen(false);
};

// hide and reset add/edit form inside modal
const handleCancel = () => {
dataform.resetFields();
setEditData(null);
setIsModalOpen(false);
};

//add record to database table by api call
const addNewRecord = async (data) => {
setSaving(true);

//add or removing extra data from values from formData object
const values = {
...data,
};

data = { data: values };

console.log('data:', data);
console.log('values:', values);
// return;
//editId will be present only in case of edit mode
console.log('data edit:', data.data.editId);

if (data.data.editId) {
console.log('edit id :', data.data.editId);
// update the record
try {
const response = await fetch(`${API}/special-attributes/${data.data.editId}`, {
method: 'PUT',
headers: {
'Content-Type': 'application/json',

Authorization: `Bearer ${getToken()}`,
},
body: JSON.stringify(data),
});

const responseData = await response.json();
console.log(responseData);
if (responseData.error) {
message.error('Unable to Update Record.');
} else {
message.success('Data saved successfully!');

fetchData(getToken());
}
} catch (error) {
console.error(Error);
message.error('Error While Updating the Data!');
} finally {
setSaving(false);
dataform.resetFields();
setEditData(null);
setIsModalOpen(false);
}
} else {
// create a new record in the database table
console.log('edit id :', data.editId); //should be falsy

try {
const response = await fetch(`${API}/special-attributes/`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',

Authorization: `Bearer ${getToken()}`,
},
body: JSON.stringify(data),
});
const responseData = await response.json();
console.log(responseData);
if (responseData.error) {
message.error('Unable to Add Record.');
} else {
message.success('Data saved successfully!');
//
fetchData(getToken());
}
} catch (error) {
console.error(Error);
message.error('Error While Updating the Data!');
} finally {
setSaving(false);
dataform.resetFields();
setEditData(null);
setIsModalOpen(false);
}
}
};
//end function addNewRecord

const columns = [

{
key: 'specialAttribute_name',
title: 'Special Attribute Name',
dataIndex: 'specialAttribute_name',
},
{
key: 'description',
title: 'Description',
dataIndex: 'description',
},
//Disabled: no need to map supplier to fabric
// {
// key: 'suppliers',
// title: 'supplier Name',
// dataIndex: 'suppliers',
// render: (_, record) =>
// record.supplierDetails.supplierName
// ? record.supplierDetails.supplierName
// : 'NA',
// },
{
key: 'action',
title: 'Actions',

render: (_, record) => {
return (
<div>
    <div className="ActionColumn">
        <EditOutlined style={{ color: 'black' }} onClick={()=> Edit(record)}
            />
            <Button icon={ <DeleteOutlined style={{ color: 'red' , marginRight: 8 }} />
            }
            onClick={() => Delete(record)}
            />
    </div>
</div>
);
},
},
];

const deleteRecord = async (recordID) => {
setIsLoading(true);
try {
const response = await fetch(`${API}/special-attributes/${recordID}`, {
method: 'DELETE',
headers: {
'Content-Type': 'application/json',

Authorization: `Bearer ${getToken()}`,
},
});
const responseData = await response.json();
console.log(responseData);

if (responseData.error) {
message.error('Something went wrong, please try again');
} else {
fetchData(getToken());
}
} catch (error) {
console.error(error);
message.error('Error while fetching Data!');
} finally {
setIsLoading(false);
}
};

const Delete = (record) => {
Modal.confirm({
title: 'Are you sure you want to delete this',
onOk: () => {
deleteRecord(record.id);
},
});
};

const Edit = (record) => {
console.log(record);
// set data for add or edit form
setEditData({
specialAttribute_name : record?.specialAttribute_name,
description : record?.description,

// Disabled: no need to map supplier to fabric
// suppliers: record.supplierDetails.supplierRefId,
editId: record.id, // its important for edit functionality
});
showModal(); // after setting the data for the edit form show the modal
};

const handleTableChange = (pagination, filters, sorter) => {
console.log('in handle table change', pagination, filters, sorter);
const { order, columnKey } = sorter;
setFilterInfo(filters);
setSortedInfo({ columnKey, order });
};

//reset table
const resetTable = () => {
setSortedInfo({});
setFilterInfo({});
setSearchText('');
setFilterScope('all');
setAlreadySelectedRows([]);
fetchData(authToken);
};

const handleChangeSearch = (e) => {
setSearchText(e.target.value);

if (e.target.value == '') {
fetchData(authToken);
setFilteredData([]);
}
};

useEffect(() => {
if (searchText == '') {
fetchData(authToken);
setFilteredData([]);
} else {
filterGlobalSearch();
}
}, [searchText, filterScope]);

// : on search button click
const filterGlobalSearch = () => {
let filteredDataTemp = [];
if (filterScope == 'all') {
filteredDataTemp = Data?.filter((value) => {
return (
value?.specialAttribute_name?.toLowerCase()?.includes(searchText?.toLowerCase()) || 
value?.description?.toLowerCase()?.includes(searchText?.toLowerCase()) || 
false
);
});
}

// Disabled: no need to map supplier to fabric
// else if (filterScope == 'suppliers') {
// filteredDataTemp = Data.filter((value) => {
// return value.supplierDetails.supplierName
// .toLowerCase()
// .includes(searchText.toLowerCase());
// });
// }

console.log(filteredDataTemp, 'filtered data', filterScope, searchText);
if (filteredDataTemp.length > 0) {
setFilteredData(filteredDataTemp);
} else {
filteredDataTemp.push({
key: 0,
id: 0,
specialAttribute_name : 'Not Found',
description : 'Not Found',
});
setFilteredData(filteredDataTemp);
}
};
const handleFilterScopeChange = (value) => {
console.log('setFilterScope', value);
setFilterScope(value);
};

const getFilterOptionArray = (options) => {
return options.map((option) => {
return { value: option, label: option };
});
};

const filterOptionArray = [...getFilterOptionArray(filterScopeOptionsArray)];


const getFormattedData = () => {
const downloadData =
filteredData && filteredData.length ? filteredData : Data;
// console.log(downloadData, 'downloading data...');

let downloadDataFormatted = downloadData?.map((row) => {
// console.log(row, 'downloading data row...');
return {
ID: row.id,
'Special Attribute Name' : row.specialAttribute_name,
'Description' : row.description,
};
});

// console.log(downloadDataFormatted, 'downloading data 2...');
return downloadDataFormatted;
};

return (
<div>
    <h2>Special Attribute</h2>
    {/* Modal */}
    <Button onClick={showModal}>Add Special Attribute</Button> 

    {/* Table Section */}
    <div className="table">
        {/* Filter search Section */}
        <div className="filterContainer">
            <div className="tableSearchSection">
                <Select disabled={true} defaultValue="all" className="filterScopeSelect" onChange={handleFilterScopeChange}
                    options={filterOptionArray} />

                <Input placeholder="Enter Search Text" onChange={handleChangeSearch} type="text" allowClear
                    value={searchText}></Input>
                <Button onClick={resetTable}>Reset</Button>
                <Button style={{ backgroundColor: 'greenyellow' , color: 'white' }}>
                    <CSVLink data={getFormattedData()} filename={ 'Specialattribute' + "Data-" + moment(new Date()).format('DD-MM-YYYY')}>

                        Download
                    </CSVLink>
                </Button>
            </div>
        </div>
        <Table dataSource={filteredData && filteredData.length ? filteredData : Data} columns={columns}
            onChange={handleTableChange} pagination={{ position: ['bottomCenter'] }} rowSelection={{
            type: 'checkbox' , selectedRowKeys: alreadySelectedRows, onChange: (keys)=> {
            setAlreadySelectedRows(keys);
            },
            onSelect: (record) => {
            console.log(record, 'selected row ');
            },
            selections: [Table.SELECTION_NONE, Table.SELECTION_ALL],
            }}
            />
    </div>
{/* Modal For Add Or Edit */}
<Modal title="Add New SpecialAttribute"  visible={isModalOpen} okButtonProps={{ style: {
    display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }} onOk={handleOk} onCancel={handleCancel}
    width={1000}>
    <Card className="addRecord_page_card">
        <Form form={dataform} layout="vertical" onFinish={addNewRecord}>
            <Row gutter={[16, 16]}>
                <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Special Attribute Name" name="specialAttribute_name">
                    <Input placeholder="Special Attribute Name" />
                </Form.Item>
                </Col>                 
                <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Description" name="description">
                    <Input placeholder="Description" />
                </Form.Item>
                </Col>                 
                <Form.Item label="editId" hidden={true} name="editId">
                    <Input type="text" />
                </Form.Item>
            </Row>

            <Button className="addRecord_save_btn" htmlType="submit" type="primary" size="large">
                {saving ? (
                <>
                    <Spin size="small" /> Saving
                </>
                ) : (
                'Save'
                )}
            </Button>
        </Form>
    </Card>
</Modal>

</div>
);
};
export default SpecialAttributeMaster;
