import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import ItemListDisplay from '../../components/ItemList/ItemListDispaly';
import { getToken } from '../../helpers';
import { CSVLink } from 'react-csv';
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import {
  getMonthNameFromStringDate,
  compareMonth,
  sortRecordsByMonth,
  sortRecordsBySeason,
} from '../../utils/dateUtils';

const OtbRecordList = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(null);

  const authToken = getToken();
  const [Data, setData] = useState([]);

  const [seasonMasterList, setSeasonMasterList] = useState([]);

  const [filterInfo, setFilterInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState('');

  const [searchText, setSearchText] = useState('');
  const [filterScope, setFilterScope] = useState('all');
  let [filteredData, setFilteredData] = useState([]);
  const filterScopeOptionsArray = ['all', 'launchName', 'launchDrop', 'month'];

  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);

  const toOtbRecordDetails = (state) => {
    navigate('/OtbDetails', { state });
  };

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/otb-records?populate[launch_master][populate][season]=true&populate[product_option_qties][populate][product_option_type]=true&sort=createdAt:desc`,
        // `${API}/otb-records?populate[product_option_qties][populate][0]=product_option_type&populate[launch_master]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      console.log(responseData, 'response check');
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;

        data1 = responseData.data.map((entry) => {
          let launchObj = {};
          if (entry?.attributes?.launch_master) {
            let launchMasterObject = entry?.attributes?.launch_master;
            launchObj = {
              launchRefId: launchMasterObject?.data?.id,
              launchName: launchMasterObject?.data?.attributes?.launchName,
              launchCategory: launchMasterObject?.data?.attributes?.launchCat,
              launchMonth: launchMasterObject?.data?.attributes?.launchMonth,
              launchDrop: launchMasterObject?.data?.attributes?.launchDrop,
              launchDate: launchMasterObject?.data?.attributes?.launchDate,
              launchDate: launchMasterObject?.data?.attributes?.launchDate,
              seasonDetails: {
                seasonId:
                  launchMasterObject?.data?.attributes?.season?.data?.attributes
                    ?.season_id,
                seasonRefId:
                  launchMasterObject.data?.attributes?.season?.data?.id,
              },
            };
          }

          let productOptionList = [];
          if (entry?.attributes?.product_option_qties?.data?.length > 0) {
            productOptionList =
              entry?.attributes?.product_option_qties?.data.map(
                (productOptionQtyObject) => {
                  let productOptionObject =
                    productOptionQtyObject?.attributes?.product_option_type;
                  let poqId = productOptionQtyObject?.id;
                  let quantity = productOptionQtyObject?.attributes?.quantity;
                  let poObject = {
                    productCatName:
                      productOptionObject?.data?.attributes
                        ?.productCategoryName,
                    productCatId: productOptionObject?.data?.id,
                  };

                  return { poqId, quantity, poObject };
                }
              );
          }

          return {
            key: entry?.id,
            id: entry?.id,
            month: entry?.attributes?.month,
            type: entry?.attributes?.type,
            status: entry?.attributes?.status,
            totalUnits: entry?.attributes?.totalUnits,
            totalOptionQty: entry?.attributes?.totalOptionQty,
            launchDetails: launchObj,
            productOptionDetails: productOptionList,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);
        // setData(await sortRecordsByMonth(data1, true));
        setData(data1);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
      fetchSeasonList(getToken());
    }
  }, []);

  useEffect(() => {
    if (searchText == '') {
      fetchData(authToken);
      setFilteredData([]);
    } else {
      filterGlobalSearch();
    }
  }, [searchText, filterScope]);

  if (isLoading) {
    return <Spin size="large" />;
  }
  const toProductOptionPage = (state) => {
    navigate('/productOptionList', { state });
  };

  const fetchSeasonList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/seasons?sort=createdAt:ASC`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          season_type: entry?.attributes?.season_type,
          season_year: entry?.attributes?.season_year,
          season_id: entry?.attributes?.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setSeasonMasterList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      key: 'seasonId',
      title: 'Season',
      dataIndex: 'seasonId',
      // sorter: (record1, record2) => {
      //   let r1SeasonId = record1.launchDetails?.seasonDetails?.seasonId;
      //   let r2SeasonId = record2.launchDetails?.seasonDetails?.seasonId;

      //   const sessionArr = [
      //     'SS19',
      //     'SS23',
      //     'SS23',
      //     'AW24',
      //     'SS24',
      //     'AW25',
      //     'SS25',
      //     'AW26',
      //     'SS26',
      //     'AW27',
      //     'SS27',
      //   ];

      //   return sessionArr.indexOf(r1SeasonId) > sessionArr.indexOf(r2SeasonId)
      //     ? true
      //     : false;
      // },

      sorter: async (record1, record2, sortOrder) => {
        // return record1.month?.localeCompare(record2.month);
        // return compareMonth(record1.month, record2.month);
        console.log(sortOrder, 'session sort from header');
        const sortingOrderCustomeFunct = sortOrder == 'ascend' ? true : false;
        const seasonslist = seasonMasterList
          .map((season) => season.season_id)
          .filter((value, index, self) => self.indexOf(value) === index);
        console.log(
          sortOrder,
          sortingOrderCustomeFunct,
          seasonslist,
          'season sort from header'
        );
        setData(
          await sortRecordsBySeason(Data, sortingOrderCustomeFunct, seasonslist)
        );
        return true;
      },
      sortOrder: sortedInfo.columnKey === 'seasonId' && sortedInfo.order,
      render: (_, record) => {
        console.log(record, 241);
        return record?.launchDetails?.seasonDetails?.seasonId;
      },
    },
    {
      key: 'month',
      title: 'Month',
      dataIndex: 'month',
      sorter: async (record1, record2, sortOrder) => {
        // return record1.month?.localeCompare(record2.month);
        // return compareMonth(record1.month, record2.month);
        console.log(sortOrder, 'month sort from header');
        const sortingOrderCustomeFunct = sortOrder == 'ascend' ? true : false;
        console.log(
          sortOrder,
          sortingOrderCustomeFunct,
          'month sort from header'
        );
        setData(await sortRecordsByMonth(Data, sortingOrderCustomeFunct));
        return true;
      },
      // sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: sortedInfo.columnKey === 'month' && sortedInfo.order,
    },
    {
      key: 'launchName',
      title: 'Launch Name',
      dataIndex: 'launchName',
      sorter: (record1, record2) => {
        return record1.launchDetails?.launchName?.localeCompare(
          record2.launchDetails?.launchName
        );
      },
      sortOrder: sortedInfo.columnKey === 'launchName' && sortedInfo.order,
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              toProductOptionPage(record.id);
            }}
          >
            {record.launchDetails?.launchName}
          </a>
        );
      },
    },
    {
      key: 'launchDrop',
      title: 'Launch Drop',
      dataIndex: 'launchDrop',
      render: (_, record) => {
        return record.launchDetails?.launchDrop;
      },
    },

    {
      key: 'launchCategory',
      title: 'Launch Category',
      dataIndex: 'launchCategory',
      render: (_, record) => {
        return record.launchDetails?.launchCategory;
      },
    },
    {
      key: 'productCategories',
      title: 'Product Categories',
      dataIndex: 'productCategories',
      render: (_, record) => {
        return <ItemListDisplay productOptions={record.productOptionDetails} />;
      },
    },
    {
      key: 'totalOptionQty',
      title: 'Total Option Qty',
      dataIndex: 'totalOptionQty',
    },
    {
      key: 'totalUnits',
      title: 'Total Units',
      dataIndex: 'totalUnits',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => {
        switch (record.status) {
          case 'confirmed':
            return 'confirmed';
            break;
          case 'prod_option_generated':
            return 'Option Generated';
            break;
          default:
            return record.status;
            break;
        }
      },
    },
    {
      key: 'action',
      title: 'Actions',

      render: (_, record) => {
        // if (record.status !== 'prod_option_generated') {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <Button
                disabled={record.status === 'prod_option_generated'}
                icon={
                  <DeleteOutlined style={{ color: 'red', marginRight: 8 }} />
                }
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
        // }
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/otb-records/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    toOtbRecordDetails(record);
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    console.log('in handle table change', pagination, filters, sorter);
    const { order, columnKey } = sorter;
    setFilterInfo(filters);
    setSortedInfo({ columnKey, order });
  };

  const resetTable = () => {
    setSortedInfo({});
    setFilterInfo({});
    setSearchText('');
    setFilterScope('all');
    setAlreadySelectedRows([]);
    fetchData(authToken);
  };

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);

    if (e.target.value == '') {
      fetchData(authToken);
      setFilteredData([]);
    }
  };

  const filterGlobalSearch = () => {
    let filteredDataTemp = [];
    if (filterScope == 'all') {
      filteredDataTemp = Data.filter((value) => {
        console.log(value);
        return value.launchDetails?.launchName
          ?.toLowerCase()
          .includes(searchText.trim().toLowerCase()) ||
          value.launchDetails?.launchDrop
            ?.toLowerCase()
            .includes(searchText.trim().toLowerCase()) ||
          value.launchDetails?.launchCategory
            ?.toLowerCase()
            .includes(searchText.trim().toLowerCase()) ||
          value.launchDetails?.seasonDetails?.seasonId
            ?.toLowerCase()
            .includes(searchText.trim().toLowerCase()) ||
          value.month
            ?.toLowerCase()
            .includes(searchText.trim().toLowerCase()) ||
          value?.productOptionDetails?.filter((poqObj) =>
            poqObj?.poObject?.productCatName
              ?.toLowerCase()
              .includes(searchText.trim().toLowerCase())
          )?.length > 0
          ? true
          : false ||
              value.status
                ?.toLowerCase()
                .includes(searchText.trim().toLowerCase());
      });
    } else if (filterScope == 'launchName') {
      filteredDataTemp = Data.filter((value) => {
        return value.launchDetails?.launchName
          ?.toLowerCase()
          .includes(searchText.trim().toLowerCase());
      });
    } else if (filterScope == 'launchDrop') {
      filteredDataTemp = Data.filter((value) => {
        return value.launchDetails?.launchDrop
          ?.toLowerCase()
          .includes(searchText.trim().toLowerCase());
      });
    } else if (filterScope == 'month') {
      filteredDataTemp = Data.filter((value) => {
        return value.month
          ?.toLowerCase()
          .includes(searchText.trim().toLowerCase());
      });
    }

    console.log(filteredDataTemp, 'filtered data', filterScope, searchText);
    if (filteredDataTemp.length > 0) {
      setFilteredData(filteredDataTemp);
    } else {
      filteredDataTemp.push({
        key: 0,
        id: 0,
        month: 'Not Found',
        type: 'Not Found',
        status: false,
        totalUnits: 0,
        totalOptionQty: 0,
        launchDetails: {
          launchRefId: 0,
          launchName: 'Not Found',
          launchCategory: 'Not Found',
          launchMonth: 'Not Found',
          launchDrop: 'Not Found',
          launchDate: 0,
          seasonDetails: {
            seasonId: 'Not Found',
          },
        },
        productOptionDetails: [],
      });
      setFilteredData(filteredDataTemp);
    }
  };

  const handleFilterScopeChange = (value) => {
    console.log('setFilterScope', value);
    setFilterScope(value);
  }; // handle change in filter scope

  const getFilterOptionArray = (options) => {
    return options.map((option) => {
      return { value: option, label: option };
    });
  }; //

  const filterOptionArray = [...getFilterOptionArray(filterScopeOptionsArray)];

  const confirmAllselectedRecords = async () => {
    console.log(alreadySelectedRows, 'confirm all selected records func');
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/otb-record/confirm-otb-records`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          data: { selectedOTBRecords: alreadySelectedRows },
        }),
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        message.success('updated Records successfully');
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const otbRecordStatusLookupObject = {
    active: 'Active',
    wip: 'WIP',
    inactive: 'Inactive',
    droped: 'Dropped',
    confirmed: 'Confirmed',
    prod_option_generated: 'Options Generated',
    deleted: 'Deleted',
  };
  const getDisplayableStatus = (sys_status) => {
    return otbRecordStatusLookupObject[sys_status];
  };

  const getFormattedData = () => {
    const downloadData =
      filteredData && filteredData.length ? filteredData : Data;
    // console.log(downloadData, 'downloading data...');

    let downloadDataFormatted = downloadData?.map((row) => {
      // console.log(row, 'downloading data row...');
      return {
        'Season ID': row?.launchDetails?.seasonDetails?.seasonId,
        Month: row?.month,
        'Launch Name': row?.launchDetails?.launchName,
        'Launch Drop': row?.launchDetails?.launchDrop,
        Type: row?.type,
        'Total Units': row?.totalUnits,
        'Total Option Qty': row?.totalOptionQty,
        'Product Categories': row?.productOptionDetails
          .map((category) => category?.poObject?.productCatName)
          .join(','),
        Status: getDisplayableStatus(row?.status),
      };
    });

    // console.log(downloadDataFormatted, 'downloading data 2...');
    return downloadDataFormatted;
  };
  const generateProductOptionForAllselectedRecords = async () => {
    console.log(alreadySelectedRows, 'confirm all selected records func');
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/otb-record/generate-options`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          data: { selectedOTBRecords: alreadySelectedRows },
        }),
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        if (responseData?.data?.incompleteSuccess.length) {
          message.error(
            'Something went wrong, Please make sure All Selected records are confirmed before generating options'
          );
        } else {
          message.success('completed Product option generation');
        }
        // console.log(responseData);

        // fetchData(authToken);
        resetTable();
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>OTB Records Listing</h2>
      <Button
        onClick={() => {
          toOtbRecordDetails();
        }}
      >
        Add OTB Record
      </Button>

      {/* Table Section */}
      <div className="table">
        {/* Filter search Section */}
        <div className="filterContainer">
          <div className="tableSearchSection">
            <Select
              disabled={true}
              defaultValue="all"
              className="filterScopeSelect"
              onChange={handleFilterScopeChange}
              options={filterOptionArray}
            />

            <Input
              placeholder="Enter Search Text"
              onChange={handleChangeSearch}
              type="text"
              allowClear
              value={searchText}
            ></Input>
            <Button onClick={resetTable}>Reset</Button>
            <Button style={{ backgroundColor: 'greenyellow', color: 'white' }}>
              <CSVLink
                data={getFormattedData()}
                filename={
                  'OTB-Record-list-' + moment(new Date()).format('DD-MM-YYYY')
                }
              >
                Download
              </CSVLink>
            </Button>
          </div>
        </div>
        <Table
          dataSource={filteredData && filteredData.length ? filteredData : Data}
          columns={columns}
          onChange={handleTableChange}
          pagination={{ position: ['bottomCenter'] }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: alreadySelectedRows,
            onChange: (keys) => {
              setAlreadySelectedRows(keys);
            },
            getCheckboxProps: (record) => ({
              disabled: record.status === 'prod_option_generated',
              // Column configuration not to be checked
            }),

            onSelect: (record) => {
              console.log(record, 'selected row ');
            },
            selections: [
              Table.SELECTION_NONE,
              Table.SELECTION_ALL,

              {
                key: 'confirmSelected',
                text: 'Confirm All Selected Rows',
                onSelect: (allkeys) => {
                  console.log('confirmSelected');
                  confirmAllselectedRecords();
                },
              },
              {
                key: 'generateProductOptions',
                text: 'Generate Product Options',
                onSelect: (allkeys) => {
                  console.log('confirmSelected');
                  generateProductOptionForAllselectedRecords();
                },
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default OtbRecordList;
