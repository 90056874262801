import React from 'react'

import { Tabs } from "antd";

import PostponedProductsManagemnt from '../PostponedProductsManagemnt/PostponedProductsManagemnt';
import PostponedProducts from '../PostponedProducts/PostponedProducts';

const { TabPane } = Tabs;


const PostponeManagementPortal = () => {
    return (
        <div>
            <div> PostPone Management Portals</div>
            <div>
                <Tabs>
                    <TabPane tab="Postpone Products Management" key="PostponeProductsManagement">
                        <PostponedProductsManagemnt />
                    </TabPane>
                    <TabPane tab="Postponed Product" key="PostponeManagementPortal">
                        <PostponedProducts />
                    </TabPane>
                </Tabs>
            </div>



        </div>
    )
}

export default PostponeManagementPortal