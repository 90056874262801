import React, { useEffect, useState } from 'react';
import { Form, Spin, message, Input, Button, Row, Col, Select } from 'antd';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';

const FilterForm = ({ onFilter, optionLists }) => {
  const [form] = Form.useForm();
  const [stateValues, setSelectedStateValue] = useState([...optionLists[1]]);
  const [countryValues, setCountryValues] = useState([...optionLists[0]]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountryValue, setSelectedCountryValue] = useState(null);

  console.log('chek function tiype', onFilter, optionLists);

  useEffect(() => {
    if (selectedCountryValue) {
      fetchSelectedState(getToken());
    }
  }, [selectedCountryValue]);

  const onFinish = (values) => {
    console.log('chek function tiype', onFilter);
    onFilter(values);
  };

  const handleCountrySelect = (value) => {
    console.log('selected country ', value);
    setSelectedCountryValue([...value]);
  };

  const fetchSelectedState = async (token) => {
    let stateData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/states?populate[0]=country&filters[country][id][$in]=${selectedCountryValue}`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      console.log('dependent select', responseData);
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        stateData2 = responseData.data.map((entry) => {
          return {
            id: entry.id,
            stateName: entry.attributes.stateName,
          };
        });

        setSelectedStateValue(stateData2);
        console.log('original state Data:', responseData);
        console.log('modified state Data:', stateData2);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  }; // end function fetchSelectedState

  if (isLoading) {
    return <Spin size="large" />;
  }
  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="inline">
        <Row gutter={[24, 24]}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item label="city" name="city">
              <Input placeholder="SearchText" />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item label="country" name="Country">
              <Select
                placeholder="Select a country"
                mode="multiple"
                allowClear
                maxTagCount={2}
                onChange={handleCountrySelect}
              >
                {countryValues.map((opt) => (
                  <Select.Option value={opt.id} key={opt.id}>
                    {opt.countryName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item label="state" name="state">
              <Select
                placeholder="Select a state"
                mode="multiple"
                allowClear
                maxTagCount={2}
              >
                {stateValues.map((opt) => (
                  <Select.Option value={opt.id} key={opt.id}>
                    {opt.stateName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Form.Item style={{ marginTop: 32 }}>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default FilterForm;
