import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Table,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import { v4 as uuidv4 } from 'uuid';

const CountryMaster = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // Edit Data for editing existing record
  const [editData, setEditData] = useState(null);

  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [dataform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/countries?pagination[page]=${current}&pagination[pageSize]=${pageSize}`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;
        data1 = responseData.data.map((entry) => {
          console.log(responseData);
          return {
            id: entry.id,
            countryName: entry.attributes.countryName,
          };
        });
        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);

        setData(data1);

        setTotal(responseData.meta.pagination.total);
        setPageSize(responseData.meta.pagination.pageSize);
        setPageCount(responseData.meta.pagination.pageCount);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
    }
  }, []);

  // for opening add or edit form with data or change in the reference of data form when form is closed or page is reloaded
  useEffect(() => {
    //dataform data
    console.log(editData);
    dataform.setFieldsValue(editData);
  }, [editData, dataform]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  // hide and reset add/edit form inside modal
  const handleOk = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  // hide and reset add/edit form inside modal
  const handleCancel = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };
  // search table
  const handleSearch = async (event) => {
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchData(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(
          `${API}/countries?filters[$or][0][countryName][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const responseData = await response.json();
        if (responseData.error) {
          message.error('Unable to fetch Records, please try again later.');
        } else {
          data1 = responseData.data.map((entry) => {
            return {
              id: entry.id,
              countryName: entry.attributes.countryName,
            };
          });
          console.log('original Data:', responseData);
          console.log('Data:', data1);
          setData(data1);

          setTotal(responseData.meta.pagination.total);
          setPageSize(responseData.meta.pagination.pageSize);
          setPageCount(responseData.meta.pagination.pageCount);
        }
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // search table

  //add record to database table by api call
  const addNewRecord = async (data) => {
    setSaving(true);

    //add or removing extra data from values from formData object
    const values = {
      ...data,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);
    // return;
    //editId will be present only in case of edit mode
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);
      // update the record
      try {
        const response = await fetch(`${API}/countries/${data.data.editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Record.');
        } else {
          message.success('Data saved successfully!');

          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId); //should be falsy

      try {
        const response = await fetch(`${API}/countries/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Record.');
        } else {
          message.success('Data saved successfully!');
          //
          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    }
  };
  //end function addNewRecord

  // on every page change by the user
  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);

    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/countries?pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      if (paginationData.error) {
        message.error('Something went wrong, please try again');
      } else {
        data2 = paginationData.data.map((entry) => {
          return {
            id: entry.id,
            countryName: entry.attributes.countryName,
          };
        });
        console.log('original Data:', paginationData);
        console.log('Data:', data2);
        setData(data2);
        // let page = paginationData.meta.pagination.page;

        setTotal(paginationData.meta.pagination.total);
        setPageSize(paginationData.meta.pagination.pageSize);
        setPageCount(paginationData.meta.pagination.pageCount);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };
  //end function onPageChange

  const columns = [
    {
      key: 'countryName',
      title: 'Country Name',
      dataIndex: 'countryName',
    },
    {
      key: 'action',
      title: 'Actions',
      render: (record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <DeleteOutlined
                style={{ color: 'red' }}
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/countries/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    setEditData({
      countryName: record.countryName,
      editId: record.id, // its important for edit functionality
    });
    showModal(); // after setting the data for the edit form show the modal
  };

  return (
    <div>
      <h2>Country Master</h2>
      <Button onClick={showModal}>Add New Country</Button>
      <div className="FilterSection">
        <form onSubmit={handleSearch} className="searchBarr">
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit"> Search</button>
        </form>
      </div>
      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
        />
      </div>

      <Modal
        title="Add New Country"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addRecord_page_card">
          <Form form={dataform} layout="vertical" onFinish={addNewRecord}>
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Country Name"
                  name="countryName"
                  rules={[
                    {
                      required: true,
                      message: 'Country Name is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Country Name" />
                </Form.Item>
              </Col>

              <Form.Item label="" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>

            <Button
              className="addRecord_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default CountryMaster;
