import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import DynamicSelectOption from '../../components/DynamicSelectOption/DynamicSelectOption';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, Link } from 'react-router-dom';

const SessionManagement = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [designform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const dateFormat = 'YYYY-MM-DD';
  const [optionList, setOptionList] = useState([]);
  const [seasonYearSelected, setSeasonYearSelected] = useState('');
  const [seasonCategorySelected, setSeasonCategorySelected] = useState('');
  const [seasonName, setSeasonName] = useState('');

  // search table
  const handleSearch = async (event) => {
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchDesignMaster(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(
          `${API}/seasons?filters[$or][0][seasonType][$containsi]=${searchValue}&filters[$or][1][season_id][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}&sort=createdAt:desc`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const designMasterData = await response.json();

        data1 = designMasterData.data.map((entry) => {
          return {
            id: entry.id,
            seasonType: entry.attributes.seasonType,
            seasonYear: entry.attributes.seasonYear,
            season_id: entry.attributes.season_id,
          };
        });
        console.log('original Data:', designMasterData);
        console.log('Data:', data1);
        setData(data1);

        setTotal(designMasterData.meta.pagination.total);
        setPageSize(designMasterData.meta.pagination.pageSize);
        setPageCount(designMasterData.meta.pagination.pageCount);
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // search table

  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);

    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/seasons?pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      data2 = paginationData.data.map((entry) => {
        return {
          id: entry.id,
          seasonType: entry.attributes.seasonType,
          seasonYear: entry.attributes.seasonYear,
          season_id: entry.attributes.season_id,
        };
      });
      console.log('original Data:', paginationData);
      console.log('Data:', data2);
      setData(data2);
      // let page = paginationData.meta.pagination.page;

      setTotal(paginationData.meta.pagination.total);
      setPageSize(paginationData.meta.pagination.pageSize);
      setPageCount(paginationData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  let data1 = [];

  const fetchDesignMaster = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/seasons?pagination[page]=1&pagination[pageSize]=${pageSize}&sort=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const designMasterData = await response.json();

      data1 = designMasterData.data.map((entry) => {
        return {
          id: entry.id,
          seasonType: entry.attributes.seasonType,
          seasonYear: entry.attributes.seasonYear,
          season_id: entry.attributes.season_id,
        };
      });
      console.log('original Data:', designMasterData);
      console.log('Data:', data1);
      setData(data1);

      setTotal(designMasterData.meta.pagination.total);
      setPageSize(designMasterData.meta.pagination.pageSize);
      setPageCount(designMasterData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchDesignMaster(authToken);
      // fetchSesson(authToken);
    }
  }, []);

  useEffect(() => {
    console.log(editData);
    designform.setFieldsValue(editData);
  }, [editData, designform]);

  useEffect(() => {
    if (seasonCategorySelected == 'autumn' && seasonYearSelected) {
      let seasonNewName = 'AW' + seasonYearSelected?.format('YY');
      designform.setFieldsValue({
        season_id: seasonNewName,
      });
    } else if (seasonCategorySelected == 'summer' && seasonYearSelected) {
      let seasonNewName = 'SS' + seasonYearSelected?.format('YY');
      designform.setFieldsValue({
        season_id: seasonNewName,
      });
    }

    console.log(seasonName);
  }, [seasonCategorySelected, seasonYearSelected]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const seasonObject = {
    spring: 2,
    summer: 3,
    monsoon: 6,
    autumn: 10,
    'pre-winter': 11,
    winter: 12,
  };

  const addNewProductSpecification = async (data) => {
    setSaving(true);

    console.log(
      data.seasonYear,
      seasonObject[data.seasonType],
      data.seasonYear.format('YYYY-MM-DD'),
      data.seasonYear.format('YYYY'),

      new Date(data.seasonYear, 3, 1),
      new Date(data.seasonYear.format('YYYY'), 3, 1),
      moment(
        new Date(
          data.seasonYear.format('YYYY'),
          seasonObject[data.seasonType],
          1
        )
      ).format('YYYY-MM-DD')
    );

    const values = {
      ...data,
      seasonYear: moment(
        new Date(
          data?.seasonYear?.format('YYYY'),
          seasonObject[data?.seasonType],
          1
        )
      ).format('YYYY-MM-DD'),
    };

    // delete values["Date"];

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);
    // return;

    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(`${API}/seasons/${data.data.editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Season Details.');
        } else {
          message.success('Data saved successfully!');
          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        handleOk();
      }
    } else {
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/seasons/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Season Details.');
        } else {
          message.success('Data saved successfully!');
          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        handleOk();
      }
    }
  };

  const columns = [
    {
      key: 'seasonType',
      title: 'Season Type',
      dataIndex: 'seasonType',
    },
    {
      key: 'season_id',
      title: 'season Name',
      dataIndex: 'season_id',
    },
    {
      key: 'seasonYear',
      title: 'season year',
      dataIndex: 'season_year',
      render: (_, record) => {
        console.log(
          record,
          record?.seasonYear,
          moment(record?.seasonYear).format('YYYY')
        );
        return moment(record?.seasonYear).format('YYYY') ?? 'Invalid Year';
      },
    },

    {
      key: 'action',
      title: 'Actions',
      render: (record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <DeleteOutlined
                hidden={false}
                style={{ color: 'red' }}
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteDesignMasterEntry = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/seasons/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      fetchDesignMaster(getToken());
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteDesignMasterEntry(record.id);

        // setData((pre) => {
        //   return pre.filter((person) => person.id != record.id);
        // });
      },
    });
  };

  const Edit = (record) => {
    setEditData({
      seasonType: record.seasonType,
      seasonYear: moment(record.seasonYear, 'YYYY'),
      season_id: record.season_id,
      editId: record.id,
    });

    showModal();
  };

  return (
    <div>
      <div>Season Management</div>

      <Button onClick={showModal}>Add New Season</Button>
      <div className="FilterSection">
        <Input
          type="text"
          size="large"
          name="searchInput"
          value={searchInput}
          onChange={async (event) => setSearchInput(event.target.value)}
          label="Search"
        />

        <Button onClick={handleSearch}>search</Button>
      </div>

      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
        />
      </div>

      <Modal
        title="Add New Season"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form
            form={designform}
            layout="vertical"
            onFinish={addNewProductSpecification}
          >
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Season Category"
                  name="seasonType"
                  rules={[
                    {
                      required: true,
                      message: 'Season category Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    onChange={(value) => setSeasonCategorySelected(value)}
                  >
                    <Select.Option value="summer">Summer</Select.Option>
                    <Select.Option value="autumn">Autumn</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Season Year"
                  name="seasonYear"
                  rules={[
                    {
                      required: true,
                      message: 'Season Year is Required!',
                      type: 'object',
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(value) => setSeasonYearSelected(value)}
                    picker="year"
                  />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Season Name"
                  name="season_id"
                  rules={[
                    {
                      type: 'string',
                      required: true,
                    },
                  ]}
                >
                  <Input value={seasonName} />
                </Form.Item>
              </Col>

              <Form.Item label="" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default SessionManagement;
