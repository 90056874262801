import React from 'react';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Checkbox,
  Divider,
  Button,
  message,
  Table,
  Typography,
  Col,
  Row,
  Spin,
} from 'antd';
import ListOfItem from '../ListOfItem/ListOfItem';
import { RollbackOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const CheckboxGroup = Checkbox.Group;
const plainOptions = ['SpecsSheet', 'MasterSheet', 'MetaSheet'];
const defaultCheckedList = ['SpecsSheet'];

const GenerateSheets = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [products, setProducts] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [filesCreatedList, setFilesCreatedList] = useState([]);

  console.log(location.state);

  const toProductDetails = (record) => {};
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onPageChange = async (page) => {
    // setIsLoading(true);
    console.log(page, pageSize);
    // setCurrent(page);

    // let data2 = [];
    // try {
    //     const pagin_response = await fetch(`launch-masters/${launch.id}?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`, {
    //         headers: { Authorization: `${BEARER} ${getToken()}` }
    //     });
    //     const paginationData = await pagin_response.json();

    //     data2 = paginationData.data.map(entry => {
    //         return {
    //             id: entry.id,
    //             launchName: entry.attributes.launchName,
    //             launchId: entry.attributes.launchId,
    //             launchDate: entry.attributes.launchDate,
    //             launchMonth: entry.attributes.launchMonth,
    //             launchSeason: entry.attributes.launchSeason,

    //         };
    //     });
    //     console.log("original Data:", paginationData);
    //     console.log("Data:", data2);
    //     setData(data2);
    //     // let page = paginationData.meta.pagination.page;

    //     setTotal(paginationData.meta.pagination.total);
    //     setPageSize(paginationData.meta.pagination.pageSize);
    //     setPageCount(paginationData.meta.pagination.pageCount);

    // } catch (error) {
    //     console.error(error);
    //     message.error("Error while fetching Data!");
    // } finally {
    //     setIsLoading(false);
    // }
  };

  const getAllProducts = async (launchId) => {
    const token = getToken();
    setIsLoading(true);
    // const data1 = [];
    try {
      const response = await fetch(
        `${API}/launch-masters/${launchId}?populate=*`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      console.log('original generate Data:', responseData);
      const data1 = responseData.data.attributes.core_products?.data
        ?.filter((product) => product.attributes.object_status === 'received')
        .map((product) => {
          return {
            id: product.id,
            sku: product.attributes.sku,
            collection: product.attributes.collection,
            description: product.attributes.description,
          };
        });
      console.log('original Data:', responseData);
      console.log('Data:', data1);
      setProducts(data1);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (getToken()) {
      getAllProducts(location.state);
    }
  }, []);

  const GenerateSelectedSheets = async (list) => {
    if (list.length > 0) {
      console.log(list, location.state);

      let sheetList = list;
      let launchId = location.state;
      let token = getToken();
      setIsLoading(true);
      try {
        const response = await fetch(
          `${API}/specssheet-generate?launchInfo=${launchId}&sheetList=${sheetList}`,
          {
            headers: { Authorization: `${BEARER} ${token}` },
          }
        );
        const data = await response.json();
        console.log(data);
        setFilesCreatedList(data.data);
      } catch (error) {
        console.error(error);
        message.error('Error While Getting Logged In User Details');
      } finally {
        setIsLoading(false);
      }
    } else {
      message.error('Please Select at least one item from the list!');
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  const columns = [
    {
      key: 'sku',
      title: 'SKU',

      render: (record) => {
        console.log(record);
        return (
          <div>
            {' '}
            <a
              onClick={() => {
                toProductDetails(record);
              }}
            >
              {record.sku}
            </a>
          </div>
        );
      },
    },
    {
      key: 'collection',
      title: 'Collection',
      dataIndex: 'collection',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
  ];

  const toPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col md={12} lg={12} sm={24} xs={24}>
          <Title>Generate Sheets</Title>
          <Divider />
          <div>
            Generate Respective Sheets For Uploads for{' '}
            <Text strong> Launch : {location.state.launchName} </Text>
          </div>

          <div
            className="SheetSelection"
            style={{ margin: '20px', padding: '10px' }}
          >
            <div> Please Select the Sheects </div>

            <div
              className="checkboxlist"
              style={{ margin: '20px', padding: '10px' }}
            >
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Check all
              </Checkbox>
              <Divider />
              <CheckboxGroup
                vertical
                options={plainOptions}
                value={checkedList}
                onChange={onChange}
              />
            </div>
          </div>

          <Button onClick={() => GenerateSelectedSheets(checkedList)}>
            Generate
          </Button>
          <div className="sheeetGenerated">
            <ListOfItem data={filesCreatedList} />
          </div>
        </Col>

        <Col md={12} lg={12} sm={24} xs={24}>
          <Button type="link" onClick={toPreviousPage}>
            Back
            <RollbackOutlined />
          </Button>
          <div className="tableSection">
            <Divider />
            <div className="table">
              <Table
                dataSource={products}
                columns={columns}
                pagination={{
                  pageSize: pageSize,
                  total: total,
                  current: current,
                  onChange: onPageChange,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GenerateSheets;
