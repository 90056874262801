import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons';

const MerchEntry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [optionList, setOptionList] = useState([]);
  const [merchform] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();

  const fetchOptions = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setOptionList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setEditData({
      SKU: location.state.SKU,
      factory: location.state.factory,
      mill: location.state.mill,
      quality: location.state.quality,
      fabricCode: location.state.fabricCode,
      freshOrStock: location.state.freshOrStock,
      articleName: location.state.articleName,
      fabricPrice: location.state.fabricPrice?.toString(),
      fabricQty: location.state.fabricQty?.toString(),
      Booked: location.state.Booked,
      size: location.state.size,
      launch_masters: location.state.launchDetails?.map(
        (launch) => launch.launchRefId
      ),
      editId: location.state.id,
    });
    fetchOptions(authToken);
  }, []);

  useEffect(() => {
    console.log(editData);
    merchform.setFieldsValue(editData);
  }, [editData, merchform]);

  const addNewProductSpecification = async (data) => {
    setSaving(true);

    const fabricPrice = parseFloat(data['fabricPrice']);
    const fabricQty = parseInt(data['fabricQty']);

    const values = {
      ...data,
      fabricPrice: fabricPrice,
      fabricQty: fabricQty,
    };

    data = { data: values };

    console.log('data:', data);

    // return;
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/merchandise-masters/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/merchandise-masters/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const toPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div>
      <div>Merchendise Master Entry</div>

      <Button type="link" onClick={toPreviousPage}>
        Back
        <RollbackOutlined />
      </Button>

      <div>
        <Form
          form={merchform}
          layout="vertical"
          onFinish={addNewProductSpecification}
        >
          <Row gutter={[16, 16]}>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="SKU"
                name="SKU"
                rules={[
                  {
                    required: true,
                    message: 'SKU is Required!',
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Factory"
                name="factory"
                rules={[
                  {
                    required: true,
                    message: 'Factory is Required!',
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Factory Name" value={editData?.styleNo} />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Mill"
                name="mill"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Mill" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Quality"
                name="quality"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Quality" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Fabric Code"
                name="fabricCode"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Fabric Code" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Fresh or Stock"
                name="freshOrStock"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Fresh or Stock" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Article Name"
                name="articleName"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Article Name" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Size"
                name="size"
                rules={[
                  {
                    type: 'string',
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Size" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="fabric Price"
                name="fabricPrice"
                rules={[
                  {
                    required: true,
                    // pattern: new RegExp('/^\d{0,8}(\.\d{1,4})?$/'),
                    message: 'Please enter a valid price',
                    pattern: '^([0-9]{0,10}((.)[0-9]{0,4}))$',
                  },
                ]}
              >
                <Input placeholder="fabric Price" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Fabric Qty"
                name="fabricQty"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid price',
                    pattern: '^([0-9]{0,10}((.)[0-9]{0,4}))$',
                  },
                ]}
              >
                <Input placeholder="fabric Quantity" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Booked" name="Booked">
                <Select placeholder="Select ">
                  <Select.Option value="Booked">Booked</Select.Option>
                  <Select.Option value="Not Booked">Not Booked</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Launch" name="launch_masters">
                <Select placeholder="Select" mode="multiple" disabled={true}>
                  {optionList.map((opt) => (
                    <Select.Option value={opt.id} key={opt.id}>
                      {opt.launchName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Form.Item label="merchendiseId" hidden={true} name="editId">
              <Input type="text" />
            </Form.Item>
          </Row>
          <Button
            className="addProduct_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {saving ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default MerchEntry;
