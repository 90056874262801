const ItemListDispaly = ({ productOptions }) => {
  // console.log(productOptions);
  if (productOptions.length > 0) {
    return (
      <div>
        <ul>
          {productOptions.map((productOption) => {
            // console.log(productOption);
            if (productOption.quantity !== 0) {
              return (
                <li>
                  {`${productOption.poObject.productCatName} : ${productOption.quantity}`}
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  } else {
    return null;
  }
};
export default ItemListDispaly;
