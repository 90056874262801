import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';

const PlanningCompletedProducts = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [designform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [optionList, setOptionList] = useState([]);
  const dateFormat = 'YYYY-MM-DD';

  // search table
  const handleSearch = async (event) => {
    event.preventDefault();
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchDesignMaster(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(
          `${API}/planing-masters?filters[$or][0][completionStage][$eqi]=completed&filters[SKU][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&sort[0]=createdAt:desc`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const designMasterData = await response.json();
        console.log('original Data planning:', designMasterData);
        // return;

        data1 = designMasterData.data.map((entry) => {
          let launchObj = [];
          if (entry.attributes.launch_masters.data.length > 0) {
            launchObj = entry.attributes.launch_masters.data.map((launch) => {
              let launchRefId = launch.id;
              let launchName = launch.attributes.launchName;
              let launchDate = launch.attributes.launchDate;
              return {
                launch_name: launchName,
                launchDate: launchDate,
                launchRefId: launchRefId,
              };
            });
          }

          return {
            id: entry.id,
            SKU: entry.attributes.SKU,
            productDescription: entry.attributes.productDescription,
            productCategory: entry.attributes.productCategory,
            AMRP: entry.attributes.AMRP,
            ARV: entry.attributes.ARV,
            ASP: entry.attributes.ASP,
            assumedMRP: entry.attributes.assumedMRP,
            buyVolume: entry.attributes.buyVolume,
            category: entry.attributes.category,
            projectedDiscount: entry.attributes.projectedDiscount,
            revisedMRP: entry.attributes.revisedMRP,
            targetCOGS: entry.attributes.targetCOGS,
            totalARV: entry.attributes.totalARV,
            tweakedBuy: entry.attributes.tweakedBuy,
            leftoverStock: entry.attributes.leftoverStock,
            launchDetails: launchObj,
          };
        });
        console.log('original Data:', designMasterData);
        console.log('Data:', data1);
        setData(data1);

        setTotal(designMasterData.meta.pagination.total);
        setPageSize(designMasterData.meta.pagination.pageSize);
        setPageCount(designMasterData.meta.pagination.pageCount);
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Table Data
  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);

    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/planing-masters?filters[$or][0][completionStage][$eqi]=completed&pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      data2 = paginationData.data.map((entry) => {
        let launchObj = [];
        if (entry.attributes.launch_masters.data.length > 0) {
          launchObj = entry.attributes.launch_masters.data.map((launch) => {
            let launchRefId = launch.id;
            let launchName = launch.attributes.launchName;
            let launchDate = launch.attributes.launchDate;
            return {
              launch_name: launchName,
              launchDate: launchDate,
              launchRefId: launchRefId,
            };
          });
        }
        return {
          id: entry.id,
          SKU: entry.attributes.SKU,
          productDescription: entry.attributes.productDescription,
          productCategory: entry.attributes.productCategory,
          AMRP: entry.attributes.AMRP,
          ARV: entry.attributes.ARV,
          ASP: entry.attributes.ASP,
          assumedMRP: entry.attributes.assumedMRP,
          buyVolume: entry.attributes.buyVolume,
          category: entry.attributes.category,
          projectedDiscount: entry.attributes.projectedDiscount,
          revisedMRP: entry.attributes.revisedMRP,
          targetCOGS: entry.attributes.targetCOGS,
          totalARV: entry.attributes.totalARV,
          tweakedBuy: entry.attributes.tweakedBuy,
          leftoverStock: entry.attributes.leftoverStock,
          launchDetails: launchObj,
        };
      });
      console.log('original Data:', paginationData);
      console.log('Data:', data2);
      setData(data2);
      // let page = paginationData.meta.pagination.page;

      setTotal(paginationData.meta.pagination.total);
      setPageSize(paginationData.meta.pagination.pageSize);
      setPageCount(paginationData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  let data1 = [];

  const fetchDesignMaster = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/planing-masters?filters[$or][0][completionStage][$eqi]=completed&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const designMasterData = await response.json();
      console.log('original Data planning:', designMasterData);

      data1 = designMasterData.data.map((entry) => {
        let launchObj = [];
        if (entry.attributes.launch_masters.data.length > 0) {
          launchObj = entry.attributes.launch_masters.data.map((launch) => {
            let launchRefId = launch.id;
            let launchName = launch.attributes.launchName;
            let launchDate = launch.attributes.launchDate;
            return {
              launch_name: launchName,
              launchDate: launchDate,
              launchRefId: launchRefId,
            };
          });
        }

        return {
          id: entry.id,
          SKU: entry.attributes.SKU,
          productDescription: entry.attributes.productDescription,
          productCategory: entry.attributes.productCategory,
          AMRP: entry.attributes.AMRP,
          ARV: entry.attributes.ARV,
          ASP: entry.attributes.ASP,
          assumedMRP: entry.attributes.assumedMRP,
          buyVolume: entry.attributes.buyVolume,
          category: entry.attributes.category,
          projectedDiscount: entry.attributes.projectedDiscount,
          revisedMRP: entry.attributes.revisedMRP,
          targetCOGS: entry.attributes.targetCOGS,
          totalARV: entry.attributes.totalARV,
          tweakedBuy: entry.attributes.tweakedBuy,
          leftoverStock: entry.attributes.leftoverStock,
          launchDetails: launchObj,
        };
      });
      console.log('original Data:', designMasterData);
      console.log('Data:', data1);
      setData(data1);

      setTotal(designMasterData.meta.pagination.total);
      setPageSize(designMasterData.meta.pagination.pageSize);
      setPageCount(designMasterData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };
  // Table Data

  // Initail Data
  useEffect(() => {
    if (authToken) {
      fetchDesignMaster(authToken);
    }
  }, []);

  //   auxiliary functions for handeleing Models

  if (isLoading) {
    return <Spin size="large" />;
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  const toDetailedRecordPage = (state) => {
    navigate('/EditPlaningEntry', { state });
  };

  //   Table Column

  const columns = [
    {
      key: 'SKU',
      title: 'SKU',
      render: (record) => {
        console.log(record);
        return (
          <div>
            {' '}
            <a
              onClick={() => {
                toDetailedRecordPage(record);
              }}
            >
              {record.SKU}
            </a>
          </div>
        );
      },
    },
    {
      key: 'productDescription',
      title: 'product Description',
      dataIndex: 'productDescription',
    },
    {
      key: 'productCategory',
      title: 'product Category',
      dataIndex: 'productCategory',
    },
    {
      key: 'AMRP',
      title: 'AMRP',
      dataIndex: 'AMRP',
    },
    {
      key: 'ARV',
      title: 'ARV',
      dataIndex: 'ARV',
    },
    {
      key: 'ASP',
      title: 'ASP',
      dataIndex: 'ASP',
    },
    {
      key: 'assumedMRP',
      title: 'Assumed MRP',
      dataIndex: 'assumedMRP',
    },
    {
      key: 'buyVolume',
      title: 'buy Volume',
      dataIndex: 'buyVolume',
    },
    {
      key: 'category',
      title: 'category',
      dataIndex: 'category',
    },
    {
      key: 'projectedDiscount',
      title: 'Projected Discount',
      dataIndex: 'projectedDiscount',
    },
    {
      key: 'revisedMRP',
      title: 'Revised MRP',
      dataIndex: 'revisedMRP',
    },
    {
      key: 'targetCOGS',
      title: 'Target COGS',
      dataIndex: 'targetCOGS',
    },
    {
      key: 'totalARV',
      title: 'Total ARV',
      dataIndex: 'totalARV',
    },
    {
      key: 'tweakedBuy',
      title: 'Tweaked Buy',
      dataIndex: 'tweakedBuy',
    },
    {
      key: 'leftoverStock',
      title: 'Left Over Stock',
      dataIndex: 'leftoverStock',
    },

    {
      key: 'action',
      title: 'Actions',
      render: (record) => {
        return (
          <div>
            <div className="ActionColumn">
              <Button
                type="primary"
                style={{ color: 'black', backgroundColor: 'lightgreen' }}
                onClick={() => confirmEntry(record)}
              >
                confirm
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  //   auxiliary functions for record Action

  const confirmEntry = (record) => {
    Modal.confirm({
      title: 'You are sure you are Marking this Entry Complete and Confirmed',
      onOk: () => {
        confirmMasterEntry(record);

        // setData((pre) => {
        //   return pre.filter((person) => person.id != record.id);
        // });
      },
    });
  };

  const confirmMasterEntry = async (record) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/planing-masters/${record.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          data: { completionStage: 'confirmed', completionStatus: true },
        }),
      });
      const responseData = await response.json();
      console.log(responseData);

      fetchDesignMaster(getToken());
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div>Planning All Completed Products</div>

      <div className="FilterSection">
        {/** search new */}

        <form
          onSubmit={(event) => {
            handleSearch(event);
          }}
          className="searchBarr"
        >
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit"> Search</button>
        </form>

        <br />

        {/** search new */}

        {/** search old
            <Input type="text"
          size="large"
          name="searchInput"
          value={searchInput}
          onChange={async (event) => setSearchInput(event.target.value)}
          // onKeyPress={(event) => handleSearchBarKeyPress(event)}
          label="Search"
        />

        <Button onClick={handleSearch} >
          search
        </Button>    
    */}
      </div>

      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
        />
      </div>
    </div>
  );
};

export default PlanningCompletedProducts;
