import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import DynamicSelectOption from '../../components/DynamicSelectOption/DynamicSelectOption';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, Link } from 'react-router-dom';
import { monthNames } from '../../utils/dateUtils';
import { getUserDetails } from '../../helpers';

const userRolesAllowedForFeature = {
  edit: {
    // designAttribute: [1000, 1001, 1003],
    // merchAttribute: [1000, 1004, 1002],
    // planningAttribute: [1000, 1007, 1008],
    launchInformation: [1000, 1001, 1007],
  },
  view: { generateDoc: [1000] },
};

const LaunchMaster = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // Edit Data for editing existing record
  const [editData, setEditData] = useState(null);

  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [designform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const dateFormat = 'YYYY-MM-DD';
  const [optionList, setOptionList] = useState([]);

  const user = getUserDetails();
  console.log(user, '48');

  const toGenerateSheet = (state) => {
    navigate('/generateSheets', { state });
  };

  // search table
  const handleSearch = async (event) => {
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchDesignMaster(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(
          `${API}/launch-masters?filters[$or][0][launchName][$containsi]=${searchValue}&filters[$or][1][season][season_id][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[season][fields][0]=season_id&sort=createdAt:desc`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const designMasterData = await response.json();
        if (designMasterData.error) {
          message.error('Unable to fetch Records, please try again later.');
        } else {
          data1 = designMasterData.data.map((entry) => {
            let seasonRefId = entry.attributes.season.data?.id;
            let season_id = entry.attributes.season.data?.attributes.season_id;
            let launchSeasonObj = {};
            if (seasonRefId && season_id)
              launchSeasonObj = {
                seasonRefId: seasonRefId,
                season_id: season_id,
              };
            return {
              id: entry.id,
              launchName: entry.attributes.launchName,
              launchId: entry.attributes.launchId,
              launchDate: entry.attributes.launchDate,
              launchMonth: entry.attributes.launchMonth,
              launchSeason: launchSeasonObj,
              launchDrop: entry.attributes.launchDrop,
              launchCat: entry.attributes.launchCat,
            };
          });
          console.log('original Data:', designMasterData);
          console.log('Data:', data1);
          setData(data1);

          setTotal(designMasterData.meta.pagination.total);
          setPageSize(designMasterData.meta.pagination.pageSize);
          setPageCount(designMasterData.meta.pagination.pageCount);
        }
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // search table

  // on every page change by the user
  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);

    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/launch-masters?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[season][fields][0]=season_id&sort=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      if (paginationData.error) {
        message.error('Something went wrong, please try again');
      } else {
        data2 = paginationData.data.map((entry) => {
          let seasonRefId = entry.attributes.season.data?.id;
          let season_id = entry.attributes.season.data?.attributes.season_id;
          let launchSeasonObj = {};
          if (seasonRefId && season_id)
            launchSeasonObj = {
              seasonRefId: seasonRefId,
              season_id: season_id,
            };

          return {
            id: entry.id,
            launchName: entry.attributes.launchName,
            launchId: entry.attributes.launchId,
            launchDate: entry.attributes.launchDate,
            launchMonth: entry.attributes.launchMonth,
            launchSeason: launchSeasonObj,
            launchDrop: entry.attributes.launchDrop,
            launchCat: entry.attributes.launchCat,
          };
        });
        console.log('original Data:', paginationData);
        console.log('Data:', data2);
        setData(data2);
        // let page = paginationData.meta.pagination.page;

        setTotal(paginationData.meta.pagination.total);
        setPageSize(paginationData.meta.pagination.pageSize);
        setPageCount(paginationData.meta.pagination.pageCount);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  let data1 = [];

  const fetchDesignMaster = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?pagination[page]=${current}&pagination[pageSize]=${pageSize}&populate[season][fields][0]=season_id&sort=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const designMasterData = await response.json();
      if (designMasterData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // console.log("originalq Data:", designMasterData[0]?.attributes.season.data.id);
        // return;
        data1 = designMasterData.data.map((entry) => {
          let seasonRefId = entry.attributes.season.data?.id;
          let season_id = entry.attributes.season.data?.attributes.season_id;
          let launchSeasonObj = {};
          if (seasonRefId && season_id)
            launchSeasonObj = {
              seasonRefId: seasonRefId,
              season_id: season_id,
            };
          return {
            id: entry.id,
            launchName: entry.attributes.launchName,
            launchId: entry.attributes.launchId,
            launchDate: entry.attributes.launchDate,
            launchMonth: entry.attributes.launchMonth,
            launchSeason: launchSeasonObj,
            launchDrop: entry.attributes.launchDrop,
            launchCat: entry.attributes.launchCat,
          };
        });
        console.log('original Data:', designMasterData);
        console.log('Data:', data1);
        setData(data1);

        setTotal(designMasterData.meta.pagination.total);
        setPageSize(designMasterData.meta.pagination.pageSize);
        setPageCount(designMasterData.meta.pagination.pageCount);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  // selecting the dropdowns data
  const fetchSesson = async (token) => {
    let seasonData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/seasons?sort=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const seasonData = await response.json();

      if (seasonData.error) {
        message.error('Something went wrong, please try again');
      } else {
        seasonData2 = seasonData.data.map((entry) => {
          return {
            id: entry.id,
            season_type: entry.attributes.season_type,
            season_year: entry.attributes.season_year,
            season_id: entry.attributes.season_id,
            // launch_masters: entry.attributes.launch_masters,
          };
        });

        setOptionList(seasonData2);
        console.log('original Season Data:', seasonData);
        console.log('modified Data:', seasonData2);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  }; // end function fetchSeason

  // on first load do this
  useEffect(() => {
    if (authToken) {
      fetchDesignMaster(authToken);
      fetchSesson(authToken);
    }
  }, []);

  // for opening add or edit form with data or change in the reference of design form when form is closed or page is reloaded
  useEffect(() => {
    console.log(editData);
    designform.setFieldsValue(editData);
  }, [editData, designform]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  // show add/edit form inside modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // hide and reset add/edit form inside modal
  const handleOk = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  // hide and reset add/edit form inside modal
  const handleCancel = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //add record to database table by api call
  const addNewProductSpecification = async (data) => {
    setSaving(true);

    //add or removing extra data from values from formData object
    const values = {
      ...data,
      // 'LaunchDate': data['launchDate'].format('YYYY-MM-DD')
      launchId: uuidv4(),
      season: data.season,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);
    // return;
    //editId will be present only in case of edit mode
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);
      // update the record
      try {
        const response = await fetch(
          `${API}/launch-masters/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Product Specification.');
        } else {
          message.success('Data saved successfully!');

          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        designform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId); //should be falsy

      try {
        const response = await fetch(`${API}/launch-masters/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          //
          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        designform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    }
  };

  const columns = [
    {
      key: 'launchName',
      title: 'launch Name',

      // render method for rendering customized values in the table cell
      render: (record) => {
        console.log(record);
        // if()
        return (
          <div>
            {user.rolesAssigned.find((role) =>
              userRolesAllowedForFeature?.view?.generateDoc?.includes(role)
            ) ? (
              <a
                onClick={() => {
                  toGenerateSheet(record.id);
                }}
              >
                {record.launchName}
              </a>
            ) : (
              record.launchName
            )}
          </div>
        );
      },
    },
    {
      key: 'launchId', //key should be unique for each column
      title: 'launch Id', // display name of the column
      dataIndex: 'launchId', //should match the name of the data field in the datasource
    },
    {
      key: 'launchDate',
      title: 'launch Date',
      dataIndex: 'launchDate',
    },
    {
      key: 'launchMonth',
      title: 'launch Month',
      dataIndex: 'launchMonth',
    },
    {
      key: 'season',
      title: 'launch Season',
      render: (record) => {
        // console.log(record);
        return (
          <div>
            {' '}
            {record.launchSeason.season_id
              ? record.launchSeason.season_id
              : 'NA'}
          </div>
        );
      },
    },

    {
      key: 'action',
      title: 'Actions',
      render: (record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <DeleteOutlined
                style={{ color: 'red' }}
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteDesignMasterEntry = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/launch-masters/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fetchDesignMaster(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteDesignMasterEntry(record.id);
      },
    });
  };

  const Edit = (record) => {
    setEditData({
      launchName: record.launchName,
      launchId: record.launchId,
      launchDate: moment(record.launchDate, 'YYYY-MM-DD'), // date convertion to accepted format
      launchMonth: record.launchMonth,
      season: record.launchSeason.seasonRefId,
      launchDrop: record.launchDrop,
      launchCat: record.launchCat,
      editId: record.id, // its important for edit functionality
    });
    // console.log(moment(record));
    console.log(moment(record.date));
    showModal(); // after setting the data for the edit form show the modal
  };

  const monthListOptions = monthNames.map((month, index) => {
    return {
      label: month,
      value: month,
    };
  });

  return (
    <div>
      <div>Launch Management</div>

      <Button onClick={showModal}>Add New Launch Information</Button>

      <div className="FilterSection">
        <form onSubmit={handleSearch} className="searchBarr">
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit"> Search</button>
        </form>

        {/** search new */}
        {/*<Input
          type="text"
          size="large"
          name="searchInput"
          value={searchInput}
          onChange={async (event) => setSearchInput(event.target.value)}
          label="Search"
        />
  <Button onClick={handleSearch}>search</Button> */}
      </div>

      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
        />
      </div>

      <Modal
        title="Add New Launch Details"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form
            form={designform}
            layout="vertical"
            onFinish={addNewProductSpecification}
          >
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch Name"
                  name="launchName"
                  rules={[
                    {
                      required: true,
                      message: 'Launch Name is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Launch Name" value={editData?.styleNo} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch Date"
                  name="launchDate"
                  rules={[
                    {
                      required: true,
                      message: 'Launch Date is Required!',
                      type: 'object',
                    },
                  ]}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>
              {/*
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Launch Month"
                    name="launchMonth"
                    rules={[
                      {
                        type: 'string',
                      },
                    ]}
                  >
                    <Input placeholder="Launch Month" />
                  </Form.Item>
                </Col>
                */}

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch Month"
                  name="launchMonth"
                  rules={[
                    {
                      required: true,
                      message: 'Month is Required!',
                    },
                  ]}
                >
                  {<Select options={monthListOptions} />}
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch Drop"
                  name="launchDrop"
                  rules={[
                    {
                      required: true,
                      message: 'Launch Drop Code is Required!',
                    },
                  ]}
                >
                  <Select placeholder="Select Launch Drop">
                    <Select.Option value="L1">L1</Select.Option>
                    <Select.Option value="L2">L2</Select.Option>
                    <Select.Option value="L3">L3</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch Categories"
                  name="launchCat"
                  rules={[
                    {
                      required: true,
                      message: 'Launch Categories is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Select placeholder="Select Launch Category">
                    <Select.Option value="Regular">Regular</Select.Option>
                    <Select.Option value="Super">Super</Select.Option>
                    <Select.Option value="Ultra">Ultra</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Launch Season" name="season">
                  <Select placeholder="Select ">
                    {optionList.map((opt) => (
                      <Select.Option value={opt.id}>
                        {opt.season_id}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Form.Item label="" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default LaunchMaster;
