import React from 'react';
import { Select, message, Spin, Modal, Row, Col, Button } from 'antd';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

const LaunchDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(null);
  const [launchList, setLaunchList] = useState([]);
  const [selectedlaunch, setSelectedLaunch] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const authToken = getToken();

  const fetchLaunchList = async (token) => {
    let optionsData2 = [];
    // setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          value: entry.id.toString(),
          label: entry.attributes.launchName,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setLaunchList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchLaunchList(authToken);
    }
  }, []);

  const handleLaunchChange = async (value) => {
    console.log(value);
    let optionsData2 = [];
    // setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters/${value}?populate=*`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const optionsData = await response.json();

      let entry = optionsData.data;

      optionsData2 = {
        id: entry.id,
        launchName: entry.attributes.launchName,
        launchDate: entry.attributes.launchDate,
        design_masters: entry.attributes.design_masters,
        merchandise_masters: entry.attributes.merchandise_masters,
        planing_masters: entry.attributes.planing_masters,
        products: entry.attributes.products,
        seasonName: entry.attributes.season?.data?.attributes.season_id,
        totalProductsInitialized: entry.attributes.design_masters?.data?.length,
        totalProductsConfirmed: entry.attributes.products?.data?.length,
        designCompletedProduct: entry.attributes.design_masters?.data?.filter(
          (designProd) => designProd.attributes.completionStatus === true
        ).length,
        merchCompletedProduct:
          entry.attributes.merchandise_masters?.data?.filter(
            (merchProd) => merchProd.attributes.completionStatus === true
          ).length,
        planningCompletedProduct:
          entry.attributes.planing_masters?.data?.filter(
            (planningProd) => planningProd.attributes.completionStatus === true
          ).length,
        EcomCompletedProduct: entry.attributes.products?.data?.filter(
          (EcomProd) => EcomProd.attributes.completionStatus === true
        ).length,
        // launch_masters: entry.attributes.launch_masters,
      };

      setSelectedLaunch(optionsData2);
      console.log('original LaunchDetails Data:', optionsData);
      console.log('modified LaunchDetails Data:', optionsData2);
      setShowDetails(true);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      // setIsLoading(false);
    }
  };

  // if (isLoading) {
  //   return <Spin size="large" />;
  // }

  const toGenerateSheet = (state) => {
    navigate('/generateSheets', { state });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div>Launch Details</div>

      <Select
        showSearch
        style={{
          width: 300,
        }}
        placeholder="Search to Select"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? '')
            .toLowerCase()
            .includes(input.trim().toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toLowerCase())
        }
        options={launchList}
        // onChange={(event)=>setCurrentLaunch(event.target.value)}
        onChange={handleLaunchChange}
      />
      {showDetails && (
        <div className="launchDetails">
          <Row gutter={[16, 16]}>
            <Col md={8} lg={8} sm={24} xs={24}>
              <p>
                <strong>Launch Name</strong> : {selectedlaunch.launchName}
              </p>
              <p>
                <strong>Launch Date</strong> : {selectedlaunch.launchDate}
              </p>
              <p>
                <strong>Season</strong> : {selectedlaunch.seasonName}
              </p>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <p>
                <strong>Total Product to be Launched</strong> :
                {selectedlaunch.totalProductsInitialized}
              </p>
              <p>
                <strong>Total Completed & Confirmed Product</strong> :
                {selectedlaunch.EcomCompletedProduct}
              </p>
              <p>
                <strong>Total Remaining Product</strong> :
                {selectedlaunch.totalProductsInitialized -
                  selectedlaunch.EcomCompletedProduct}
              </p>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Button
                type="primary"
                style={{ color: 'black', backgroundColor: 'lightgreen' }}
                onClick={() => toGenerateSheet(selectedlaunch.id)}
              >
                Generate Sheet
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default LaunchDetails;
