import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons';
import moment from 'moment';

const PlanningEntry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [designform] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [optionList, setOptionList] = useState([]);
  const dateFormat = 'YYYY-MM-DD';

  const fetchOptions = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setOptionList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(location);
    setEditData({
      SKU: location.state.SKU,
      productDescription: location.state.productDescription,
      productCategory: location.state.productCategory,
      AMRP: location.state.AMRP?.toString(),
      ARV: location.state.ARV?.toString(),
      ASP: location.state.ASP?.toString(),
      assumedMRP: location.state.assumedMRP?.toString(),
      buyVolume: location.state.buyVolume?.toString(),
      category: location.state.category,
      projectedDiscount: location.state.projectedDiscount?.toString(),
      revisedMRP: location.state.revisedMRP?.toString(),
      targetCOGS: location.state.targetCOGS?.toString(),
      totalARV: location.state.totalARV?.toString(),
      tweakedBuy: location.state.tweakedBuy?.toString(),
      leftoverStock: location.state.leftoverStock?.toString(),
      launch_masters: location.state.launchDetails?.map(
        (launch) => launch.launchRefId
      ),
      editId: location.state.id,
    });

    fetchOptions(authToken);
  }, []);

  useEffect(() => {
    console.log(editData);
    designform.setFieldsValue(editData);
  }, [editData, designform]);

  const addNewProductSpecification = async (data) => {
    setSaving(true);

    const values = {
      ...data,
      buyVolume: parseInt(data['buyVolume'], '10'),
      leftoverStock: parseInt(data['leftoverStock'], '10'),
      assumedMRP: parseInt(data['assumedMRP'], '10'),
      revisedMRP: parseInt(data['revisedMRP'], '10'),
      AMRP: parseInt(data['AMRP'], '10'),
      projectedDiscount: parseInt(data['projectedDiscount'], '10'),
      ASP: parseInt(data['ASP'], '10'),
      ARV: parseInt(data['ARV'], '10'),
      totalARV: parseInt(data['totalARV'], '10'),
      targetCOGS: parseInt(data['totalARV'], '10'),
    };

    data = { data: values };

    console.log('data:', data);
    console.log('data edit:', data.data.editId);
    // return;

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/planing-masters/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        // setEditData(null);
      }
    } else {
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/planing-masters/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const toPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div>
      <div>Planing Master Entry</div>

      <Button type="link" onClick={toPreviousPage}>
        Back
        <RollbackOutlined />
      </Button>

      <div>
        <Form
          form={designform}
          layout="vertical"
          onFinish={addNewProductSpecification}
        >
          <Row gutter={[16, 16]}>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="SKU"
                name="SKU"
                rules={[
                  {
                    required: true,
                    message: 'SKU no is Required!',
                    type: 'string',
                  },
                ]}
              >
                <Input
                  placeholder="SKU"
                  disabled={true}
                  value={editData?.styleNo}
                />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Product Description"
                name="productDescription"
                rules={[
                  {
                    message: 'Product Description is Required!',
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Product Description" disabled={true} />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="product Category"
                name="productCategory"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Product Category" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                name="AMRP"
                label="AMRP"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'Plese Enter AMRP',
                  },
                ]}
              >
                <Input type="number" placeholder="AMRP" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="ARV"
                name="ARV"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="ARV" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="ASP"
                name="ASP"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="ASP" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Assumed MRP"
                name="assumedMRP"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Assumed MRP" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="buy Volume"
                name="buyVolume"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="buy Volume" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="category"
                name="category"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="category" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Projected Discount"
                name="projectedDiscount"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="projected Discount" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Revised MRP"
                name="revisedMRP"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Revised MRP" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Target COGS"
                name="targetCOGS"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Target COGS" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Total ARV"
                name="totalARV"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Total ARV" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="tweaked Buy"
                name="tweakedBuy"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="tweaked Buy" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Left Over Stock"
                name="leftoverStock"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Left Over Stock" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Launch" name="launch_masters">
                <Select placeholder="Select" mode="multiple" disabled={true}>
                  {optionList.map((opt) => (
                    <Select.Option value={opt.id} key={opt.id}>
                      {opt.launchName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Form.Item label="Edit It" hidden={true} name="editId">
              <Input type="text" />
            </Form.Item>
          </Row>
          <Button
            className="addProduct_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {saving ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default PlanningEntry;
