import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from 'antd';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, redirect } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import useScreenSize from '../../hooks/useScreenSize';
import { API, BEARER } from '../../constant';
import { setToken, setUserDetails } from '../../helpers';

const SignIn = () => {
  const navigate = useNavigate();
  const { isDesktopView } = useScreenSize();

  const { user, setUser } = useAuthContext();
  // if(!user){
  //  navigate('/');
  // }

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');

  const onFinish = async (values) => {
    setIsLoading(true);

    console.log('User VAlues', values);
    try {
      const value = {
        identifier: values.email,
        password: values.password,
      };
      const response = await fetch(`${API}/auth/local/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        // set the token

        console.log('Sign in function ', value, data, data.jwt);
        setToken(data.jwt);

        try {
          const response = await fetch(`${API}/users/me?populate=*`, {
            headers: { Authorization: `${BEARER} ${data.jwt}` },
          });
          const userDetails = await response.json();

          console.log('user details', userDetails);
          let rolesAssignedArray = userDetails?.roles_permisions;
          let rolesAssigned = [];
          rolesAssignedArray?.map((rolesAssigneditem) => {
            rolesAssigned.push(rolesAssigneditem.role_id);
          });
          console.log('roles Assigned : ', rolesAssigned);
          userDetails.rolesAssigned = rolesAssigned;
          console.log(userDetails);
          setUserDetails(userDetails);

          // set the user
          setUser(userDetails);
        } catch (e) {
          message.error(`Something Went Wrong, please try again later!`);
        }

        message.success(`Welcome back ${data.user.username}!`);
        navigate('/dashboard');
        // return redirect('/');
        // return navigate("/profile");
      }
    } catch (error) {
      console.error(error);
      setError(error?.message ?? 'Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="SignIn">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError('')}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}
              >
                <Input placeholder="Email address" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Login {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Paragraph className="form_help_text">
              New to EMPS? <Link to="/signup">Sign Up</Link>
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SignIn;
