import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons';
import moment from 'moment';

const ProductDetailsView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [designform] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [optionList, setOptionList] = useState([]);
  const [silhouetteList, setSilhouetteList] = useState([]);
  const [specialAttributeList, setSpecialAttributeList] = useState([]);
  const [trendsList, setTrendsList] = useState([]);
  const dateFormat = 'YYYY-MM-DD';

  const fetchOptions = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setOptionList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSilhouetteList = async (token) => {
    let silhouetteListData = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/silhouettes`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      silhouetteListData = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          silhouetteName: entry.attributes.silhouette_name,
        };
      });

      setSilhouetteList(silhouetteListData);
      console.log('original Silhouette data:', optionsData);
      console.log('modified Silhouette Data:', silhouetteListData);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSpecialAttributeList = async (token) => {
    let specialAttributeListData = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/special-attributes`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      specialAttributeListData = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          specialAttributeName: entry.attributes.specialAttribute_name,
        };
      });

      setSpecialAttributeList(specialAttributeListData);
      console.log('original  Special attribute data:', optionsData);
      console.log('modified Special attribute Data:', specialAttributeListData);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTrendsList = async (token) => {
    let trendsListData = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/trends`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      trendsListData = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          trendsName: entry.attributes.trend_name,
        };
      });

      setTrendsList(trendsListData);
      console.log('original  Trends data:', optionsData);
      console.log('modified Trends Data:', trendsListData);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('data ', location);
    setEditData({
      styleNo: location.state.styleNo,
      SKU: location.state.SKU,
      Div: location.state.Div,
      Date: moment(location.state.date, 'YYYY-MM-DD'),
      season: location.state.season,
      productCategory: location.state.productCategory,
      description: location.state.description,
      block: location.state.block,
      washing: location.state.washing,
      PACKING: location.state.PACKING,
      styleSample: location.state.styleSample,
      launch_masters: location.state.launchDetails?.map(
        (launch) => launch.launchRefId
      ),
      editId: location.state.id,
    });

    fetchOptions(authToken);
    fetchSilhouetteList(authToken);
    fetchSpecialAttributeList(authToken);
    fetchTrendsList(authToken);
  }, []);

  useEffect(() => {
    console.log(editData);
    designform.setFieldsValue(editData);
  }, [editData, designform]);

  const addNewProductSpecification = async (data) => {
    setSaving(true);

    const values = {
      ...data,
      date: data['Date'].format('YYYY-MM-DD'),
    };

    delete values['Date'];

    data = { data: values };

    console.log('data:', data);
    console.log('data edit:', data.data.editId);
    // return;

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/design-masters/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        // setEditData(null);
      }
    } else {
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/design-masters/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const toPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div>
      <div>Product Details View</div>

      <Button type="link" onClick={toPreviousPage}>
        Back
        <RollbackOutlined />
      </Button>

      <div>
        <Form
          form={designform}
          layout="vertical"
          onFinish={addNewProductSpecification}
        >
          <Row gutter={[16, 16]}>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="styleNo"
                name="styleNo"
                rules={[
                  {
                    required: true,
                    message: 'Style no is Required!',
                    type: 'string',
                  },
                ]}
              >
                <Input
                  placeholder="Style Number"
                  disabled={true}
                  value={editData?.styleNo}
                />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="SKU"
                name="SKU"
                rules={[
                  {
                    required: true,
                    message: 'SKU is Required!',
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Div"
                name="Div"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Division" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                name="Date"
                label="Date"
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: 'Plese select the date of product Specification!',
                  },
                ]}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Season"
                name="season"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Website Url" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Product Category"
                name="productCategory"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Product Category" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Description" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Block"
                name="block"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Block" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Sub Category" name="sub_category">
                <Select placeholder="Select Sub Category">
                  <Select.Option value="Shirt">Shirt</Select.Option>
                  <Select.Option value="Shacket">Shacket</Select.Option>
                  <Select.Option value="Jacket">Jacket</Select.Option>
                  <Select.Option value="Overshirt">Overshirt</Select.Option>
                  <Select.Option value="Chino">Chino</Select.Option>
                  <Select.Option value="Pant-Trouser">
                    Pant-Trouser
                  </Select.Option>
                  <Select.Option value="Shorts">Shorts</Select.Option>
                  <Select.Option value="Jogger">Jogger</Select.Option>
                  <Select.Option value="Crew">Crew</Select.Option>
                  <Select.Option value="Neck-Tee">Neck-Tee</Select.Option>
                  <Select.Option value="Polo">Polo</Select.Option>
                  <Select.Option value="Hoodie">Hoodie</Select.Option>
                  <Select.Option value="Sweatshirt">Sweatshirt</Select.Option>
                  <Select.Option value="Pullover-Sweater">
                    Pullover-Sweater
                  </Select.Option>
                  <Select.Option value="Bomber">Bomber</Select.Option>
                  <Select.Option value="Gilet">Gilet</Select.Option>
                  <Select.Option value="Pocket-square">
                    Pocket square
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Collection TR"
                name="collection_tr"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Collection TR" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Collection"
                name="collection"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="collection" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Factory"
                name="factory"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="factory" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Colour"
                name="colour"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Colour" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Launch Season"
                name="launchSeason"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Launch Season" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Fit"
                name="fit"
                rules={[
                  {
                    type: 'string',
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Fit" />
              </Form.Item>
            </Col>

            {/* Launch Month*/}

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Launch Month" name="launchMonth">
                <Select placeholder="Select Launch Month">
                  <Select.Option value="January">January</Select.Option>
                  <Select.Option value="February">February</Select.Option>
                  <Select.Option value="March">March</Select.Option>
                  <Select.Option value="April">April</Select.Option>
                  <Select.Option value="May">May</Select.Option>
                  <Select.Option value="June">June</Select.Option>
                  <Select.Option value="July">July</Select.Option>
                  <Select.Option value="August">August</Select.Option>
                  <Select.Option value="September">September</Select.Option>
                  <Select.Option value="October">October</Select.Option>
                  <Select.Option value="November">November</Select.Option>
                  <Select.Option value="December">December</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="New Repeat" name="new_Repeat">
                <Select placeholder="Select New Or Repeat">
                  <Select.Option value="New">New</Select.Option>
                  <Select.Option value="Repeat">Repeat</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Sleeve" name="Sleeve">
                <Select placeholder="Select Sleeve">
                  <Select.Option value="Full sleeve">Full sleeve</Select.Option>
                  <Select.Option value="Half sleeve">Half sleeve</Select.Option>
                  <Select.Option value="Sleeveless">Sleeveless</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Design Category" name="designCategory">
                <Select placeholder="Select Design Category">
                  <Select.Option value="Core">Core</Select.Option>
                  <Select.Option value="Fashion">Fashion</Select.Option>
                  <Select.Option value="Experimental">
                    Experimental
                  </Select.Option>
                  <Select.Option value="Indian">Indian</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Attribution Category"
                name="attributionCategory"
              >
                <Select placeholder="Select Attribution Category">
                  <Select.Option value="Formal">Core</Select.Option>
                  <Select.Option value="Semi formal">Fashion</Select.Option>
                  <Select.Option value="Evening">Experimental</Select.Option>
                  <Select.Option value="Casual">Indian</Select.Option>
                  <Select.Option value="Semi casual">Indian</Select.Option>
                  <Select.Option value="Casual occasion">Indian</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Pattern" name="pattern">
                <Select placeholder="Select Pattern">
                  <Select.Option value="Solid">Solid</Select.Option>
                  <Select.Option value="Check">Check</Select.Option>
                  <Select.Option value="Stripe">Stripe</Select.Option>
                  <Select.Option value="Chambray">Chambray</Select.Option>
                  <Select.Option value="Jacquard">Jacquard</Select.Option>
                  <Select.Option value="Dobby">Dobby</Select.Option>
                  <Select.Option value="Print">Print</Select.Option>
                  <Select.Option value="Ombre">Ombre</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Knit Woven" name="knitWoven">
                <Select placeholder="Select Knit Woven">
                  <Select.Option value="Knit">Knit</Select.Option>
                  <Select.Option value="Woven">Woven</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Freshness" name="freshness">
                <Select placeholder="Select Freshness">
                  <Select.Option value="New">New</Select.Option>
                  <Select.Option value="Variant">Variant</Select.Option>
                  <Select.Option value="Repeat">Repeat</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Silhouette" name="silhouettes">
                <Select placeholder="Select Silhouette" mode="multiple">
                  {silhouetteList.map((opt) => (
                    <Select.Option value={opt.id} key={opt.id}>
                      {opt.silhouetteName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Special Attribute" name="special_attributes">
                <Select placeholder="Select Special Attribute" mode="multiple">
                  {specialAttributeList.map((opt) => (
                    <Select.Option value={opt.id} key={opt.id}>
                      {opt.specialAttributeName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Trends" name="trends">
                <Select placeholder="Select Trends" mode="multiple">
                  {trendsList.map((opt) => (
                    <Select.Option value={opt.id} key={opt.id}>
                      {opt.trendsName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Colour Family" name="colourFamily">
                <Select placeholder="Select Colour Family">
                  <Select.Option value="White">White</Select.Option>
                  <Select.Option value="Khaki/Beige">Khaki/Beige</Select.Option>
                  <Select.Option value="Black">Black</Select.Option>
                  <Select.Option value="Navy">Navy</Select.Option>
                  <Select.Option value="Indigo">Indigo</Select.Option>
                  <Select.Option value="Blue">Blue</Select.Option>
                  <Select.Option value="Grey">Grey</Select.Option>
                  <Select.Option value="Pink">Pink</Select.Option>
                  <Select.Option value="Red">Red</Select.Option>
                  <Select.Option value="Orange">Orange</Select.Option>
                  <Select.Option value="Mustard">Mustard</Select.Option>
                  <Select.Option value="Gold">Gold</Select.Option>
                  <Select.Option value="Brown">Brown</Select.Option>
                  <Select.Option value="Green/Olive">Green/Olive</Select.Option>
                  <Select.Option value="Violet/Lavender">
                    Violet/Lavender
                  </Select.Option>
                  <Select.Option value="Burgundy">Burgundy</Select.Option>
                  <Select.Option value="Rust">Rust</Select.Option>
                  <Select.Option value="Teal">Teal</Select.Option>
                  <Select.Option value="Off-white">Off-white</Select.Option>
                  <Select.Option value="Yellow">Yellow</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Design Season"
                name="designSeason"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Design Season" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Life Occasion" name="lifeOccasion">
                <Select placeholder="Select Life Occasion">
                  <Select.Option value="Formal">Formal</Select.Option>
                  <Select.Option value="Semi formal">Semi formal</Select.Option>
                  <Select.Option value="Evening">Evening</Select.Option>
                  <Select.Option value="Casual">Casual</Select.Option>
                  <Select.Option value="Semi casual">Semi casual</Select.Option>
                  <Select.Option value="Casual occasion">
                    Casual occasion
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Fit"
                name="fit"
                rules={[
                  {
                    type: 'string',
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Fit" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Washing" name="washing">
                <Select placeholder="Select your gender">
                  <Select.Option value="WASHED">Washed</Select.Option>
                  <Select.Option value="UNWASHED">Unwashed</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="PACKING"
                name="PACKING"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="PACKING" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Style Sample" name="styleSample">
                <Select placeholder="Select Style Sample">
                  <Select.Option value="TRIAL SAMPLE">
                    Trial Sample
                  </Select.Option>
                  <Select.Option value="PRE PRODUCTION SAMPLE">
                    Pre Production Sample
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Launch" name="launch_masters">
                <Select placeholder="Select" mode="multiple">
                  {optionList.map((opt) => (
                    <Select.Option value={opt.id} key={opt.id}>
                      {opt.launchName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Form.Item label="Username" hidden={true} name="editId">
              <Input type="text" />
            </Form.Item>
          </Row>
          <Button
            className="addProduct_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {saving ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ProductDetailsView;
