import React from 'react';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Checkbox,
  Divider,
  Button,
  message,
  Table,
  Typography,
  Col,
  Row,
  Spin,
  Select,
} from 'antd';
import ListOfItem from '../ListOfItem/ListOfItem';
import { RollbackOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const CheckboxGroup = Checkbox.Group;
const plainOptions = ['SpecsSheet', 'MasterSheet', 'MetaSheet'];
const defaultCheckedList = ['SpecsSheet'];

const GenerateBuyPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [products, setProducts] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [filesCreatedList, setFilesCreatedList] = useState([]);
  const [seasonMastersList, setSeasonMastersList] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);

  console.log(location.state);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const fetchSeasonList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/seasons?sort[0]=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData?.data?.map((entry) => {
        return {
          id: entry?.id,
          seasonId: entry?.attributes?.season_id,
        };
      });

      setSeasonMastersList(optionsData2);
      console.log('original season option  Data:', optionsData);
      console.log('modified season Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const onPageChange = async (page) => {
    // setIsLoading(true);
    console.log(page, pageSize);
    // setCurrent(page);

    // let data2 = [];
    // try {
    //     const pagin_response = await fetch(`launch-masters/${launch.id}?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`, {
    //         headers: { Authorization: `${BEARER} ${getToken()}` }
    //     });
    //     const paginationData = await pagin_response.json();

    //     data2 = paginationData.data.map(entry => {
    //         return {
    //             id: entry.id,
    //             launchName: entry.attributes.launchName,
    //             launchId: entry.attributes.launchId,
    //             launchDate: entry.attributes.launchDate,
    //             launchMonth: entry.attributes.launchMonth,
    //             launchSeason: entry.attributes.launchSeason,

    //         };
    //     });
    //     console.log("original Data:", paginationData);
    //     console.log("Data:", data2);
    //     setData(data2);
    //     // let page = paginationData.meta.pagination.page;

    //     setTotal(paginationData.meta.pagination.total);
    //     setPageSize(paginationData.meta.pagination.pageSize);
    //     setPageCount(paginationData.meta.pagination.pageCount);

    // } catch (error) {
    //     console.error(error);
    //     message.error("Error while fetching Data!");
    // } finally {
    //     setIsLoading(false);
    // }
  };

  const getAllProducts = async (seasonId) => {
    const token = getToken();
    setIsLoading(true);
    // const data1 = [];
    try {
      const response = await fetch(`${API}/seasons/${seasonId}?populate=*`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const responseData = await response.json();
      console.log('original generate Data:', responseData);

      const data1 =
        responseData?.data?.attributes?.core_products_launch_season?.data
          ?.filter((product) => {
            console.log(product, 'filter check');
            return product?.attributes?.object_status_no > 6;
          })
          .map((product) => {
            console.log(product, 'product CHECK');
            return {
              id: product.id,
              sku: product.attributes.sku,
              collection: product.attributes.collection,
              description: product.attributes.description,
            };
          });
      console.log('original Data:', responseData);
      console.log('Data:', data1);
      setProducts(data1);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (getToken()) {
      fetchSeasonList(getToken());
    }
  }, []);

  useEffect(() => {
    if (selectedSeason) {
      getAllProducts(selectedSeason);
    }
  }, [selectedSeason]);

  const GenerateSelectedSheets = async (list) => {
    if (products.length > 0) {
      console.log(products, location.state);

      let productIdList = products.map((product) => product.id);
      let token = getToken();
      setIsLoading(true);
      try {
        const response = await fetch(
          `${API}/buyplan-generate?seasonId=${selectedSeason}&productList=${productIdList}`,
          {
            headers: { Authorization: `${BEARER} ${token}` },
          }
        );
        const data = await response.json();
        console.log(data);
        setFilesCreatedList(data.data);
      } catch (error) {
        console.error(error);
        message.error('Something went wrong, Please try again later.');
      } finally {
        setIsLoading(false);
      }
    } else {
      message.error(
        'This Season Does not Have eligible products Please Try later!'
      );
    }
  };

  const toProductDetails = (record) => {};

  if (isLoading) {
    return <Spin size="large" />;
  }

  const columns = [
    {
      key: 'sku',
      title: 'SKU',

      render: (record) => {
        console.log(record);
        return (
          <div>
            {' '}
            <a
              onClick={() => {
                toProductDetails(record);
              }}
            >
              {record.sku}
            </a>
          </div>
        );
      },
    },
    {
      key: 'collection',
      title: 'Collection',
      dataIndex: 'collection',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
  ];

  const seasonListOptions = seasonMastersList.map((season) => {
    return {
      label: season.seasonId,
      value: season.id,
    };
  });

  const toPreviousPage = () => {
    navigate(-1);
  };

  const handleSeasonChange = (data) => {
    console.log('season change', data);
    setSelectedSeason(data);
    // getAllProducts(data);
  };

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col md={12} lg={8} sm={24} xs={24}>
          <div className="genBuyPlan">
            <Title>Generate Buy Plan</Title>
            <Divider />

            <div className="av">
              <Row gutter={[16, 16]}>
                <Col md={20} lg={20} sm={24} xs={24}>
                  <Select
                    showSearch
                    onSearch={(value) => {
                      // console.log('searching...', value);
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={seasonListOptions}
                    onChange={handleSeasonChange}
                    defaultValue={selectedSeason}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
            </div>

            <div className="genBtn">
              <Button onClick={() => GenerateSelectedSheets()}>Generate</Button>
            </div>

            <div className="sheeetGenerated">
              <ListOfItem data={filesCreatedList} />
            </div>
          </div>
        </Col>

        <Col md={12} lg={16} sm={24} xs={24}>
          <Button type="link" onClick={toPreviousPage}>
            Back
            <RollbackOutlined />
          </Button>
          <div className="tableSection">
            <Divider />
            <div className="table">
              <Table
                dataSource={products}
                columns={columns}
                pagination={{
                  pageSize: pageSize,
                  total: total,
                  current: current,
                  onChange: onPageChange,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GenerateBuyPlan;
