import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';

const PostponedProductsManagemnt = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [designform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [optionList, setOptionList] = useState([]);
  const [seasonOptionList, setSeasonOptionList] = useState([]);
  const dateFormat = 'YYYY-MM-DD';

  // search table
  const handleSearch = async (event) => {
    event.preventDefault();
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchDesignMaster(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(`${API}/products-to-postpone`, {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        });
        const designMasterData = await response.json();

        data1 = designMasterData.data.map((entry) => {
          let launchObj = [];
          if (entry.launch_masters?.length > 0) {
            launchObj = entry.launch_masters?.map((launch) => {
              let launchRefId = launch.id;
              let launchName = launch.launchName;
              let launchDate = launch.launchDate;
              return {
                launch_name: launchName,
                launchDate: launchDate,
                launchRefId: launchRefId,
              };
            });
          }

          return {
            id: entry.id,
            styleNo: entry.styleNo,
            SKU: entry.SKU,
            Div: entry.Div,
            date: entry.date,
            season: entry.season,
            productCategory: entry.productCategory,
            description: entry.description,
            block: entry.block,
            washing: entry.washing,
            PACKING: entry.PACKING,
            styleSample: entry.styleSample,
            launchDetails: launchObj,
          };
        });
        console.log('original Data:', designMasterData);
        console.log('Data:', data1);
        setData(data1);

        setTotal(designMasterData.length);
        setPageSize(10);
        setPageCount(Math.ceil(designMasterData.length / 10));
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // search table

  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);
    setIsLoading(false);
  };

  let data1 = [];

  const fetchDesignMaster = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/products-to-postpone`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const designMasterData = await response.json();
      console.log(designMasterData);
      // return;
      data1 = designMasterData.data.map((entry) => {
        let launchObj = [];
        if (entry.launch_masters?.length > 0) {
          launchObj = entry.launch_masters?.map((launch) => {
            let launchRefId = launch.id;
            let launchName = launch.launchName;
            let launchDate = launch.launchDate;
            return {
              launch_name: launchName,
              launchDate: launchDate,
              launchRefId: launchRefId,
            };
          });
        }

        return {
          id: entry.id,
          styleNo: entry.styleNo,
          SKU: entry.SKU,
          Div: entry.Div,
          date: entry.date,
          season: entry.season,
          productCategory: entry.productCategory,
          description: entry.description,
          block: entry.block,
          washing: entry.washing,
          PACKING: entry.PACKING,
          styleSample: entry.styleSample,
          launchDetails: launchObj,
        };
      });
      console.log('original Data:', designMasterData);
      console.log('Data:', data1);
      setData(data1);

      setTotal(designMasterData.length);
      setPageSize(5);
      setPageCount(Math.ceil(designMasterData.length / 5));
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOptions = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setOptionList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOptionsSeason = async (token) => {
    let seasonData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/seasons?`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const seasonData = await response.json();

      seasonData2 = seasonData.data.map((entry) => {
        return {
          id: entry.id,
          season_type: entry.attributes.season_type,
          season_year: entry.attributes.season_year,
          season_id: entry.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setSeasonOptionList(seasonData2);
      console.log('original Season Data:', seasonData);
      console.log('modified Data:', seasonData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchDesignMaster(authToken);
      fetchOptions(authToken);
      fetchOptionsSeason(authToken);
    }
  }, []);

  useEffect(() => {
    console.log(editData);
    designform.setFieldsValue(editData);
  }, [editData, designform]);

  if (isLoading) {
    return <Spin size="large" />;
  }
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  const addNewProductSpecification = async (data) => {
    setSaving(true);

    const values = {
      ...data,
      completionStatus: false,
      completionStage: 'postponed',
    };

    data = { data: values };

    console.log('data:', data);
    console.log('data edit:', data.data.editId);
    // return;

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(`${API}/postpone-product`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Product Specification.');
        } else {
          message.success('Product Postponed Successfully!');
          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
        setIsModalOpen(false);
      }
    } else {
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/design-masters/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product.');
        } else {
          message.success('Data saved successfully!');
          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
        setIsModalOpen(false);
      }
    }
  };

  const toDetailedRecordPage = (state) => {
    navigate('/product-details-view', { state });
  };

  const columns = [
    {
      key: 'styleNo',
      title: 'Style No',
      render: (record) => {
        return (
          <div>
            {' '}
            <a
              onClick={() => {
                toDetailedRecordPage(record);
              }}
            >
              {record.SKU}
            </a>
          </div>
        );
      },
    },
    {
      key: 'SKU',
      title: 'SKU',
      dataIndex: 'SKU',
    },
    {
      key: 'Div',
      title: 'Division',
      dataIndex: 'Div',
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
    },
    {
      key: 'season',
      title: 'Season',
      dataIndex: 'season',
    },
    {
      key: 'productCategory',
      title: 'Product Category',
      dataIndex: 'productCategory',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
    {
      key: 'block',
      title: 'Block',
      dataIndex: 'block',
    },
    {
      key: 'washing',
      title: 'Washing',
      dataIndex: 'washing',
    },
    {
      key: 'PACKING',
      title: 'Packing',
      dataIndex: 'PACKING',
    },
    {
      key: 'styleSample',
      title: 'Style Sample',
      dataIndex: 'styleSample',
    },

    {
      key: 'action',
      title: 'Actions',
      render: (record) => {
        return (
          <div>
            <div className="ActionColumn">
              <Button
                type="primary"
                style={{ color: 'black', backgroundColor: 'lightgreen' }}
                onClick={() => Edit(record)}
              >
                Post Pone
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const postPone = (record) => {};

  const Edit = (record) => {
    setEditData({
      SKU: record.SKU,
      season: record.season,
      productCategory: record.productCategory,
      description: record.description,
      launch_masters: record.launchDetails.map((launch) => launch.launchRefId),
      editId: record.id,
    });
    console.log(record);

    showModal();
  };

  const confirmEntry = (record) => {
    Modal.confirm({
      title: 'You are sure you are Marking this Entry Complete',
      onOk: () => {
        confirmMasterEntry(record);

        // setData((pre) => {
        //   return pre.filter((person) => person.id != record.id);
        // });
      },
    });
  };

  const confirmMasterEntry = async (record) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/design-masters/${record.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          data: { completionStage: 'confirmed', completionStatus: true },
        }),
      });
      const responseData = await response.json();
      console.log(responseData);

      fetchDesignMaster(getToken());
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div>All In Progress Product</div>

      <div className="FilterSection">
        {/** search new */}

        <form onSubmit={handleSearch} className="searchBarr">
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit"> Search</button>
        </form>

        <br />

        {/** search new */}

        {/** search old
            <Input type="text"
          size="large"
          name="searchInput"
          value={searchInput}
          onChange={async (event) => setSearchInput(event.target.value)}
          // onKeyPress={(event) => handleSearchBarKeyPress(event)}
          label="Search"
        />

        <Button onClick={handleSearch} >
          search
        </Button>    
    */}
      </div>

      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
        />
      </div>

      <Modal
        title="Postpone Details"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form
            form={designform}
            layout="vertical"
            onFinish={addNewProductSpecification}
          >
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="SKU"
                  name="SKU"
                  rules={[
                    {
                      required: true,
                      message: 'SKU is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="SKU" disabled={true} />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Season"
                  name="season"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Season" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Product Category"
                  name="productCategory"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Product Category" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Description" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch"
                  name="launch_masters"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select" mode="multiple">
                    {optionList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.launchName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Form.Item label="Username" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default PostponedProductsManagemnt;
