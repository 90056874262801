import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  DatePicker,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {
  getMonthNameFromStringDate,
  getMonthIndex,
  getDateFromMonth,
  dateFormat,
  monthNames,
} from '../../utils/dateUtils';

const TechpackMaster = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // Edit Data for editing existing record
  const [editData, setEditData] = useState(null);
  const [launchMastersList, setLaunchMastersList] = useState([]);

  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [dataform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  //options for form

  const [filterCloumnData, setFilterCloumnData] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState('');

  //filter global
  const [searchText, setSearchText] = useState('');
  const [filterScope, setFilterScope] = useState('all');
  let [filteredData, setFilteredData] = useState([]);

  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const filterScopeOptionsArray = [
    'all',
    'techpack_file_date',
    'sku',
    'techpack_file_reference_id',

    // Disabled: no need to map supplier to fabric
    // 'suppliers',
  ];

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/techpacks?populate[launch_master][populate]=true&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;
        data1 = responseData.data.map((entry) => {
          console.log(
            responseData,
            entry.attributes?.launch_master?.data,
            'Techpack check'
          );

          let launchObj = {};
          if (entry.attributes?.launch_master?.data) {
            const launchMasterObject = entry.attributes?.launch_master?.data;
            console.log(launchMasterObject, 'check last');
            launchObj = {
              launchRefId: launchMasterObject.id,
              launchName: launchMasterObject.attributes.launchName,
              launchCategory: launchMasterObject.attributes.launchCat,
              launchMonth: launchMasterObject.attributes.launchMonth,
              launchDrop: launchMasterObject.attributes.launchDrop,
              launchDate: launchMasterObject.attributes.launchDate,
            };
          }

          return {
            key: entry.id,
            id: entry.id,
            techpack_file_link: entry.attributes.techpack_file_link,
            techpack_file_reference_id:
              entry.attributes.techpack_file_reference_id,
            techpack_file_date: entry.attributes.techpack_file_date,
            sku: entry.attributes.sku,
            launch_master: launchObj,
            // supplierDetails: supplierObj,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);

        setData(data1);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLaunchList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id&createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          launchDrop: entry.attributes.launchDrop,
          //   launchCat: entry.attributes.launchCat,

          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setLaunchMastersList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  // Disabled: no need to map supplier to fabric
  // selecting the dropdowns data
  // const fetchSupplier = async (token) => {
  //   let supplierData2 = [];
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch(`${API}/suppler-masters?`, {
  //       headers: { Authorization: `${BEARER} ${token}` },
  //     });
  //     const responseData = await response.json();
  //     console.log(responseData);
  //     if (responseData.error) {
  //       message.error('Something went wrong, please try again');
  //     } else {
  //       supplierData2 = responseData.data.map((entry) => {
  //         return {
  //           id: entry.id,
  //           supplierName: entry.attributes.supplierName,

  //           // launch_masters: entry.attributes.launch_masters,
  //         };
  //       });

  //       setSupplierList(supplierData2);
  //       console.log('original supplier Data:', responseData);
  //       console.log('modified supplier Data:', supplierData2);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     message.error('Error while fetching Data!');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }; // end function fetchSeason

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
      fetchLaunchList(authToken);
      // Disabled: no need to map supplier to fabric
    }
  }, []);

  // for opening add or edit form with data or change in the reference of data form when form is closed or page is reloaded
  useEffect(() => {
    //dataform data
    console.log(editData);
    dataform.setFieldsValue(editData);
  }, [editData, dataform]);

  // show the modal
  const showModal = () => {
    setIsModalOpen(true);
    dataform.setFieldsValue({ techpack_file_date: moment() });
  };

  // hide and reset add/edit form inside modal
  const handleOk = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  // hide and reset add/edit form inside modal
  const handleCancel = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  //add record to database table by api call
  const addNewRecord = async (data) => {
    setSaving(true);

    //add or removing extra data from values from formData object
    const values = {
      ...data,
      techpack_file_date: data['techpack_file_date'].format('YYYY-MM-DD'),
      techpack_file_reference_id: data?.sku ? data.sku : null,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);
    // return;
    //editEntityId will be present only in case of edit mode
    console.log('data edit:', data.data.editEntityId);

    if (data.data.editEntityId) {
      console.log('edit id :', data.data.editEntityId);
      // update the record
      try {
        const response = await fetch(
          `${API}/techpacks/${data.data.editEntityId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Record.');
        } else {
          message.success('Data saved successfully!');

          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editEntityId); //should be falsy

      try {
        const response = await fetch(`${API}/techpacks/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Record.');
        } else {
          message.success('Data saved successfully!');
          //
          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    }
  };
  //end function addNewRecord

  const columns = [
    {
      key: 'techpack_file_link',
      title: 'Techpack File Link',
      dataIndex: 'techpack_file_link',
      className: 'antd-column-large',
    },

    {
      key: 'techpack_launch_name',
      title: 'Collection Name',
      dataIndex: 'techpack_launch_name',
      render: (_, record) => record?.launch_master?.launchName,
    },
    {
      key: 'techpack_launch_month',
      title: 'Launch Month',
      dataIndex: 'techpack_launch_month',
      render: (_, record) => record?.launch_master?.launchMonth,
    },

    {
      key: 'techpack_file_reference_id',
      title: 'Techpack File Reference id',
      dataIndex: 'techpack_file_reference_id',
    },
    {
      key: 'techpack_file_date',
      title: 'techpack file date',
      dataIndex: 'techpack_file_date',
    },
    {
      key: 'sku',
      title: 'sku',
      dataIndex: 'sku',
    },

    //Disabled: no need to map supplier to fabric
    // {
    //   key: 'suppliers',
    //   title: 'supplier Name',
    //   dataIndex: 'suppliers',
    //   render: (_, record) =>
    //     record.supplierDetails.supplierName
    //       ? record.supplierDetails.supplierName
    //       : 'NA',
    // },
    {
      key: 'action',
      title: 'Actions',

      render: (_, record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <Button
                icon={
                  <DeleteOutlined style={{ color: 'red', marginRight: 8 }} />
                }
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/techpacks/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        message.success('Successfully Deleted!');
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    console.log(record);

    // set data for add or edit form
    setEditData({
      techpack_file_link: record.techpack_file_link,
      techpack_file_reference_id: record.techpack_file_reference_id,
      techpack_file_date:
        (record?.techpack_file_date && moment(record?.techpack_file_date)) ||
        null,

      // moment(record.techpack_file_date), // date ,
      sku: record.sku,
      launch_master: record.launch_master.launchRefId,

      // Disabled: no need to map supplier to fabric
      // suppliers: record.supplierDetails.supplierRefId,
      editEntityId: record.id, // its important for edit functionality
    });
    showModal(); // after setting the data for the edit form show the modal
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log('in handle table change', pagination, filters, sorter);
    const { order, columnKey } = sorter;
    setFilterInfo(filters);
    setSortedInfo({ columnKey, order });
  };

  //reset table
  const resetTable = () => {
    setSortedInfo({});
    setFilterInfo({});
    setSearchText('');
    setFilterScope('all');
    setAlreadySelectedRows([]);
    fetchData(authToken);
  };

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);

    if (e.target.value == '') {
      fetchData(authToken);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (searchText == '') {
      fetchData(authToken);
      setFilteredData([]);
    } else {
      filterGlobalSearch();
    }
  }, [searchText, filterScope]);

  // : on search button click
  const filterGlobalSearch = () => {
    let filteredDataTemp = [];
    if (filterScope == 'all') {
      filteredDataTemp = Data.filter((value) => {
        return (
          value?.techpack_file_link
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          value.techpack_file_reference_id
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          value?.techpack_file_date
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          value?.sku?.toLowerCase()?.includes(searchText?.toLowerCase())
        );
      });
    } else if (filterScope == 'techpack_file_link') {
      filteredDataTemp = Data.filter((value) => {
        return value?.techpack_file_link
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());
      });
    } else if (filterScope == 'techpack_file_date') {
      filteredDataTemp = Data.filter((value) => {
        return value?.techpack_file_date
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());
      });
    } else if (filterScope == 'sku') {
      filteredDataTemp = Data.filter((value) => {
        return value?.sku?.toLowerCase()?.includes(searchText?.toLowerCase());
      });
    } else if (filterScope == 'techpack_file_reference_id') {
      filteredDataTemp = Data.filter((value) => {
        return value?.techpack_file_reference_id
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());
      });
    }

    // Disabled: no need to map supplier to fabric
    //  else if (filterScope == 'suppliers') {
    //   filteredDataTemp = Data.filter((value) => {
    //     return value.supplierDetails.supplierName
    //       .toLowerCase()
    //       .includes(searchText.toLowerCase());
    //   });
    // }

    console.log(filteredDataTemp, 'filtered data', filterScope, searchText);
    if (filteredDataTemp.length > 0) {
      setFilteredData(filteredDataTemp);
    } else {
      filteredDataTemp.push({
        key: 0,
        id: 0,
        techpack_file_link: 'Not Found',
        techpack_file_reference_id: 'Not Found',
        techpack_file_date: 'Not Found',
        sku: 'Not Found',

        // Disabled: no need to map supplier to fabric
        // supplierDetails: 'Not Found',
      });
      setFilteredData(filteredDataTemp);
    }
  };
  const handleFilterScopeChange = (value) => {
    console.log('setFilterScope', value);
    setFilterScope(value);
  };

  const getFilterOptionArray = (options) => {
    return options.map((option) => {
      return { value: option, label: option };
    });
  };

  const filterOptionArray = [...getFilterOptionArray(filterScopeOptionsArray)];

  const launchListOptions = launchMastersList.map((launch) => {
    return {
      label: launch.launchName,
      value: launch.id,
    };
  });

  const getFormattedData = () => {
    const downloadData =
      filteredData && filteredData.length ? filteredData : Data;
    // console.log(downloadData, 'downloading data...');
    // return [];
    let downloadDataFormatted = downloadData.map((row) => {
      console.log(row, 'downloading data row...');
      return {
        ID: row.id,
        techpack_file_link: row.techpack_file_link,
        'Launch Name': row.launch_master?.launchName,
        'Launch Drop': row.launch_master?.launchDrop,
        techpack_file_reference_id: row.techpack_file_reference_id,
        techpack_file_date: row.techpack_file_date,
        sku: row.sku,
      };
    });

    console.log(downloadDataFormatted, 'downloading data 2...');
    return downloadDataFormatted;
  };

  return (
    <div>
      <h2>Techpack Master</h2>
      {/* Modal */}
      <Button onClick={showModal}>Add Techpack Master</Button>

      {/* Table Section */}
      <div className="table">
        {/* Filter search Section */}
        <div className="filterContainer">
          <div className="tableSearchSection">
            <Select
              defaultValue="all"
              className="filterScopeSelect"
              onChange={handleFilterScopeChange}
              options={filterOptionArray}
            />

            <Input
              placeholder="Enter Search Text"
              onChange={handleChangeSearch}
              type="text"
              allowClear
              value={searchText}
            ></Input>
            <Button onClick={resetTable}>Reset</Button>
            <Button style={{ backgroundColor: 'greenyellow', color: 'white' }}>
              <CSVLink
                data={getFormattedData()}
                filename={
                  'Indent-Master-Records-list-' +
                  moment(new Date()).format('DD-MM-YYYY')
                }
              >
                Download
              </CSVLink>
            </Button>
          </div>
        </div>
        <Table
          dataSource={filteredData && filteredData.length ? filteredData : Data}
          columns={columns}
          onChange={handleTableChange}
          pagination={{ position: ['bottomCenter'] }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: alreadySelectedRows,
            onChange: (keys) => {
              setAlreadySelectedRows(keys);
            },
            onSelect: (record) => {
              console.log(record, 'selected row ');
            },
            selections: [
              Table.SELECTION_NONE,
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
            ],
          }}
        />
      </div>

      {/* Modal For Add Or Edit */}
      <Modal
        title="Add New Techpack"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form form={dataform} layout="vertical" onFinish={addNewRecord}>
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Techpack Link"
                  name="techpack_file_link"
                  rules={[
                    {
                      required: true,
                      message: 'Techpack Link is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Tech Pack URL" />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Techpack File Date"
                  name="techpack_file_date"
                  rules={[
                    {
                      required: true,
                      message: 'Techpack Date is Required!',
                      type: 'object',
                    },
                  ]}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item name="sku" label="SKU">
                  <Input placeholder="SKU" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch Master"
                  name="launch_master"
                  rules={[
                    {
                      required: true,
                      message: 'Launch is Required!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onSearch={() => {
                      //
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={launchListOptions}
                  />
                </Form.Item>
              </Col>

              <Form.Item label="Username" hidden={true} name="editEntityId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};
export default TechpackMaster;
