import moment from 'moment';

export const dateFormat = 'YYYY-MM-DD';


export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const getMonthName = (dateObj) => {
    return monthNames[dateObj.getMonth()];
}

export const getMonthNameFromStringDate = (dateString) => {
    console.log("date moment in date utils", moment(dateString));
    if (!dateString) {
        return "Not Found";
    } else if (dateString !== -1) {
        const dateObj = moment(dateString);
        console.log("monthChange", dateObj.format('MMMM'));
        // const dateObj = new Date(dateString);
        return dateObj.format('MMMM');

    } else {
        return "Not Found";
    }
}

export const getMonthIndex = (monthName) => {

    for (let i = 0; i < monthNames.length; i++) {
        if (monthNames[i].toLowerCase() == monthName.toLowerCase()) {
            return i;
        }
    }
};

export const getDateFromMonth = (monthName) => {
    const date = new Date();
    let monthIndex = getMonthIndex(monthName);
    date.setMonth(monthIndex);
    return date;
};

export const compareMonth = (month1, month2) => {
    const month1Index = getMonthIndex(month1);
    const month2Index = getMonthIndex(month2);
    console.log(month1Index, month2Index, "ln48 date utils");
    return month1Index > month2Index ? true : false;
};


export const sortArrayOfObjects = (array, propertyName, accendingOrder) => {

    const monthsInYear = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    // Create a mapping of month names to their indices
    const monthIndices = {};
    monthsInYear.forEach((month, index) => {
        monthIndices[month.toLowerCase()] = index;
    });

    const sortOrder = accendingOrder ? 1 : -1;

    // Sort the array based on the month property of each object
    array.sort((a, b) => sortOrder * (monthIndices[a[propertyName].toLowerCase()] - monthIndices[b[propertyName].toLowerCase()]));
    return array;
}

// Example usage:
const unsortedRecords = [
    { month: "May", value: 42 },
    { month: "April", value: 17 },
    { month: "July", value: 30 },
    // ... more records
];



export const sortRecordsByMonth = async (recordsArray, accendingOrder) => {
    return new Promise((resolve) => {
        const sortedRecords = sortArrayOfObjects(recordsArray, 'month', accendingOrder);
        console.log(sortedRecords, "ln84 dateutils");
        resolve(sortedRecords);
    });
};


export const sortArrayOfObjectsForSeason = (array, season, accendingOrder, seasonList) => {
    console.log(array, accendingOrder);
    const sessionArr = [
        'SS19',
        'SS23',
        'AW23',
        'AW24',
        'SS24',
        'AW25',
        'SS25',
        'AW26',
        'SS26',
        'AW27',
        'SS27',
    ];

    const seasonIndices = {};
    // sessionArr.forEach((season, index) => {
    //     seasonIndices[season.toLowerCase()] = index;
    // });
    seasonList.forEach((season, index) => {
        seasonIndices[season.toLowerCase()] = index;
    });

    const sortOrder = accendingOrder ? 1 : -1;

    array.sort((a, b) => {
        console.log(
            'in season sort',
            seasonIndices,
            a,
            b,
            a['launchDetails']['seasonDetails']['seasonId'],
            b['launchDetails']['seasonDetails']['seasonId'],
            a['launchDetails']['seasonDetails']['seasonId'].toLowerCase(),
            b['launchDetails']['seasonDetails']['seasonId'].toLowerCase(),
            seasonIndices[
            a['launchDetails']['seasonDetails']['seasonId'].toLowerCase()
            ],
            seasonIndices[
            b['launchDetails']['seasonDetails']['seasonId'].toLowerCase()
            ],
            sortOrder *
            (seasonIndices[
                a['launchDetails']['seasonDetails']['seasonId'].toLowerCase()
            ] -
                seasonIndices[
                b['launchDetails']['seasonDetails']['seasonId'].toLowerCase()
                ])
        );
        return (
            sortOrder *
            (seasonIndices[
                a['launchDetails']['seasonDetails']['seasonId'].toLowerCase()
            ] -
                seasonIndices[
                b['launchDetails']['seasonDetails']['seasonId'].toLowerCase()
                ])
        );
    });
    return array;
};




export const sortRecordsBySeason = (recordsArray, accendingOrder, seasonList) => {


    return new Promise((resolve) => {
        // Sort the array based on the month property of each object
        const sortedRecords = sortArrayOfObjectsForSeason(recordsArray, 'seasonId', accendingOrder, seasonList);
        console.log(sortedRecords, "ln145 dateutils");
        resolve(sortedRecords);
        // console.log(sortedArray);

    });
};




export default getMonthName;