import React from 'react'
import EditableDataTable from '../../components/EditableDataTable/EditableDataTable'
const OTBPlan = () => {
  return (
    <div>
            <h1>OTB Plan Details</h1>
            {<EditableDataTable />}    
    </div>
  )
}

export default OTBPlan