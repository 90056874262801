import React, { useState } from "react";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import { message } from "antd";
import { API, BEARER } from "../../constant";
import { useEffect } from "react";
import { getToken } from "../../helpers";

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [reqRefresh, setReqRefresh] = useState(false);
  // const { setUser } = useAuthContext();

  
  const authToken = getToken();
  // console.log("authtoken: ", authToken, userData);

  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/users/me?populate=*`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const data = await response.json();
      let rolesAssignedArray = data.roles_permisions;
      let rolesAssigned = [];
      rolesAssignedArray.map(rolesAssigneditem => {
        rolesAssigned.push(rolesAssigneditem.role_id);
      });
      console.log("roles Assigned : ", rolesAssigned);
      data.rolesAssigned=rolesAssigned;
      console.log(data);
      setUserData(data);
      // setUser(data);

    } catch (error) {
      console.error(error);
      message.error("Error While Getting Logged In User Details");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  
  useEffect(() => {    
    console.log("i am in on load component use effect");
    if(authToken) {
      fetchLoggedInUser(authToken); 
    }
    
}, []);

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken);
    }
  }, [authToken]);



  
  return (
    <AuthContext.Provider
      value={{ user: userData,  isLoading:isLoading, setUser: handleUser }} >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
