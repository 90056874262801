import React from 'react';

import { Tabs } from 'antd';
import CoreProductList from '../CoreProduct/CoreProductList';
import BookedProductList from '../BookedProductList/BookedProductList';
import BookableProductList from '../BookableProductList/BookableProductList';

// import PostponedProductsManagemnt from '../PostponedProductsManagemnt/PostponedProductsManagemnt';
// import PostponedProducts from '../PostponedProducts/PostponedProducts';

const { TabPane } = Tabs;

const ProductManagement = () => {
  return (
    <div>
      <div> Product Management Portals</div>
      <div>
        <Tabs>
          <TabPane tab="Core Product" key="coreProductList">
            <CoreProductList />
          </TabPane>
          <TabPane tab="Bookable Products" key="bookableProductList">
            <BookableProductList />
          </TabPane>
          <TabPane tab="Booked Products" key="bookedProductList">
            <BookedProductList />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ProductManagement;
