// export const AVATAR_API = "https://ui-avatars.com/api";
// export const API = "http://localhost:1337/api";
// export const AUTH_TOKEN = "";
// export const BEARER = "Bearer";
// export const downloadUrl = "http://localhost:1337";

export const AVATAR_API = "https://ui-avatars.com/api";
export const API = "https://plm-dev-backend.andamen.com/api";
export const AUTH_TOKEN = "";
export const BEARER = "Bearer";
export const downloadUrl = "https://plm-dev-backend.andamen.com";