import React from 'react'
import { Typography } from 'antd';
import { downloadUrl} from "../../constant";
const { Text } = Typography;



const ListOfItem = ({ data }) => {

        const downloadfunction = (url) =>{
            window.open(`${downloadUrl}${url}`, '_blank', 'noreferrer');
        };


    let itemList = [];
    if (data?.length > 0) {
        itemList = data?.map((file) =>
            <li>
            <Text strong> {file.file_type} </Text> <a onClick={() => { downloadfunction(file.url) }}>{file.file_name}</a>

            </li>
        );


    }



    return (
        <ul>
            {itemList}
        </ul>
    );
}

export default ListOfItem