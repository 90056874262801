import React from 'react';
import qs from 'qs';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import { v4 as uuidv4 } from 'uuid';

const CityMaster1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // Edit Data for editing existing record
  const [editData, setEditData] = useState(null);

  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();

  const [dataform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  //options for dropdown in modal
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  //data source for datatable
  const [Data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);

  //search
  const [searchInput, setSearchInput] = useState('');

  //filter
  const [filterCloumnData, setFilterCloumnData] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});

  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [selectedStateValue, setSelectedStateValue] = useState(null);

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/cities?pagination[page]=${current}&pagination[pageSize]=${pageSize}&populate[0]=state&populate[state][populate][0]=country&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;

        data1 = responseData.data.map((entry) => {
          console.log(responseData);

          let stateObj = {};
          if (entry.attributes.state.data) {
            stateObj = {
              stateRefId: entry.attributes.state.data.id,
              stateName: entry.attributes.state.data.attributes.stateName,
              countryObj: entry.attributes.state.data.attributes.country.data,
            };
          }

          console.log(stateObj.countryObj);

          let countryObj = {};
          if (stateObj.countryObj) {
            countryObj = {
              countryRefId: stateObj.countryObj.id,
              countryName: stateObj.countryObj.attributes.countryName,
            };
          }

          return {
            id: entry.id,
            cityName: entry.attributes.cityName,
            stateDetails: stateObj,
            countryDetails: countryObj,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);

        setData(data1);

        setTotal(responseData.meta.pagination.total);
        setPageSize(responseData.meta.pagination.pageSize);
        setPageCount(responseData.meta.pagination.pageCount);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  // selecting the dropdowns data
  const fetchCountries = async (token) => {
    let countriesData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/countries?`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        countriesData2 = responseData.data.map((entry) => {
          return {
            id: entry.id,
            countryName: entry.attributes.countryName,

            // launch_masters: entry.attributes.launch_masters,
          };
        });

        setCountryList(countriesData2);
        console.log('original countries Data:', responseData);
        console.log('modified countries Data:', countriesData2);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  }; // end function fetchSeason

  const fetchState = async (token) => {
    let stateData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/states?`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        stateData2 = responseData.data.map((entry) => {
          return {
            id: entry.id,
            stateName: entry.attributes.stateName,
          };
        });

        setStateList(stateData2);
        console.log('original state Data:', responseData);
        console.log('modified state Data:', stateData2);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  }; // end function fetchSeason

  //filter options
  const fetchFilterOptions = async (token) => {
    let filterDataObj = [];
    filterDataObj['cityName'] = [];
    filterDataObj['stateName'] = [];
    filterDataObj['countryName'] = [];

    let filterOptionData = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/cities?fields[0]=cityName&populate[state][fields][0]=stateName&populate[state][fields][1]=id&populate[state][populate][country][fields][0]=countryName`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      console.log('filteroptonData', responseData);
      //   return;
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        filterOptionData = responseData.data.map((entry) => {
          //city
          if (
            !filterDataObj['cityName'].find((element) => {
              return element == entry.attributes.cityName;
            }) &&
            entry.attributes.cityName !== null
          ) {
            filterDataObj['cityName'].push(entry.attributes.cityName);
          }
          //state
          if (
            !filterDataObj['stateName'].find((element) => {
              return (
                element == entry.attributes.state.data.attributes.stateName
              );
            }) &&
            entry.attributes.state.data.attributes.stateName !== null
          ) {
            filterDataObj['stateName'].push(
              entry.attributes.state.data.attributes.stateName
            );
          }

          //country
          if (
            !filterDataObj['countryName'].find((element) => {
              return (
                element ==
                entry.attributes.state.data.attributes.country.data.attributes
                  .countryName
              );
            }) &&
            entry.attributes.state.data.attributes.country.data.attributes
              .countryName !== null
          ) {
            filterDataObj['countryName'].push(
              entry.attributes.state.data.attributes.country.data.attributes
                .countryName
            );
          }

          return {};
        });

        console.log('filter column data:', filterDataObj);
        setFilterCloumnData(filterDataObj); // setting filter data
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCountryChage = (value) => {
    console.log(value);
    setSelectedCountryValue(value);
  };

  useEffect(() => {
    const fetchSelectedState = async (token) => {
      let stateData2 = [];
      setIsLoading(true);
      try {
        const response = await fetch(
          `${API}/states?populate[0]=country&filters[country][id][$eq]=${selectedCountryValue}`,
          {
            headers: { Authorization: `${BEARER} ${token}` },
          }
        );
        const responseData = await response.json();
        console.log('dependent select', responseData);
        if (responseData.error) {
          message.error('Something went wrong, please try again');
        } else {
          stateData2 = responseData.data.map((entry) => {
            return {
              id: entry.id,
              stateName: entry.attributes.stateName,
            };
          });

          setStateList(stateData2);
          console.log('original state Data:', responseData);
          console.log('modified state Data:', stateData2);
        }
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }; // end function fetchSelectedState

    fetchSelectedState(getToken());
  }, [selectedCountryValue]);

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
      fetchFilterOptions(authToken);

      fetchCountries(authToken);
      fetchState(authToken);
    }
  }, []);

  // for opening add or edit form with data or change in the reference of data form when form is closed or page is reloaded
  useEffect(() => {
    //dataform data
    console.log(editData);
    dataform.setFieldsValue(editData);
  }, [editData, dataform]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  // hide and reset add/edit form inside modal
  const handleOk = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  // hide and reset add/edit form inside modal
  const handleCancel = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  // search table
  const handleSearch = async (event) => {
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchData(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(
          `${API}/cities?filters[$or][0][cityName][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=state&populate[state][populate][0]=country&sort[0]=createdAt:desc`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const responseData = await response.json();
        if (responseData.error) {
          message.error('Unable to fetch Records, please try again later.');
        } else {
          data1 = responseData.data.map((entry) => {
            console.log(responseData);

            let stateObj = {};
            if (entry.attributes.state.data) {
              stateObj = {
                stateRefId: entry.attributes.state.data.id,
                stateName: entry.attributes.state.data.attributes.stateName,
                countryObj: entry.attributes.state.data.attributes.country.data,
              };
            }

            console.log(stateObj.countryObj);

            let countryObj = {};
            if (stateObj.countryObj) {
              countryObj = {
                countryRefId: stateObj.countryObj.id,
                countryName: stateObj.countryObj.attributes.countryName,
              };
            }

            return {
              id: entry.id,
              cityName: entry.attributes.cityName,
              stateDetails: stateObj,
              countryDetails: countryObj,
            };
          });
          console.log('original Data:', responseData);
          console.log('Data:', data1);
          setData(data1);

          setTotal(responseData.meta.pagination.total);
          setPageSize(responseData.meta.pagination.pageSize);
          setPageCount(responseData.meta.pagination.pageCount);
        }
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };
  // Search Table

  //add record to database table by api call
  const addNewRecord = async (data) => {
    setSaving(true);

    //add or removing extra data from values from formData object
    const values = {
      ...data,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);
    // return;
    //editId will be present only in case of edit mode
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);
      // update the record
      try {
        const response = await fetch(`${API}/cities/${data.data.editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Record.');
        } else {
          message.success('Data saved successfully!');

          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId); //should be falsy

      try {
        const response = await fetch(`${API}/cities/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Record.');
        } else {
          message.success('Data saved successfully!');
          //
          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    }
  };
  //end function addNewRecord

  // on every page change by the user
  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);

    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/cities?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[0]=state&populate[state][populate][0]=country&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      if (paginationData.error) {
        message.error('Something went wrong, please try again');
      } else {
        data2 = paginationData.data.map((entry) => {
          console.log(paginationData);

          let stateObj = {};
          if (entry.attributes.state.data) {
            stateObj = {
              stateRefId: entry.attributes.state.data.id,
              stateName: entry.attributes.state.data.attributes.stateName,
              countryObj: entry.attributes.state.data.attributes.country.data,
            };
          }

          console.log(stateObj.countryObj);

          let countryObj = {};
          if (stateObj.countryObj) {
            countryObj = {
              countryRefId: stateObj.countryObj.id,
              countryName: stateObj.countryObj.attributes.countryName,
            };
          }

          return {
            id: entry.id,
            cityName: entry.attributes.cityName,
            stateDetails: stateObj,
            countryDetails: countryObj,
          };
        });
        console.log('paging original Data:', paginationData);
        console.log('paging modified Data:', data2);
        setData(data2);
        // let page = paginationData.meta.pagination.page;

        setTotal(paginationData.meta.pagination.total);
        setPageSize(paginationData.meta.pagination.pageSize);
        setPageCount(paginationData.meta.pagination.pageCount);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };
  //end function onPageChange

  const getFiltersArray = (columnDataIndex) => {
    const filterArraycityName = filterCloumnData['cityName']
      ? filterCloumnData['cityName'].map((item) => {
          return { text: item, value: item };
        })
      : [];

    const filterArraystate = filterCloumnData['stateName']
      ? filterCloumnData['stateName'].map((item) => {
          return { text: item, value: item };
        })
      : [];

    const filterArraycountry = filterCloumnData['countryName']
      ? filterCloumnData['countryName'].map((item) => {
          return { text: item, value: item };
        })
      : [];

    console.log('filter dropdown', filterArraystate);

    switch (columnDataIndex) {
      case 'cityName':
        return filterArraycityName;
        break;
      case 'state':
        return filterArraystate;
        break;
      case 'country':
        return filterArraycountry;
        break;
      default:
        return [];
    }
  };

  console.log('realll', getFiltersArray());

  const columns = [
    {
      key: 'cityName',
      title: 'City',
      dataIndex: 'cityName',
      filters: [...getFiltersArray('cityName')],
      filteredValue: filterInfo.cityName,
      onFilter: (value, record) => {
        // return String(record.attributionColor).includes(value);
      },
    },
    {
      key: 'state',
      title: 'State',
      filters: [...getFiltersArray('state')],
      filteredValue: filterInfo.state,

      render: (record) =>
        record.stateDetails.stateName ? record.stateDetails.stateName : 'NA',
    },
    {
      key: 'country',
      title: 'Country',
      // dataIndex: "country",
      filters: [...getFiltersArray('country')],
      filteredValue: filterInfo.country,
      render: (record) =>
        record.countryDetails.countryName
          ? record.countryDetails.countryName
          : 'NA',
    },
    {
      key: 'action',
      title: 'Actions',
      render: (record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <DeleteOutlined
                style={{ color: 'red' }}
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/cities/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    console.log(record);
    setEditData({
      cityName: record.cityName,
      state: record.stateDetails.stateRefId,
      country: record.countryDetails.countryRefId,
      editId: record.id, // its important for edit functionality
    });
    showModal(); // after setting the data for the edit form show the modal
  };

  const fetchDataOnTableChange = async (
    filters,
    pagination,
    populate,
    token
  ) => {
    setIsLoading(true);
    console.log(filters, pagination, 'check');
    const query = qs.stringify(
      {
        filters: filters,
        populate: populate,
        pagination: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    console.log(query, 'check');

    try {
      const result = await fetch(`${API}/cities?${query}`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });

      // const result2 = await fetch(
      //   `${API}/cities?populate[state][filters][stateName][$eq]=punjab-indian region&pagination[page]=1&pagination[pageSize]=10`,
      //   {
      //     headers: { Authorization: `${BEARER} ${token}` },
      //   }
      // );
      // const responseData2 = await result2.json();
      // console.log(responseData2);
      const responseData = await result.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        data1 = responseData.data.map((entry) => {
          console.log(responseData);

          let stateObj = {};
          if (entry.attributes.state.data) {
            stateObj = {
              stateRefId: entry.attributes.state.data.id,
              stateName: entry.attributes.state.data.attributes.stateName,
              countryObj: entry.attributes.state.data.attributes.country.data,
            };
          }

          console.log(stateObj.countryObj);

          let countryObj = {};
          if (stateObj.countryObj) {
            countryObj = {
              countryRefId: stateObj.countryObj.id,
              countryName: stateObj.countryObj.attributes.countryName,
            };
          }

          return {
            id: entry.id,
            cityName: entry.attributes.cityName,
            stateDetails: stateObj,
            countryDetails: countryObj,
          };
        });

        setData(data1);

        setTotal(responseData.meta.pagination.total);
        setPageSize(responseData.meta.pagination.pageSize);
        setPageCount(responseData.meta.pagination.pageCount);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setFilterInfo(filters);
    let selectedPopulateObj = {
      state: {
        populate: {
          country: {
            populate: true,
          },
        },
      },
    };
    let selectedFilterObj = { $and: [] };
    let selectedFilterArray = [];
    console.log('table props or state changed', pagination, filters, sorter);
    if (pagination) {
      const { current, pageSize, total } = pagination;
    }
    if (filters) {
      let filterArray = Object.keys(filters);
      console.log(filterArray);
      console.log(filters[filterArray[2]]);
      for (let i = 0; i < filterArray.length; i++) {
        if (
          filters[filterArray[i]] != null &&
          filters[filterArray[i]].length > 0
        ) {
          console.log(filterArray[i]);
          console.log(filters[filterArray[i]]);

          if (filterArray[i] == 'state') {
            console.log('In State', filterArray[i]);
            console.log(filters[filterArray[i]]);
            // populate 1 level filter stateName with $eq
            if (filters[filterArray[i]].length > 1) {
              const stateFilter = {
                [filterArray[i]]: {
                  stateName: {
                    $in: filters[filterArray[i]],
                  },
                },
              };
              selectedFilterObj['$and'].push(stateFilter);
            } else {
              const stateFilter = {
                [filterArray[i]]: {
                  stateName: {
                    $in: filters[filterArray[i]],
                  },
                },
              };
              selectedFilterObj['$and'].push(stateFilter);
            }
          } else if (filterArray[i] == 'country') {
            console.log('In country', filterArray[i]);
            console.log(filters[filterArray[i]]);
            // populate 2 level state -> country filter countryName with $eq
            if (filters[filterArray[i]].length > 1) {
              const countryFilter = {
                ['state']: {
                  country: {
                    countryName: {
                      $in: filters[filterArray[i]],
                    },
                  },
                },
              };
              selectedFilterObj['$and'].push(countryFilter);
            } else {
              const countryFilter = {
                ['state']: {
                  country: {
                    countryName: {
                      $in: filters[filterArray[i]],
                    },
                  },
                },
              };
              selectedFilterObj['$and'].push(countryFilter);
            }
          } else if (filterArray[i] == 'cityName') {
            console.log('In city', filterArray[i]);
            console.log(filters[filterArray[i]]);
            if (filters[filterArray[i]].length > 1) {
              console.log('Filter cusntruction', filters[filterArray[i]]);
              const cityNameFilter = {
                [filterArray[i]]: {
                  $in: filters[filterArray[i]],
                },
              };
              selectedFilterObj['$and'].push(cityNameFilter);
            } else {
              const cityNameFilter = {
                [filterArray[i]]: {
                  $in: filters[filterArray[i]],
                },
              };
              selectedFilterObj['$and'].push(cityNameFilter);
            }
          }
        }
      }
    }

    console.log('flterObject Created', selectedFilterObj);

    fetchDataOnTableChange(
      selectedFilterObj,
      pagination,
      selectedPopulateObj,
      authToken
    );
  };

  return (
    <div>
      <h2>City Master</h2>
      <Button onClick={showModal}>Add New City</Button>
      <div className="FilterSection">
        <form onSubmit={handleSearch} className="searchBarr">
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit"> Search</button>
        </form>
      </div>
      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
          onChange={handleTableChange}
        />
      </div>
      <Modal
        title="Add New State"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addRecord_page_card">
          <Form form={dataform} layout="vertical" onFinish={addNewRecord}>
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: 'Country is Required!',
                    },
                  ]}
                >
                  <Select placeholder="Select " onChange={handleCountryChage}>
                    {countryList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.countryName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="state"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: 'state is Required!',
                    },
                  ]}
                >
                  <Select placeholder="Select ">
                    {stateList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.stateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="City Name"
                  name="cityName"
                  rules={[
                    {
                      required: true,
                      message: 'City Name is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="City Name" />
                </Form.Item>
              </Col>

              <Form.Item label="editId" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>

            <Button
              className="addRecord_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default CityMaster1;
