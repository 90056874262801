import React from 'react';
import { Col, Layout, Row } from 'antd';
import AppHeader from './components/Appheader/Appheader';
import AppRoutes from './Routes';
import './index.css';
import SiderMenu from './components/SiderMenu/SiderMenu';
import { getToken } from './helpers';
import { useAuthContext } from './context/AuthContext';

const { Header, Content } = Layout;

const App = () => {
  const { user } = useAuthContext();

  return (
    <Layout>
      {getToken() ? (
        <>
          <SiderMenu />
          <Layout>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Header>
                  <AppHeader />
                </Header>
              </Col>
              <Col span={22} offset={1}>
                <Content>
                  <AppRoutes />
                </Content>
              </Col>
            </Row>
          </Layout>
        </>
      ) : (
        <>
          <Layout>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Header>
                  <AppHeader />
                </Header>
              </Col>
              <Col span={22} offset={1}>
                <Content>
                  <AppRoutes />
                </Content>
              </Col>
            </Row>
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default App;
