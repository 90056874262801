import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { getUserDetails } from "../helpers";


const RequiredAuth = ({ allowedRoles }) => {
    // const { user1 } = useAuthContext();
    const user = getUserDetails();

    // console.log(user1, user, 10, "required auth");

    const location = useLocation();
    var Roles = user?.rolesAssigned;
    console.dir(user);
    console.debug(user);

    console.log("User Roles allowed", user, Roles, allowedRoles);
    return (
        Roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/signin" state={{ from: location }} replace />

    );
}

export default RequiredAuth;
