import React, { useState } from 'react';
import { Button, Form, Upload, message, Input } from 'antd';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import axios from 'axios';

const UplaodSheet = () => {
  const [fileList, setFilelist] = useState([]);
  const [saving, setSaving] = useState(false);

  const uploadSpecSheet = async (values) => {
    setSaving(true);

    console.log(values);
    console.log(fileList[0]);
    // const file =  fileList[0];
    const formData = new FormData();
    formData.append('files', fileList[0]);
    formData.append('launchDetails', values.launchInfo);

    // const upload_res = await axios({
    //   method: "POST",
    //   url: `${API}/upload`,
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: `Bearer ${getToken()}`,
    //   },
    //   data: formData,
    // });

    // console.log(upload_res);

    try {
      const response = await fetch(`${API}/upload-specsheet`, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data',

          Authorization: `Bearer ${getToken()}`,
        },
        body: formData,
      });
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.error) {
        message.error('Unable to Upload file');
      } else {
        message.success('Uploaded Successfully!');
      }
    } catch (error) {
      //   console.log(error);
      message.error('Error While Updating the Data!');
    } finally {
      setSaving(false);
    }
  };
  return (
    <div>
      <Form onFinish={uploadSpecSheet}>
        <Form.Item label="Launch" name={'launchInfo'}>
          <Input />
        </Form.Item>
        <Form.Item
          label="SpecsSheet"
          name={'specsheet'}
          valuePropName="fileList"
          getValueFromEvent={(event) => {
            return event?.fileList;
          }}
          rules={[
            {
              required: true,
              message:
                'please upload Specsheet make sure to use only csv or xlsx file.',
            },
            {
              validator(_, fileList) {
                return new Promise((resolve, reject) => {
                  if (fileList[0].size > 900000) {
                    reject('File Size Exceeded');
                    message.error('Error');
                  } else {
                    resolve('success');
                  }
                });
              },
            },
          ]}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file) => {
              return new Promise((resolve, reject) => {
                if (file.size > 900000) {
                  reject('File Size Exceeded');
                  message.error('Error');
                } else {
                  resolve('success');
                }
              });
            }}
            customRequest={(info) => {
              setFilelist([info.file]);
            }}
            showUploadList={false}
          >
            <Button>Upload</Button>
            {fileList[0]?.name}
          </Upload>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          {' '}
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default UplaodSheet;
