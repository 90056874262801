import React from 'react';

import { Tabs } from 'antd';
import CoreProductList from '../CoreProduct/CoreProductList';
import BookedProductList from '../BookedProductList/BookedProductList';
import BookableProductList from '../BookableProductList/BookableProductList';
import ReceivingProductList from '../ReceivingProductList/ReceivingProductList';
import ReceivingProductPendingList from '../ReceivingProductPendingList/ReceivingProductPendingList';
import ReceivingProductReceivedList from '../ReceivingProductReceivedList/ReceivingProductReceivedList';

// import PostponedProductsManagemnt from '../PostponedProductsManagemnt/PostponedProductsManagemnt';
// import PostponedProducts from '../PostponedProducts/PostponedProducts';

const { TabPane } = Tabs;

const InboundProductManagement = () => {
  return (
    <div>
      <div>Incoming Products Management</div>
      <div>
        <Tabs>
          <TabPane tab="Incoming Products" key="receivingProducts">
            <ReceivingProductList />
          </TabPane>
          <TabPane tab="Incoming Pending" key="receivingProductPending">
            <ReceivingProductPendingList />
          </TabPane>
          <TabPane tab="Received Products" key="receivingProductReceived">
            <ReceivingProductReceivedList />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default InboundProductManagement;
