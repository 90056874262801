import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';

const AllProduct = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]); // records shown in the page
  const [designform] = Form.useForm(); // for the form
  const [showForm, setShowForm] = useState(false); // state for the form
  const [current, setCurrent] = useState(1); // current page
  const [pageSize, setPageSize] = useState(10); // page size : no of record to be shown
  const [pageCount, setPageCount] = useState(0); //no of page available
  const [total, setTotal] = useState(0); // total records
  const [searchInput, setSearchInput] = useState(''); // input value for the search
  const [optionList, setOptionList] = useState([]); // opton to be shown for drop down
  const dateFormat = 'YYYY-MM-DD';

  // search table
  const handleSearch = async (event) => {
    event.preventDefault();
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchDesignMaster(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(
          `${API}/design-masters?filters[$or][0][completionStage][$eqi]=completed&filters[styleNo][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&sort[0]=createdAt:desc`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const designMasterData = await response.json();

        data1 = designMasterData.data.map((entry) => {
          let launchObj = [];
          if (entry.attributes.launch_masters.data.length > 0) {
            launchObj = entry.attributes.launch_masters.data.map((launch) => {
              let launchRefId = launch.id;
              let launchName = launch.attributes.launchName;
              let launchDate = launch.attributes.launchDate;
              return {
                launch_name: launchName,
                launchDate: launchDate,
                launchRefId: launchRefId,
              };
            });
          }

          return {
            id: entry.id,
            styleNo: entry.attributes.styleNo,
            SKU: entry.attributes.SKU,
            Div: entry.attributes.Div,
            date: entry.attributes.date,
            season: entry.attributes.season,
            productCategory: entry.attributes.productCategory,
            description: entry.attributes.description,
            block: entry.attributes.block,
            fit: entry.attributes.fit,
            washing: entry.attributes.washing,
            PACKING: entry.attributes.PACKING,
            styleSample: entry.attributes.styleSample,
            launchDetails: launchObj,
          };
        });
        console.log('original Data:', designMasterData);
        console.log('Data:', data1);
        setData(data1);

        setTotal(designMasterData.meta.pagination.total);
        setPageSize(designMasterData.meta.pagination.pageSize);
        setPageCount(designMasterData.meta.pagination.pageCount);
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // search table

  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);

    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/design-masters?filters[$or][0][completionStage][$eqi]=completed&pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      data2 = paginationData.data.map((entry) => {
        let launchObj = [];
        if (entry.attributes.launch_masters.data.length > 0) {
          launchObj = entry.attributes.launch_masters.data.map((launch) => {
            let launchRefId = launch.id;
            let launchName = launch.attributes.launchName;
            let launchDate = launch.attributes.launchDate;
            return {
              launch_name: launchName,
              launchDate: launchDate,
              launchRefId: launchRefId,
            };
          });
        }
        return {
          id: entry.id,
          styleNo: entry.attributes.styleNo,
          SKU: entry.attributes.SKU,
          Div: entry.attributes.Div,
          date: entry.attributes.date,
          season: entry.attributes.season,
          productCategory: entry.attributes.productCategory,
          description: entry.attributes.description,
          block: entry.attributes.block,
          fit: entry.attributes.fit,
          washing: entry.attributes.washing,
          PACKING: entry.attributes.PACKING,
          styleSample: entry.attributes.styleSample,
          launchDetails: launchObj,
        };
      });
      console.log('original Data:', paginationData);
      console.log('Data:', data2);
      setData(data2);
      // let page = paginationData.meta.pagination.page;

      setTotal(paginationData.meta.pagination.total);
      setPageSize(paginationData.meta.pagination.pageSize);
      setPageCount(paginationData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  let data1 = [];

  const fetchDesignMaster = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/design-masters?filters[$or][0][completionStage][$eqi]=completed&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&populate[1]=silhouettes&populate[2]=special_attributes&populate[3]=trends&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const designMasterData = await response.json();

      data1 = designMasterData.data.map((entry) => {
        let launchObj = [];
        if (entry.attributes.launch_masters.data.length > 0) {
          launchObj = entry.attributes.launch_masters.data.map((launch) => {
            let launchRefId = launch.id;
            let launchName = launch.attributes.launchName;
            let launchDate = launch.attributes.launchDate;
            return {
              launch_name: launchName,
              launchDate: launchDate,
              launchRefId: launchRefId,
            };
          });
        }

        let silhouetteObj = [];
        if (entry.attributes.silhouettes.data.length > 0) {
          silhouetteObj = entry.attributes.silhouettes.data.map(
            (silhouette) => {
              let silhouetteRefId = silhouette.id;
              let silhouetteName = silhouette.attributes.silhouette_name;

              return {
                silhouetteName: silhouetteName,
                silhouetteRefId: silhouetteRefId,
              };
            }
          );
        }

        let specialAttributeObj = [];
        if (entry.attributes.special_attributes.data.length > 0) {
          specialAttributeObj = entry.attributes.special_attributes.data.map(
            (specialAttribute) => {
              let specialAttributeRefId = specialAttribute.id;
              let specialAttributeName =
                specialAttribute.attributes.specialAttribute_name;

              return {
                specialAttributeName: specialAttributeName,
                specialAttributeRefId: specialAttributeRefId,
              };
            }
          );
        }

        let trendObj = [];
        if (entry.attributes.trends.data.length > 0) {
          trendObj = entry.attributes.trends.data.map((trend) => {
            let trendRefId = trend.id;
            let trendName = trend.attributes.trend_name;

            return { trendName: trendName, trendRefId: trendRefId };
          });
        }

        return {
          id: entry.id,
          styleNo: entry.attributes.styleNo,
          SKU: entry.attributes.SKU,
          Div: entry.attributes.Div,
          date: entry.attributes.date,
          season: entry.attributes.season,
          productCategory: entry.attributes.productCategory,
          description: entry.attributes.description,
          block: entry.attributes.block,

          // ---------------------
          sub_category: entry.attributes.sub_category,
          new_Repeat: entry.attributes.new_Repeat,
          collection_tr: entry.attributes.collection_tr,
          collection: entry.attributes.collection,
          factory: entry.attributes.factory,
          colour: entry.attributes.colour,
          launchSeason: entry.attributes.launchSeason,
          launchMonth: entry.attributes.launchMonth,
          designSeason: entry.attributes.designSeason,
          lifeOccasion: entry.attributes.lifeOccasion,
          colourFamily: entry.attributes.colourFamily,
          Sleeve: entry.attributes.Sleeve,
          designCategory: entry.attributes.designCategory,
          attributionCategory: entry.attributes.attributionCategory,
          pattern: entry.attributes.pattern,
          knitWoven: entry.attributes.knitWoven,
          freshness: entry.attributes.freshness,
          // ---------------

          fit: entry.attributes.fit,
          washing: entry.attributes.washing,
          PACKING: entry.attributes.PACKING,
          styleSample: entry.attributes.styleSample,
          launchDetails: launchObj,
          silhouetteDetails: silhouetteObj,
          specialAttributeDetails: specialAttributeObj,
          trendDetails: trendObj,
        };
      });
      console.log('original Data:', designMasterData);
      console.log('Data:', data1);
      setData(data1);

      setTotal(designMasterData.meta.pagination.total);
      setPageSize(designMasterData.meta.pagination.pageSize);
      setPageCount(designMasterData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOptions = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setOptionList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchDesignMaster(authToken);
      fetchOptions(authToken);
    }
  }, []);

  if (isLoading) {
    return <Spin size="large" />;
  }
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  const toDetailedRecordPage = (state) => {
    navigate('/DesignProductDetails', { state });
  };

  const columns = [
    {
      key: 'styleNo',
      title: 'Style No',
      render: (record) => {
        console.log(record);
        return (
          <div>
            {' '}
            <a
              onClick={() => {
                toDetailedRecordPage(record);
              }}
            >
              {record.SKU}
            </a>
          </div>
        );
      },
    },
    {
      key: 'SKU',
      title: 'SKU',
      dataIndex: 'SKU',
    },
    {
      key: 'Div',
      title: 'Division',
      dataIndex: 'Div',
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
    },
    {
      key: 'season',
      title: 'Season',
      dataIndex: 'season',
    },
    {
      key: 'productCategory',
      title: 'Product Category',
      dataIndex: 'productCategory',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
    {
      key: 'block',
      title: 'Block',
      dataIndex: 'block',
    },
    {
      key: 'fit',
      title: 'Fit',
      dataIndex: 'fit',
    },
    {
      key: 'washing',
      title: 'Washing',
      dataIndex: 'washing',
    },
    {
      key: 'PACKING',
      title: 'Packing',
      dataIndex: 'PACKING',
    },
    {
      key: 'styleSample',
      title: 'Style Sample',
      dataIndex: 'styleSample',
    },

    {
      key: 'action',
      title: 'Actions',
      render: (record) => {
        return (
          <div>
            <div className="ActionColumn">
              <Button
                type="primary"
                style={{ color: 'black', backgroundColor: 'lightgreen' }}
                onClick={() => confirmEntry(record)}
              >
                confirm
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const confirmEntry = (record) => {
    Modal.confirm({
      title: 'You are sure you are Marking this Entry Complete',
      onOk: () => {
        confirmMasterEntry(record);

        // setData((pre) => {
        //   return pre.filter((person) => person.id != record.id);
        // });
      },
    });
  };

  const confirmMasterEntry = async (record) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/design-masters/${record.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          data: { completionStage: 'confirmed', completionStatus: true },
        }),
      });
      const responseData = await response.json();
      console.log(responseData);

      fetchDesignMaster(getToken());
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div>All Design Completed Products</div>

      <div className="FilterSection">
        {/** search new */}

        <form onSubmit={handleSearch} className="searchBarr">
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit"> Search</button>
        </form>

        <br />

        {/** search new */}

        {/** search old
            <Input type="text"
          size="large"
          name="searchInput"
          value={searchInput}
          onChange={async (event) => setSearchInput(event.target.value)}
          // onKeyPress={(event) => handleSearchBarKeyPress(event)}
          label="Search"
        />

        <Button onClick={handleSearch} >
          search
        </Button>    
    */}
      </div>

      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
        />
      </div>
    </div>
  );
};

export default AllProduct;
