import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';

const EcomAllProduct = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [designform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [optionList, setOptionList] = useState([]);
  const dateFormat = 'YYYY-MM-DD';

  // search table
  const handleSearch = async (event) => {
    event.preventDefault();
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchDesignMaster(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(
          `${API}/products?filters[completionStatus]=true&filters[SKU][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&populate[1]=product_collections&sort[0]=createdAt:desc`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const designMasterData = await response.json();
        console.log('original Data planning:', designMasterData);
        // return;

        data1 = designMasterData.data.map((entry) => {
          let launchObj = [];
          if (entry.attributes.launch_masters.data.length > 0) {
            launchObj = entry.attributes.launch_masters.data.map((launch) => {
              let launchRefId = launch.id;
              let launchName = launch.attributes.launchName;
              let launchDate = launch.attributes.launchDate;
              return {
                launch_name: launchName,
                launchDate: launchDate,
                launchRefId: launchRefId,
              };
            });
          }

          let productCollectionObj = [];
          if (entry.attributes.product_collections.data.length > 0) {
            productCollectionObj =
              entry.attributes.product_collections.data.map(
                (productCollection) => {
                  let productCollectionRefId = productCollection.id;
                  let productCollectionName = productCollection.collectionName;
                  let productCollectionOnlineName =
                    productCollection.onlineCollectionName;
                  return {
                    productCollectionRefId: productCollectionRefId,
                    productCollectionName: productCollectionName,
                    productCollectionOnlineName: productCollectionOnlineName,
                  };
                }
              );
          }

          return {
            id: entry.id,
            productName: entry.attributes.productName,
            websiteProductName: entry.attributes.websiteProductName,
            Color: entry.attributes.Color,
            SKU: entry.attributes.SKU,
            atfContent: entry.attributes.atfContent,
            attributionCollection: entry.attributes.attributionCollection,
            attributionColor: entry.attributes.attributionColor,
            btfReferenceLinks: entry.attributes.btfReferenceLinks,
            createdAt: entry.attributes.createdAt,
            crossSells: entry.attributes.crossSells,
            design: entry.attributes.design,
            fabricDescription: entry.attributes.fabricDescription,
            feature1: entry.attributes.feature1,
            feature2: entry.attributes.feature2,
            feature3: entry.attributes.feature3,
            fit: entry.attributes.fit,
            lifeOccasion: entry.attributes.lifeOccasion,
            modelInfo: entry.attributes.modelInfo,
            pattern: entry.attributes.pattern,
            price: entry.attributes.price,
            publishedAt: entry.attributes.publishedAt,
            season: entry.attributes.season,
            seoDescription: entry.attributes.seoDescription,
            silhouette: entry.attributes.silhouette,
            specialAttribute: entry.attributes.specialAttribute,
            suggestedStyle: entry.attributes.suggestedStyle,
            trend: entry.attributes.trend,
            updatedAt: entry.attributes.updatedAt,
            launchName: entry.attributes.launchName,
            launchDetails: launchObj,
            productCollectionDetails: productCollectionObj,
          };
        });
        console.log('original Data:', designMasterData);
        console.log('Data:', data1);
        setData(data1);

        setTotal(designMasterData.meta.pagination.total);
        setPageSize(designMasterData.meta.pagination.pageSize);
        setPageCount(designMasterData.meta.pagination.pageCount);
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Table Data
  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);

    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/products?filters[completionStatus]=true&pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&populate[1]=product_collections&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      data2 = paginationData.data.map((entry) => {
        let launchObj = [];
        if (entry.attributes.launch_masters.data.length > 0) {
          launchObj = entry.attributes.launch_masters.data.map((launch) => {
            let launchRefId = launch.id;
            let launchName = launch.attributes.launchName;
            let launchDate = launch.attributes.launchDate;
            return {
              launch_name: launchName,
              launchDate: launchDate,
              launchRefId: launchRefId,
            };
          });
        }

        let productCollectionObj = [];
        if (entry.attributes.product_collections.data.length > 0) {
          productCollectionObj = entry.attributes.product_collections.data.map(
            (productCollection) => {
              let productCollectionRefId = productCollection.id;
              let productCollectionName = productCollection.collectionName;
              let productCollectionOnlineName =
                productCollection.onlineCollectionName;
              return {
                productCollectionRefId: productCollectionRefId,
                productCollectionName: productCollectionName,
                productCollectionOnlineName: productCollectionOnlineName,
              };
            }
          );
        }

        return {
          id: entry.id,
          productName: entry.attributes.productName,
          websiteProductName: entry.attributes.websiteProductName,
          Color: entry.attributes.Color,
          SKU: entry.attributes.SKU,
          atfContent: entry.attributes.atfContent,
          attributionCollection: entry.attributes.attributionCollection,
          attributionColor: entry.attributes.attributionColor,
          btfReferenceLinks: entry.attributes.btfReferenceLinks,
          createdAt: entry.attributes.createdAt,
          crossSells: entry.attributes.crossSells,
          design: entry.attributes.design,
          fabricDescription: entry.attributes.fabricDescription,
          feature1: entry.attributes.feature1,
          feature2: entry.attributes.feature2,
          feature3: entry.attributes.feature3,
          fit: entry.attributes.fit,
          lifeOccasion: entry.attributes.lifeOccasion,
          modelInfo: entry.attributes.modelInfo,
          pattern: entry.attributes.pattern,
          price: entry.attributes.price,
          publishedAt: entry.attributes.publishedAt,
          season: entry.attributes.season,
          seoDescription: entry.attributes.seoDescription,
          silhouette: entry.attributes.silhouette,
          specialAttribute: entry.attributes.specialAttribute,
          suggestedStyle: entry.attributes.suggestedStyle,
          trend: entry.attributes.trend,
          updatedAt: entry.attributes.updatedAt,
          launchName: entry.attributes.launchName,
          launchDetails: launchObj,
          productCollectionDetails: productCollectionObj,
        };
      });
      console.log('original Data:', paginationData);
      console.log('Data:', data2);
      setData(data2);
      // let page = paginationData.meta.pagination.page;

      setTotal(paginationData.meta.pagination.total);
      setPageSize(paginationData.meta.pagination.pageSize);
      setPageCount(paginationData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  let data1 = [];

  const fetchDesignMaster = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/products?filters[completionStatus]=true&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&populate[1]=product_collections&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const designMasterData = await response.json();
      console.log('original Data Ecomm:', designMasterData);

      data1 = designMasterData.data.map((entry) => {
        let launchObj = [];
        if (entry.attributes.launch_masters.data.length > 0) {
          launchObj = entry.attributes.launch_masters.data.map((launch) => {
            let launchRefId = launch.id;
            let launchName = launch.attributes.launchName;
            let launchDate = launch.attributes.launchDate;
            return {
              launch_name: launchName,
              launchDate: launchDate,
              launchRefId: launchRefId,
            };
          });
        }

        let productCollectionObj = [];
        if (entry.attributes.product_collections.data.length > 0) {
          productCollectionObj = entry.attributes.product_collections.data.map(
            (productCollection) => {
              let productCollectionRefId = productCollection.id;
              let productCollectionName = productCollection.collectionName;
              let productCollectionOnlineName =
                productCollection.onlineCollectionName;
              return {
                productCollectionRefId: productCollectionRefId,
                productCollectionName: productCollectionName,
                productCollectionOnlineName: productCollectionOnlineName,
              };
            }
          );
        }

        return {
          id: entry.id,
          productName: entry.attributes.productName,
          websiteProductName: entry.attributes.websiteProductName,
          Color: entry.attributes.Color,
          SKU: entry.attributes.SKU,
          atfContent: entry.attributes.atfContent,
          attributionCollection: entry.attributes.attributionCollection,
          attributionColor: entry.attributes.attributionColor,
          btfReferenceLinks: entry.attributes.btfReferenceLinks,
          createdAt: entry.attributes.createdAt,
          crossSells: entry.attributes.crossSells,
          design: entry.attributes.design,
          fabricDescription: entry.attributes.fabricDescription,
          feature1: entry.attributes.feature1,
          feature2: entry.attributes.feature2,
          feature3: entry.attributes.feature3,
          fit: entry.attributes.fit,
          lifeOccasion: entry.attributes.lifeOccasion,
          modelInfo: entry.attributes.modelInfo,
          pattern: entry.attributes.pattern,
          price: entry.attributes.price,
          publishedAt: entry.attributes.publishedAt,
          season: entry.attributes.season,
          seoDescription: entry.attributes.seoDescription,
          silhouette: entry.attributes.silhouette,
          specialAttribute: entry.attributes.specialAttribute,
          suggestedStyle: entry.attributes.suggestedStyle,
          trend: entry.attributes.trend,
          updatedAt: entry.attributes.updatedAt,
          launchName: entry.attributes.launchName,
          launchDetails: launchObj,
          productCollectionDetails: productCollectionObj,
        };
      });
      console.log('original Data:', designMasterData);
      console.log('Data:', data1);
      setData(data1);

      setTotal(designMasterData.meta.pagination.total);
      setPageSize(designMasterData.meta.pagination.pageSize);
      setPageCount(designMasterData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };
  // Table Data

  // Initail Data
  useEffect(() => {
    if (authToken) {
      fetchDesignMaster(authToken);
    }
  }, []);

  //   auxiliary functions for handeleing Models

  if (isLoading) {
    return <Spin size="large" />;
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  const toDetailedRecordPage = (state) => {
    navigate('/fullProductView', { state });
  };

  //   Table Column

  const columns = [
    {
      key: 'SKU',
      title: 'SKU',
      dataIndex: 'SKU',
    },
    {
      key: 'productName',
      title: 'Product Name',
      dataIndex: 'productName',
    },
    {
      key: 'websiteProductName',
      title: 'Website Product Name',
      dataIndex: 'websiteProductName',
    },
    {
      key: 'Color',
      title: 'Color',
      dataIndex: 'Color',
    },
    {
      key: 'attributionColor',
      title: 'Attribution Color',
      dataIndex: 'attributionColor',
    },
    {
      key: 'attributionCollection',
      title: 'Attribtion Collection',
      dataIndex: 'attributionCollection',
    },
    {
      key: 'fit',
      title: 'Fit',
      dataIndex: 'fit',
    },
    {
      key: 'lifeOccasion',
      title: 'Life Occasion',
      dataIndex: 'lifeOccasion',
    },
    {
      key: 'pattern',
      title: 'Pattern',
      dataIndex: 'pattern',
    },
    {
      key: 'price',
      title: 'Price',
      dataIndex: 'price',
    },
  ];

  //   auxiliary functions for record Action

  const confirmEntry = (record) => {
    Modal.confirm({
      title: 'You are sure you are Marking this Entry Complete and Confirmed',
      onOk: () => {
        confirmMasterEntry(record);

        // setData((pre) => {
        //   return pre.filter((person) => person.id != record.id);
        // });
      },
    });
  };

  const confirmMasterEntry = async (record) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/products/${record.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          data: { completionStage: 'confirmed', completionStatus: true },
        }),
      });
      const responseData = await response.json();
      console.log(responseData);

      fetchDesignMaster(getToken());
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div>All Completed And Confirmed Products</div>

      <div className="FilterSection">
        {/** search new */}

        <form
          onSubmit={(event) => {
            handleSearch(event);
          }}
          className="searchBarr"
        >
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit"> Search</button>
        </form>

        <br />

        {/** search new */}

        {/** search old
            <Input type="text"
          size="large"
          name="searchInput"
          value={searchInput}
          onChange={async (event) => setSearchInput(event.target.value)}
          // onKeyPress={(event) => handleSearchBarKeyPress(event)}
          label="Search"
        />

        <Button onClick={handleSearch} >
          search
        </Button>    
    */}
      </div>

      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
        />
      </div>
    </div>
  );
};

export default EcomAllProduct;
