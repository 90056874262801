import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';

const ProductSpecification = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [designform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [optionList, setOptionList] = useState([]);
  const [silhouetteList, setSilhouetteList] = useState([]);
  const [specialAttributeList, setSpecialAttributeList] = useState([]);
  const [trendsList, setTrendsList] = useState([]);

  const dateFormat = 'YYYY-MM-DD';

  const onSearch = (value) => {
    console.log(value);
    setSearchInput(value);
    globalSearch(searchInput);
  };

  // search table
  const handleSearch = async (event) => {
    event.preventDefault();
    console.log(event);
    globalSearch(searchInput);
  };

  const handleSearchBarKeyPress = async (event) => {
    console.log(event.key);

    if (event.key === 'Enter') {
      globalSearch(searchInput);
    } else {
      // setSearchInput(searchInput.concat(event.key))
    }
  };

  const globalSearch = async (searchValue) => {
    setIsLoading(true);
    setCurrent(1);

    if (searchValue === '') {
      fetchDesignMaster(getToken());
    } else {
      console.log('gsSearch');

      let data1 = [];
      try {
        const response = await fetch(
          `${API}/design-masters?filters[$or][0][completionStage][$eqi]=initialized&filters[$or][1][completionStage][$eqi]=wip&filters[styleNo][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&populate[1]=silhouettes&populate[2]=special_attributes&populate[3]=trends&sort[0]=createdAt:desc`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const designMasterData = await response.json();

        data1 = designMasterData.data.map((entry) => {
          let launchObj = [];
          if (entry.attributes.launch_masters.data.length > 0) {
            launchObj = entry.attributes.launch_masters.data.map((launch) => {
              let launchRefId = launch.id;
              let launchName = launch.attributes.launchName;
              let launchDate = launch.attributes.launchDate;
              return {
                launch_name: launchName,
                launchDate: launchDate,
                launchRefId: launchRefId,
              };
            });
          }

          let silhouetteObj = [];
          if (entry.attributes.silhouettes.data.length > 0) {
            silhouetteObj = entry.attributes.silhouettes.data.map(
              (silhouette) => {
                let silhouetteRefId = silhouette.id;
                let silhouetteName = silhouette.attributes.silhouette_name;

                return {
                  silhouetteName: silhouetteName,
                  silhouetteRefId: silhouetteRefId,
                };
              }
            );
          }

          let specialAttributeObj = [];
          if (entry.attributes.special_attributes.data.length > 0) {
            specialAttributeObj = entry.attributes.special_attributes.data.map(
              (specialAttribute) => {
                let specialAttributeRefId = specialAttribute.id;
                let specialAttributeName =
                  specialAttribute.attributes.specialAttribute_name;

                return {
                  specialAttributeName: specialAttributeName,
                  specialAttributeRefId: specialAttributeRefId,
                };
              }
            );
          }

          let trendObj = [];
          if (entry.attributes.trends.data.length > 0) {
            trendObj = entry.attributes.trends.data.map((trend) => {
              let trendRefId = trend.id;
              let trendName = trend.attributes.trend_name;

              return { trendName: trendName, trendRefId: trendRefId };
            });
          }

          return {
            id: entry.id,
            styleNo: entry.attributes.styleNo,
            SKU: entry.attributes.SKU,
            Div: entry.attributes.Div,
            date: entry.attributes.date,
            season: entry.attributes.season,
            productCategory: entry.attributes.productCategory,
            description: entry.attributes.description,
            block: entry.attributes.block,

            // ---------------
            sub_category: entry.attributes.sub_category,
            new_Repeat: entry.attributes.new_Repeat,
            collection_tr: entry.attributes.collection_tr,
            collection: entry.attributes.collection,
            factory: entry.attributes.factory,
            colour: entry.attributes.colour,
            launchSeason: entry.attributes.launchSeason,
            launchMonth: entry.attributes.launchMonth,
            designSeason: entry.attributes.designSeason,
            lifeOccasion: entry.attributes.lifeOccasion,
            colourFamily: entry.attributes.colourFamily,
            Sleeve: entry.attributes.Sleeve,
            designCategory: entry.attributes.designCategory,
            attributionCategory: entry.attributes.attributionCategory,
            pattern: entry.attributes.pattern,
            knitWoven: entry.attributes.knitWoven,
            freshness: entry.attributes.freshness,
            // ---------------

            fit: entry.attributes.fit,
            washing: entry.attributes.washing,
            PACKING: entry.attributes.PACKING,
            styleSample: entry.attributes.styleSample,
            launchDetails: launchObj,
            silhouetteDetails: silhouetteObj,
            specialAttributeDetails: specialAttributeObj,
            trendDetails: trendObj,
          };
        });
        console.log('original Data:', designMasterData);
        console.log('Data:', data1);
        setData(data1);

        setTotal(designMasterData.meta.pagination.total);
        setPageSize(designMasterData.meta.pagination.pageSize);
        setPageCount(designMasterData.meta.pagination.pageCount);
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // search table

  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);
    console.log('pgchange');
    let searchValue = '';
    if (searchInput != '') {
      searchValue = searchInput;
    }
    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/design-masters?filters[$or][0][completionStage][$eqi]=initialized&filters[$or][1][completionStage][$eqi]=wip&filters[styleNo][$containsi]=${searchValue}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&populate[1]=silhouettes&populate[2]=special_attributes&populate[3]=trends&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      data2 = paginationData.data.map((entry) => {
        let launchObj = [];
        if (entry.attributes.launch_masters.data.length > 0) {
          launchObj = entry.attributes.launch_masters.data.map((launch) => {
            let launchRefId = launch.id;
            let launchName = launch.attributes.launchName;
            let launchDate = launch.attributes.launchDate;
            return {
              launch_name: launchName,
              launchDate: launchDate,
              launchRefId: launchRefId,
            };
          });
        }

        let silhouetteObj = [];
        if (entry.attributes.silhouettes.data.length > 0) {
          silhouetteObj = entry.attributes.silhouettes.data.map(
            (silhouette) => {
              let silhouetteRefId = silhouette.id;
              let silhouetteName = silhouette.attributes.silhouette_name;

              return {
                silhouetteName: silhouetteName,
                silhouetteRefId: silhouetteRefId,
              };
            }
          );
        }

        let specialAttributeObj = [];
        if (entry.attributes.special_attributes.data.length > 0) {
          specialAttributeObj = entry.attributes.special_attributes.data.map(
            (specialAttribute) => {
              let specialAttributeRefId = specialAttribute.id;
              let specialAttributeName =
                specialAttribute.attributes.specialAttribute_name;

              return {
                specialAttributeName: specialAttributeName,
                specialAttributeRefId: specialAttributeRefId,
              };
            }
          );
        }

        let trendObj = [];
        if (entry.attributes.trends.data.length > 0) {
          trendObj = entry.attributes.trends.data.map((trend) => {
            let trendRefId = trend.id;
            let trendName = trend.attributes.trend_name;

            return { trendName: trendName, trendRefId: trendRefId };
          });
        }

        return {
          id: entry.id,
          styleNo: entry.attributes.styleNo,
          SKU: entry.attributes.SKU,
          Div: entry.attributes.Div,
          date: entry.attributes.date,
          season: entry.attributes.season,
          productCategory: entry.attributes.productCategory,
          description: entry.attributes.description,
          block: entry.attributes.block,

          // ---------------
          sub_category: entry.attributes.sub_category,
          new_Repeat: entry.attributes.new_Repeat,
          collection_tr: entry.attributes.collection_tr,
          collection: entry.attributes.collection,
          factory: entry.attributes.factory,
          colour: entry.attributes.colour,
          launchSeason: entry.attributes.launchSeason,
          launchMonth: entry.attributes.launchMonth,
          designSeason: entry.attributes.designSeason,
          lifeOccasion: entry.attributes.lifeOccasion,
          colourFamily: entry.attributes.colourFamily,
          Sleeve: entry.attributes.Sleeve,
          designCategory: entry.attributes.designCategory,
          attributionCategory: entry.attributes.attributionCategory,
          pattern: entry.attributes.pattern,
          knitWoven: entry.attributes.knitWoven,
          freshness: entry.attributes.freshness,
          // ---------------

          fit: entry.attributes.fit,
          washing: entry.attributes.washing,
          PACKING: entry.attributes.PACKING,
          styleSample: entry.attributes.styleSample,
          launchDetails: launchObj,
          silhouetteDetails: silhouetteObj,
          specialAttributeDetails: specialAttributeObj,
          trendDetails: trendObj,
        };
      });
      console.log('original Data:', paginationData);
      console.log('Data:', data2);
      setData(data2);
      // let page = paginationData.meta.pagination.page;

      setTotal(paginationData.meta.pagination.total);
      setPageSize(paginationData.meta.pagination.pageSize);
      setPageCount(paginationData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  let data1 = [];

  const fetchDesignMaster = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/design-masters?filters[$or][0][completionStage][$eqi]=initialized&filters[$or][1][completionStage][$eqi]=wip&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=launch_masters&populate[1]=silhouettes&populate[2]=special_attributes&populate[3]=trends&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const designMasterData = await response.json();

      console.log(designMasterData);
      data1 = designMasterData.data.map((entry) => {
        let launchObj = [];
        if (entry.attributes.launch_masters.data.length > 0) {
          launchObj = entry.attributes.launch_masters.data.map((launch) => {
            let launchRefId = launch.id;
            let launchName = launch.attributes.launchName;
            let launchDate = launch.attributes.launchDate;
            return {
              launch_name: launchName,
              launchDate: launchDate,
              launchRefId: launchRefId,
            };
          });
        }

        let silhouetteObj = [];
        if (entry.attributes.silhouettes.data.length > 0) {
          silhouetteObj = entry.attributes.silhouettes.data.map(
            (silhouette) => {
              let silhouetteRefId = silhouette.id;
              let silhouetteName = silhouette.attributes.silhouette_name;

              return {
                silhouetteName: silhouetteName,
                silhouetteRefId: silhouetteRefId,
              };
            }
          );
        }

        let specialAttributeObj = [];
        if (entry.attributes.special_attributes.data.length > 0) {
          specialAttributeObj = entry.attributes.special_attributes.data.map(
            (specialAttribute) => {
              let specialAttributeRefId = specialAttribute.id;
              let specialAttributeName =
                specialAttribute.attributes.specialAttribute_name;

              return {
                specialAttributeName: specialAttributeName,
                specialAttributeRefId: specialAttributeRefId,
              };
            }
          );
        }

        let trendObj = [];
        if (entry.attributes.trends.data.length > 0) {
          trendObj = entry.attributes.trends.data.map((trend) => {
            let trendRefId = trend.id;
            let trendName = trend.attributes.trend_name;

            return { trendName: trendName, trendRefId: trendRefId };
          });
        }

        return {
          id: entry.id,
          styleNo: entry.attributes.styleNo,
          SKU: entry.attributes.SKU,
          Div: entry.attributes.Div,
          date: entry.attributes.date,
          season: entry.attributes.season,
          productCategory: entry.attributes.productCategory,
          description: entry.attributes.description,
          block: entry.attributes.block,
          // ---------------
          sub_category: entry.attributes.sub_category,
          new_Repeat: entry.attributes.new_Repeat,
          collection_tr: entry.attributes.collection_tr,
          collection: entry.attributes.collection,
          factory: entry.attributes.factory,
          colour: entry.attributes.colour,
          launchSeason: entry.attributes.launchSeason,
          launchMonth: entry.attributes.launchMonth,
          designSeason: entry.attributes.designSeason,
          lifeOccasion: entry.attributes.lifeOccasion,
          colourFamily: entry.attributes.colourFamily,
          Sleeve: entry.attributes.Sleeve,
          designCategory: entry.attributes.designCategory,
          attributionCategory: entry.attributes.attributionCategory,
          pattern: entry.attributes.pattern,
          knitWoven: entry.attributes.knitWoven,
          freshness: entry.attributes.freshness,
          // ---------------
          fit: entry.attributes.fit,
          washing: entry.attributes.washing,
          PACKING: entry.attributes.PACKING,
          styleSample: entry.attributes.styleSample,

          launchDetails: launchObj,
          silhouetteDetails: silhouetteObj,
          specialAttributeDetails: specialAttributeObj,
          trendDetails: trendObj,
        };
      });
      console.log('original Data:', designMasterData);
      console.log('Data:', data1);
      setData(data1);

      setTotal(designMasterData.meta.pagination.total);
      setPageSize(designMasterData.meta.pagination.pageSize);
      setPageCount(designMasterData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOptions = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setOptionList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSilhouetteList = async (token) => {
    let silhouetteListData = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/silhouettes`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      silhouetteListData = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          silhouetteName: entry.attributes.silhouette_name,
        };
      });

      setSilhouetteList(silhouetteListData);
      console.log('original Silhouette data:', optionsData);
      console.log('modified Silhouette Data:', silhouetteListData);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSpecialAttributeList = async (token) => {
    let specialAttributeListData = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/special-attributes`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      specialAttributeListData = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          specialAttributeName: entry.attributes.specialAttribute_name,
        };
      });

      setSpecialAttributeList(specialAttributeListData);
      console.log('original  Special attribute data:', optionsData);
      console.log('modified Special attribute Data:', specialAttributeListData);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTrendsList = async (token) => {
    let trendsListData = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/trends`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      trendsListData = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          trendsName: entry.attributes.trend_name,
        };
      });

      setTrendsList(trendsListData);
      console.log('original  Trends data:', optionsData);
      console.log('modified Trends Data:', trendsListData);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchDesignMaster(authToken);
      fetchOptions(authToken);
      fetchSilhouetteList(authToken);
      fetchSpecialAttributeList(authToken);
      fetchTrendsList(authToken);
    }
  }, []);

  useEffect(() => {
    console.log(editData);
    //setting form data on change of edit data and disginForm reference
    designform.setFieldsValue(editData);
  }, [editData, designform]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    designform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const addNewProductSpecification = async (data) => {
    setSaving(true);

    const values = {
      ...data,
      date: data['Date'].format('YYYY-MM-DD'),
    };

    delete values['Date'];

    data = { data: values };

    console.log('data:', data);
    console.log('data edit:', data.data.editId);
    // return;

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/design-masters/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Product Specification.');
        } else {
          message.success('Data saved successfully!');
          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/design-masters/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification');
        } else {
          message.success('Data saved successfully!');
          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const columns = [
    {
      key: 'styleNo',
      title: 'Style No',
      dataIndex: 'styleNo',
    },
    {
      key: 'SKU',
      title: 'SKU',
      dataIndex: 'SKU',
    },
    {
      key: 'Div',
      title: 'Division',
      dataIndex: 'Div',
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
    },
    {
      key: 'season',
      title: 'Season',
      dataIndex: 'season',
    },
    {
      key: 'productCategory',
      title: 'Product Category',
      dataIndex: 'productCategory',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
    {
      key: 'block',
      title: 'Block',
      dataIndex: 'block',
    },
    {
      key: 'fit',
      title: 'Fit',
      dataIndex: 'fit',
    },
    {
      key: 'washing',
      title: 'Washing',
      dataIndex: 'washing',
    },

    {
      key: 'PACKING',
      title: 'Packing',
      dataIndex: 'PACKING',
    },
    {
      key: 'styleSample',
      title: 'Style Sample',
      dataIndex: 'styleSample',
    },
    {
      key: 'new_Repeat',
      title: 'New/Repeat',
      dataIndex: 'new_Repeat',
    },
    {
      key: 'collection_tr',
      title: 'collection TR',
      dataIndex: 'collection_tr',
      className: 'hiddenColumn',
    },
    {
      key: 'collection',
      title: 'Collection',
      dataIndex: 'collection',
      className: 'hiddenColumn',
    },
    {
      key: 'sub_category',
      title: 'Sub Category',
      dataIndex: 'sub_category',
      className: 'hiddenColumn',
    },
    {
      key: 'factory',
      title: 'Factory',
      dataIndex: 'factory',
      className: 'hiddenColumn',
    },
    {
      key: 'colour',
      title: 'Colour',
      dataIndex: 'colour',
      className: 'hiddenColumn',
    },
    {
      key: 'launchSeason',
      title: 'Launch Season',
      dataIndex: 'launchSeason',
      className: 'hiddenColumn',
    },
    {
      key: 'Fit',
      title: 'fit',
      dataIndex: 'Fit',
      className: 'hiddenColumn',
    },
    {
      key: 'launchMonth',
      title: 'Launch Month',
      dataIndex: 'launchMonth',
      className: 'hiddenColumn',
    },
    {
      key: 'designSeason',
      title: 'Design Season',
      dataIndex: 'designSeason',
      className: 'hiddenColumn',
    },

    {
      key: 'action',
      title: 'Actions',
      render: (record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <DeleteOutlined
                style={{ color: 'red' }}
                onClick={() => Delete(record)}
              />
              <CheckCircleOutlined
                style={{ color: 'black' }}
                onClick={() => confirmEntry(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteDesignMasterEntry = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/design-masters/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      fetchDesignMaster(getToken());
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteDesignMasterEntry(record.id);

        // setData((pre) => {
        //   return pre.filter((person) => person.id != record.id);
        // });
      },
    });
  };

  const confirmEntry = (record) => {
    Modal.confirm({
      title: 'You are sure you are Marking this Entry Complete',
      onOk: () => {
        confirmMasterEntry(record);

        // setData((pre) => {
        //   return pre.filter((person) => person.id != record.id);
        // });
      },
    });
  };

  const confirmMasterEntry = async (record) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/design-masters/${record.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ data: { completionStage: 'completed' } }),
      });
      const responseData = await response.json();
      console.log(responseData);

      fetchDesignMaster(getToken());
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Edit = (record) => {
    setEditData({
      styleNo: record.styleNo,
      SKU: record.SKU,
      Div: record.Div,
      Date: moment(record.date, 'YYYY-MM-DD'),
      season: record.season,
      productCategory: record.productCategory,
      description: record.description,
      block: record.block,
      fit: record.fit,
      washing: record.washing,
      PACKING: record.PACKING,
      styleSample: record.styleSample,
      lifeOccasion: record.lifeOccasion,
      sub_category: record.sub_category,
      new_Repeat: record.new_Repeat,
      collection_tr: record.collection_tr,
      collection: record.collection,
      factory: record.factory,
      colour: record.colour,
      launchSeason: record.launchSeason,
      launchMonth: record.launchMonth,
      designSeason: record.designSeason,
      colourFamily: record.colourFamily,
      Sleeve: record.Sleeve,
      designCategory: record.designCategory,
      attributionCategory: record.attributionCategory,
      pattern: record.pattern,
      knitWoven: record.knitWoven,
      freshness: record.freshness,
      launch_masters: record.launchDetails.map((launch) => launch.launchRefId),
      silhouettes: record.silhouetteDetails.map(
        (silhouette) => silhouette.silhouetteRefId
      ),
      special_attributes: record.specialAttributeDetails.map(
        (specialAttribute) => specialAttribute.specialAttributeRefId
      ),
      trends: record.trendDetails.map((trends) => trends.trendRefId),
      editId: record.id,
    });
    console.log(record);
    console.log(moment(record.date));
    showModal();
  };

  return (
    <div>
      <div>ProductSpecification</div>

      <Button onClick={showModal}>Add new Tech Pack</Button>
      <div className="FilterSection">
        {/** search new */}

        <form onSubmit={handleSearch} className="searchBarr">
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit"> Search</button>
        </form>

        <br />

        {/** search new */}

        {/** search old
            <Input type="text"
          size="large"
          name="searchInput"
          value={searchInput}
          onChange={async (event) => setSearchInput(event.target.value)}
          // onKeyPress={(event) => handleSearchBarKeyPress(event)}
          label="Search"
        />

        <Button onClick={handleSearch} >
          search
        </Button>    
    */}
      </div>

      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
        />
      </div>

      <Modal
        title="Add New Tech Pack"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form
            form={designform}
            layout="vertical"
            onFinish={addNewProductSpecification}
          >
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="styleNo"
                  name="styleNo"
                  rules={[
                    {
                      required: true,
                      message: 'Style no is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Style Number" value={editData?.styleNo} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="SKU"
                  name="SKU"
                  rules={[
                    {
                      required: true,
                      message: 'SKU is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="SKU" />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Div"
                  name="Div"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Division" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  name="Date"
                  label="Date"
                  rules={[
                    {
                      type: 'object',
                      required: true,
                      message:
                        'Plese select the date of product Specification!',
                    },
                  ]}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Season"
                  name="season"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Season" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Product Category"
                  name="productCategory"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Product Category" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Description" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Block"
                  name="block"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Block" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Sub Category" name="sub_category">
                  <Select placeholder="Select Sub Category">
                    <Select.Option value="Shirt">Shirt</Select.Option>
                    <Select.Option value="Shacket">Shacket</Select.Option>
                    <Select.Option value="Jacket">Jacket</Select.Option>
                    <Select.Option value="Overshirt">Overshirt</Select.Option>
                    <Select.Option value="Chino">Chino</Select.Option>
                    <Select.Option value="Pant-Trouser">
                      Pant-Trouser
                    </Select.Option>
                    <Select.Option value="Shorts">Shorts</Select.Option>
                    <Select.Option value="Jogger">Jogger</Select.Option>
                    <Select.Option value="Crew">Crew</Select.Option>
                    <Select.Option value="Neck-Tee">Neck-Tee</Select.Option>
                    <Select.Option value="Polo">Polo</Select.Option>
                    <Select.Option value="Hoodie">Hoodie</Select.Option>
                    <Select.Option value="Sweatshirt">Sweatshirt</Select.Option>
                    <Select.Option value="Pullover-Sweater">
                      Pullover-Sweater
                    </Select.Option>
                    <Select.Option value="Bomber">Bomber</Select.Option>
                    <Select.Option value="Gilet">Gilet</Select.Option>
                    <Select.Option value="Pocket-square">
                      Pocket square
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Collection TR"
                  name="collection_tr"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Collection TR" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Collection"
                  name="collection"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="collection" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Factory"
                  name="factory"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="factory" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Colour"
                  name="colour"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Colour" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch Season"
                  name="launchSeason"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Launch Season" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fit"
                  name="fit"
                  rules={[
                    {
                      type: 'string',
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Fit" />
                </Form.Item>
              </Col>

              {/* Launch Month*/}

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Launch Month" name="launchMonth">
                  <Select placeholder="Select Launch Month">
                    <Select.Option value="January">January</Select.Option>
                    <Select.Option value="February">February</Select.Option>
                    <Select.Option value="March">March</Select.Option>
                    <Select.Option value="April">April</Select.Option>
                    <Select.Option value="May">May</Select.Option>
                    <Select.Option value="June">June</Select.Option>
                    <Select.Option value="July">July</Select.Option>
                    <Select.Option value="August">August</Select.Option>
                    <Select.Option value="September">September</Select.Option>
                    <Select.Option value="October">October</Select.Option>
                    <Select.Option value="November">November</Select.Option>
                    <Select.Option value="December">December</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="New Repeat" name="new_Repeat">
                  <Select placeholder="Select New Or Repeat">
                    <Select.Option value="New">New</Select.Option>
                    <Select.Option value="Repeat">Repeat</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Sleeve" name="Sleeve">
                  <Select placeholder="Select Sleeve">
                    <Select.Option value="Full sleeve">
                      Full sleeve
                    </Select.Option>
                    <Select.Option value="Half sleeve">
                      Half sleeve
                    </Select.Option>
                    <Select.Option value="Sleeveless">Sleeveless</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Design Category" name="designCategory">
                  <Select placeholder="Select Design Category">
                    <Select.Option value="Core">Core</Select.Option>
                    <Select.Option value="Fashion">Fashion</Select.Option>
                    <Select.Option value="Experimental">
                      Experimental
                    </Select.Option>
                    <Select.Option value="Indian">Indian</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Attribution Category"
                  name="attributionCategory"
                >
                  <Select placeholder="Select Attribution Category">
                    <Select.Option value="Formal">Core</Select.Option>
                    <Select.Option value="Semi formal">Fashion</Select.Option>
                    <Select.Option value="Evening">Experimental</Select.Option>
                    <Select.Option value="Casual">Indian</Select.Option>
                    <Select.Option value="Semi casual">Indian</Select.Option>
                    <Select.Option value="Casual occasion">
                      Indian
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Pattern" name="pattern">
                  <Select placeholder="Select Pattern">
                    <Select.Option value="Solid">Solid</Select.Option>
                    <Select.Option value="Check">Check</Select.Option>
                    <Select.Option value="Stripe">Stripe</Select.Option>
                    <Select.Option value="Chambray">Chambray</Select.Option>
                    <Select.Option value="Jacquard">Jacquard</Select.Option>
                    <Select.Option value="Dobby">Dobby</Select.Option>
                    <Select.Option value="Print">Print</Select.Option>
                    <Select.Option value="Ombre">Ombre</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Knit Woven" name="knitWoven">
                  <Select placeholder="Select Knit Woven">
                    <Select.Option value="Knit">Knit</Select.Option>
                    <Select.Option value="Woven">Woven</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Freshness" name="freshness">
                  <Select placeholder="Select Freshness">
                    <Select.Option value="New">New</Select.Option>
                    <Select.Option value="Variant">Variant</Select.Option>
                    <Select.Option value="Repeat">Repeat</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Silhouette" name="silhouettes">
                  <Select placeholder="Select Silhouette" mode="multiple">
                    {silhouetteList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.silhouetteName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Special Attribute" name="special_attributes">
                  <Select
                    placeholder="Select Special Attribute"
                    mode="multiple"
                  >
                    {specialAttributeList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.specialAttributeName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Trends" name="trends">
                  <Select placeholder="Select Trends" mode="multiple">
                    {trendsList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.trendsName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Colour Family" name="colourFamily">
                  <Select placeholder="Select Colour Family">
                    <Select.Option value="White">White</Select.Option>
                    <Select.Option value="Khaki/Beige">
                      Khaki/Beige
                    </Select.Option>
                    <Select.Option value="Black">Black</Select.Option>
                    <Select.Option value="Navy">Navy</Select.Option>
                    <Select.Option value="Indigo">Indigo</Select.Option>
                    <Select.Option value="Blue">Blue</Select.Option>
                    <Select.Option value="Grey">Grey</Select.Option>
                    <Select.Option value="Pink">Pink</Select.Option>
                    <Select.Option value="Red">Red</Select.Option>
                    <Select.Option value="Orange">Orange</Select.Option>
                    <Select.Option value="Mustard">Mustard</Select.Option>
                    <Select.Option value="Gold">Gold</Select.Option>
                    <Select.Option value="Brown">Brown</Select.Option>
                    <Select.Option value="Green/Olive">
                      Green/Olive
                    </Select.Option>
                    <Select.Option value="Violet/Lavender">
                      Violet/Lavender
                    </Select.Option>
                    <Select.Option value="Burgundy">Burgundy</Select.Option>
                    <Select.Option value="Rust">Rust</Select.Option>
                    <Select.Option value="Teal">Teal</Select.Option>
                    <Select.Option value="Off-white">Off-white</Select.Option>
                    <Select.Option value="Yellow">Yellow</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Design Season"
                  name="designSeason"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Design Season" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Life Occasion" name="lifeOccasion">
                  <Select placeholder="Select Life Occasion">
                    <Select.Option value="Formal">Formal</Select.Option>
                    <Select.Option value="Semi formal">
                      Semi formal
                    </Select.Option>
                    <Select.Option value="Evening">Evening</Select.Option>
                    <Select.Option value="Casual">Casual</Select.Option>
                    <Select.Option value="Semi casual">
                      Semi casual
                    </Select.Option>
                    <Select.Option value="Casual occasion">
                      Casual occasion
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Washing" name="washing">
                  <Select placeholder="Select Washing">
                    <Select.Option value="WASHED">Washed</Select.Option>
                    <Select.Option value="UNWASHED">Unwashed</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="PACKING"
                  name="PACKING"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="PACKING" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Style Sample" name="styleSample">
                  <Select placeholder="Select Style Sample">
                    <Select.Option value="TRIAL SAMPLE">
                      Trial Sample
                    </Select.Option>
                    <Select.Option value="PRE PRODUCTION SAMPLE">
                      Pre Production Sample
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch"
                  name="launch_masters"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select" mode="multiple">
                    {optionList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.launchName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Form.Item label="Username" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default ProductSpecification;
