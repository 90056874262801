import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';

const AllLaunchedProduct = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [merchForm] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const toFinalProductView = (state) => {
    navigate('/fullProductView', { state });
  };

  const handleSearch = async (event) => {
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchDesignMaster(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(
          `${API}/products?filters[SKU][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const designMasterData = await response.json();

        data1 = designMasterData.data.map((entry) => {
          return {
            id: entry.id,
            productName: entry.attributes.factory,
            websiteProductName: entry.attributes.mill,
            quality: entry.attributes.quality,
            fabricCode: entry.attributes.fabricCode,
            freshOrStock: entry.attributes.freshOrStock,
            articleName: entry.attributes.articleName,
            fabricPrice: entry.attributes.fabricPrice,
            fabricQty: entry.attributes.fabricQty,
            Booked: entry.attributes.Booked,
            SKU: entry.attributes.SKU,
          };
        });
        console.log('original Data:', designMasterData);
        console.log('Data:', data1);
        setData(data1);

        setTotal(designMasterData.meta.pagination.total);
        setPageSize(designMasterData.meta.pagination.pageSize);
        setPageCount(designMasterData.meta.pagination.pageCount);
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);

    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/products?pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      data2 = paginationData.data.map((entry) => {
        return {
          id: entry.id,
          factory: entry.attributes.factory,
          mill: entry.attributes.mill,
          quality: entry.attributes.quality,
          fabricCode: entry.attributes.fabricCode,
          freshOrStock: entry.attributes.freshOrStock,
          articleName: entry.attributes.articleName,
          fabricPrice: entry.attributes.fabricPrice,
          fabricQty: entry.attributes.fabricQty,
          Booked: entry.attributes.Booked,
          SKU: entry.attributes.SKU,
        };
      });
      console.log('original Data:', paginationData);
      console.log('Data:', data2);
      setData(data2);
      // let page = paginationData.meta.pagination.page;

      setTotal(paginationData.meta.pagination.total);
      setPageSize(paginationData.meta.pagination.pageSize);
      setPageCount(paginationData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  let data1 = [];

  const fetchDesignMaster = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/products?pagination[page]=1&pagination[pageSize]=${pageSize}`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const designMasterData = await response.json();

      data1 = designMasterData.data.map((entry) => {
        return {
          id: entry.id,
          factory: entry.attributes.factory,
          mill: entry.attributes.mill,
          quality: entry.attributes.quality,
          fabricCode: entry.attributes.fabricCode,
          freshOrStock: entry.attributes.freshOrStock,
          articleName: entry.attributes.articleName,
          fabricPrice: entry.attributes.fabricPrice,
          fabricQty: entry.attributes.fabricQty,
          Booked: entry.attributes.Booked,
          SKU: entry.attributes.SKU,
        };
      });
      console.log('original Data:', designMasterData);
      console.log('Data:', data1);
      setData(data1);

      setTotal(designMasterData.meta.pagination.total);
      setPageSize(designMasterData.meta.pagination.pageSize);
      setPageCount(designMasterData.meta.pagination.pageCount);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchDesignMaster(authToken);
    }
  }, []);

  useEffect(() => {
    console.log(editData);
    merchForm.setFieldsValue(editData);
  }, [editData, merchForm]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    merchForm.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    merchForm.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const addNewProductSpecification = async (data) => {
    setSaving(true);

    const fabricPrice = parseFloat(data['fabricPrice']);
    const fabricQty = parseInt(data['fabricQty']);

    delete data['fabricPrice'];
    delete data['fabricQty'];

    const values = {
      ...data,
      fabricPrice: fabricPrice,
      fabricQty: fabricQty,
    };

    data = { data: values };

    console.log('data:', data);

    // return;
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/merchandise-masters/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Product Specification.');
        } else {
          message.success('Data saved successfully!');
          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/merchandise-masters/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          fetchDesignMaster(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const columns = [
    {
      key: 'SKU',
      title: 'SKU',
      render: (record) => {
        console.log(record);
        return (
          <div>
            {' '}
            <a
              onClick={() => {
                toFinalProductView(record);
              }}
            >
              {record.SKU}
            </a>
          </div>
        );
      },
    },
    {
      key: 'factory',
      title: 'Factory',
      dataIndex: 'factory',
    },
    {
      key: 'mill',
      title: 'Mill',
      dataIndex: 'mill',
    },
    {
      key: 'quality',
      title: 'Quality',
      dataIndex: 'quality',
    },
    {
      key: 'fabricCode',
      title: 'Fabric Code',
      dataIndex: 'fabricCode',
    },
    {
      key: 'freshOrStock',
      title: 'Fabric Or Stock',
      dataIndex: 'freshOrStock',
    },
    {
      key: 'articleName',
      title: 'Article Name',
      dataIndex: 'articleName',
    },
    {
      key: 'fabricPrice',
      title: 'Fabric Price',
      dataIndex: 'fabricPrice',
    },
    {
      key: 'fabricQty',
      title: 'Fabric Quantity',
      dataIndex: 'fabricQty',
    },
    {
      key: 'Booked',
      title: 'Booked',
      dataIndex: 'Booked',
    },
  ];

  const deleteDesignMasterEntry = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/merchandise-masters/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      fetchDesignMaster(getToken());
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmMerchMasterEntry = async (record) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/merchandise-masters/${record.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ data: { completionStage: 'completed' } }),
      });
      const responseData = await response.json();
      console.log(responseData);

      fetchDesignMaster(getToken());
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteDesignMasterEntry(record.id);
      },
    });
  };

  const confirmMerchEntry = (record) => {
    Modal.confirm({
      title: 'You are Marking this Entry Complete and Confirmed',
      onOk: () => {
        confirmMerchMasterEntry(record);
      },
    });
  };

  const Edit = (record) => {
    setEditData({
      SKU: record.SKU,
      factory: record.factory,
      mill: record.mill,
      quality: record.quality,
      fabricCode: record.fabricCode,
      freshOrStock: record.freshOrStock,
      articleName: record.articleName,
      fabricPrice: record.fabricPrice,
      fabricQty: record.fabricQty,
      Booked: record.Booked,
      editId: record.id,
    });
    console.log(moment(record));
    console.log(moment(record.date));
    showModal();
  };

  return (
    <div>
      <div>Merchendising All Completed Products</div>

      <div className="FilterSection">
        <Input
          type="text"
          size="large"
          name="searchInput"
          value={searchInput}
          onChange={async (event) => setSearchInput(event.target.value)}
          label="Search"
        />

        <Button onClick={handleSearch}>search</Button>
      </div>

      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
        />
      </div>

      <Modal
        title="Add Merchendising Details"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form
            form={merchForm}
            layout="vertical"
            onFinish={addNewProductSpecification}
          >
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="SKU"
                  name="SKU"
                  rules={[
                    {
                      required: true,
                      message: 'SKU is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="SKU" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Factory"
                  name="factory"
                  rules={[
                    {
                      required: true,
                      message: 'Factory is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Factory Name" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Mill"
                  name="mill"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Mill" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Quality"
                  name="quality"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Quality" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fabric Code"
                  name="fabricCode"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Fabric Code" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fresh or Stock"
                  name="freshOrStock"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Fresh or Stock" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Article Name"
                  name="articleName"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Article Name" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="fabric Price"
                  name="fabricPrice"
                  rules={[
                    {
                      required: true,
                      // pattern: new RegExp('/^\d{0,8}(\.\d{1,4})?$/'),
                      message: 'Please enter a valid price',
                      pattern: '^([0-9]{0,10}((.)[0-9]{0,4}))$',
                    },
                  ]}
                >
                  <Input placeholder="fabric Price" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fabric Qty"
                  name="fabricQty"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid price',
                      pattern: '^([0-9]{0,10}((.)[0-9]{0,4}))$',
                    },
                  ]}
                >
                  <Input placeholder="fabric Quantity" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item label="Booked" name="Booked">
                  <Select placeholder="Select ">
                    <Select.Option value="Booked">Booked</Select.Option>
                    <Select.Option value="Not Booked">Not Booked</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Form.Item label="merchendiseId" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default AllLaunchedProduct;
