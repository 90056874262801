import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Select,
  Divider,
  Tooltip,
  Space,
  Collapse,
  Typography,
} from 'antd';

import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { getUserDetails } from '../../helpers';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  RollbackOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  getMonthNameFromStringDate,
  getMonthIndex,
  getDateFromMonth,
  dateFormat,
  monthNames,
  delay,
} from '../../utils/dateUtils';
import {
  coreProductStatusArray as coreProductStatusList,
  attributionCategoryOptionArray,
  paymentModelOptionArray,
  patternOptionArray,
  freshnessOptionArray,
  fabricSpecialAttributeOptionArray,
  knitWovenOptionArray,
  weightOptionArray,
  colorDepthOptionArray,
  lifeOccasionOptionArray,
  tnaStatusOptionArray,
  fabricApplicationTypeArray,
  sleeveOptionArray,
  weaveStructureOptionArray,
  designCategoryOptionArray,
  toProperCase,
} from '../../utils/generic';
import moment from 'moment';

const userRolesAllowedForAttributes = {
  edit: {
    designAttribute: [1000, 1001, 1003],
    merchAttribute: [1000, 1004, 1002],
    planningAttribute: [1000, 1007, 1008],
  },
  view: {},
};

const CoreProductDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = getUserDetails();
  console.log(user, '48');

  const [dataform] = Form.useForm();
  const [editData, setEditData] = useState(null);
  const [updatedValues, setUpdatedValues] = useState([]);
  // const [editData, setEditData] = useState(null);
  const [fabricList, setFabricList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [launchMastersList, setLaunchMastersList] = useState([]);
  const [seasonMastersList, setSeasonMastersList] = useState([]);
  const [familyColorList, setFamilyColorList] = useState([]);
  const [filterColorList, setFilterColorList] = useState([]);
  const [fabricGroupList, setFabricGroupList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [fitList, setFitList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [silhouetteList, setSilhouetteList] = useState([]);
  const [specialAttributeList, setSpecialAttributeList] = useState([]);
  const [trendList, setTrendList] = useState([]);
  const [patternList, setPatternList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [indentSheetList, setIndentSheetList] = useState([]);
  const [techPackList, setTechPackList] = useState([]);
  const [selectedLaunchNew, setSelectedLaunchNew] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenTechPack, setIsModalOpenTechPack] = useState(false);
  const [isModalOpenFabric, setIsModalOpenFabric] = useState(false);
  const [modalform] = Form.useForm();
  const [modalformTechpack] = Form.useForm();
  const [modalformFabricMaster] = Form.useForm();

  const [saving, setSaving] = useState(false);

  const [isLoading1, setIsLoading] = useState(true);

  const [productOptionDetails, setProductOptionDetails] = useState([]);

  const [variantList, setVariantList] = useState([]);
  const [derivedAttributes, setDerivedAttributes] = useState(false);
  const [coreProductData, setCoreProductData] = useState(null);

  const [unitOfMeasurementList, setUnitOfMeasurement] = useState([]);

  const authToken = getToken();
  const { TextArea } = Input;

  //   console.log(location, 'line no 69');
  //   return;

  const fetchCoreProductDetails = async (token, coreProductId) => {
    setIsLoading(true);
    // await delay(5000);
    try {
      //  ${
      //     location?.state && `&filters[otb_record][id][$eq]=${location?.state}`

      // &populate[fabric_used_for_products][populate][fabric][populate][unit_of_measurement][populate]=true&populate[fabric_used_for_products][populate][fabric][populate][fabric_group][populate]=true
      //   }
      const response = await fetch(
        `${API}/core-products/${coreProductId}?populate[launch_masters][populate]=true&populate[product_option_type][populate]=true&populate[indent_sheets][populate]=true&populate[techpacks][populate]=true&populate[fabric_used_for_products][populate][supplier_master][populate]=true&populate[fabric_used_for_products][populate][tna][populate]=true&populate[fabric_used_for_products][populate][fabric][populate][fabric_group][populate]=true&populate[fabric_used_for_products][populate][fabric][populate][unit_of_measurement][populate]=true&populate[sub_color_lists][populate][filter_color][populate]=true&populate[fits_list][populate]=true&populate[size_list][populate]=true&populate[design_season][populate]=true&populate[launch_season][populate]=true&populate[silhouettes][populate]=true&populate[trends][populate]=true&populate[special_attributes][populate]=true&populate[garment_special_attributes][populate]=true&populate[garment_special_attributes2][populate]=true&populate[color_family][populate]=true&populate[product_variants][populate]=true&populate[collection][populate]=true&populate[patterns][populate]=true&populate[supplier_master][populate]=true`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      console.log(responseData, 'core product details response check');
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;

        // data1 = responseData.data.map((entry) => {
        //---------map start
        let entry = responseData.data;
        let launchObj = [];
        if (entry.attributes.launch_masters?.data?.length > 0) {
          console.log(
            'core product details 414',
            entry.attributes.launch_masters?.data
          );
          launchObj = entry.attributes.launch_masters?.data.map(
            (launchMasterObject) => {
              console.log(launchMasterObject);
              return {
                launchRefId: launchMasterObject.id,
                launchName: launchMasterObject.attributes.launchName,
                launchCategory: launchMasterObject.attributes.launchCat,
                launchMonth: launchMasterObject.attributes.launchMonth,
                launchDrop: launchMasterObject.attributes.launchDrop,
                launchDate: launchMasterObject.attributes.launchDate,
              };
            }
          );
        }

        let fabricGroup = null;
        let fabricUtilizedObjArray = [];
        if (entry.attributes.fabric_used_for_products?.data?.length > 0) {
          fabricUtilizedObjArray =
            entry.attributes.fabric_used_for_products?.data.map(
              (fabricUtilized, index) => {
                console.log(
                  'fabric utilized details for fabric group details',
                  fabricUtilized
                );
                if (index === 0) {
                  console.log('Index is 0 ', index, fabricUtilized);
                  const fabricGrpObject = {};
                  fabricGrpObject.fabricGroupId =
                    fabricUtilized?.attributes?.fabric?.data?.attributes
                      ?.fabric_group?.data?.id ?? null;
                  fabricGrpObject.fabricGroupName =
                    fabricUtilized?.attributes?.fabric?.data?.attributes
                      ?.fabric_group?.data?.attributes?.fabric_group_name ??
                    null;
                  //   const {
                  //     attributes: {
                  //       fabric: {
                  //         data: {
                  //           attributes: {
                  //             fabric_group: {
                  //               data: {
                  //                 id: fabricGroupId = null,
                  //                 attributes: {
                  //                   fabric_group_name: fabricGroupName = null,
                  //                 } = {},
                  //               } = {},
                  //             } = {},
                  //           } = {},
                  //         } = {},
                  //       } = {},
                  //     } = {},
                  //   } = fabricUtilized ?? {};
                  //   console.log('Index is 0 a', fabricGroupId, fabricGroupName);

                  fabricGroup = {
                    ...fabricGrpObject,
                  };
                }

                // if (index === 0) {
                //   console.log('Index is 0 ', index, fabricUtilized);

                //   const {
                //     attributes: {
                //       fabric: {
                //         data: {
                //           attributes: {
                //             fabric_group: {
                //               data: {
                //                 id: fabricGroupId = null,
                //                 attributes: {
                //                   fabric_group_name: fabricGroupName = null,
                //                 } = {},
                //               } = {},
                //             } = {},
                //           } = {},
                //         } = {},
                //       } = {},
                //     } = {},
                //   } = fabricUtilized ?? {};
                //   console.log('Index is 0 a', fabricGroupId, fabricGroupName);

                //   fabricGroup = {
                //     fabricGroupId,
                //     fabricGroupName,
                //   };
                // }

                let fupId = fabricUtilized.id;
                // let availableQuantity =
                //   fabricUtilized.attributes.availableQuantity;
                let consumptionPerUnit =
                  fabricUtilized.attributes.consumptionPerUnit;
                let utilizationQty = fabricUtilized.attributes.utilizationQty;
                let fabric_role = fabricUtilized.attributes.fabric_role;

                let fabricObject = {
                  fabricId: fabricUtilized.attributes?.fabric?.data?.id,
                  fabricName:
                    fabricUtilized.attributes?.fabric?.data?.attributes
                      ?.fabricName,
                  fabricCode:
                    fabricUtilized.attributes?.fabric?.data?.attributes
                      ?.fabricCode,

                  fabricComposition:
                    fabricUtilized.attributes?.fabric?.data?.attributes
                      ?.fabricComposition,
                  countAndConstruction:
                    fabricUtilized.attributes?.fabric?.data?.attributes
                      ?.countAndConstruction,
                  fabricPrice:
                    fabricUtilized.attributes?.fabric?.data?.attributes
                      ?.fabricPrice,
                  weight:
                    fabricUtilized.attributes?.fabric?.data?.attributes?.weight,
                  uomDetails: {
                    uomRefId:
                      fabricUtilized.attributes?.fabric?.data?.attributes
                        ?.unit_of_measurement?.data?.id,
                    uom_measurement_name:
                      fabricUtilized.attributes?.fabric?.data?.attributes
                        ?.unit_of_measurement?.data?.attributes
                        ?.measurement_name,
                    uom_measurement_symbol:
                      fabricUtilized.attributes?.fabric?.data?.attributes
                        ?.unit_of_measurement?.data?.attributes
                        ?.measurement_symbol,
                  },
                };
                let supplierObject = {
                  supplierId:
                    fabricUtilized.attributes?.supplier_master?.data?.id,
                  supplierName:
                    fabricUtilized.attributes?.supplier_master?.data?.attributes
                      ?.supplierName,
                };
                let tnaObject = {
                  tnaId: fabricUtilized.attributes?.tna?.data?.id,
                  tnaStatus:
                    fabricUtilized.attributes?.tna?.data?.attributes?.status,
                  tnaTentativeDate:
                    fabricUtilized.attributes?.tna?.data?.attributes
                      ?.tentativeEndDate,
                  actualEndDate:
                    fabricUtilized.attributes?.tna?.data?.attributes
                      ?.actualEndDate,
                  tnaStartDate:
                    fabricUtilized.attributes?.tna?.data?.attributes?.startDate,
                };

                return {
                  fupId,
                  consumptionPerUnit,
                  utilizationQty,
                  fabric_role,

                  fabricObject,
                  supplierObject,
                  tnaObject,
                };
              }
            );
        }

        console.log('temp check', fabricUtilizedObjArray, fabricGroup);

        let indentSheetsObjArray = [];
        if (entry.attributes.indent_sheets?.data?.length > 0) {
          console.log(entry.attributes.indent_sheets?.data);
          indentSheetsObjArray = entry.attributes.indent_sheets?.data.map(
            (indentSheetObject) => {
              console.log(indentSheetObject);
              return {
                indentRefId: indentSheetObject.id,
                indentReffName:
                  indentSheetObject.attributes.Indent_file_reference_id,
                indentRefLink: indentSheetObject.attributes.Indent_file_link,
                indentDate: indentSheetObject.attributes.indent_file_date,
                indentType: indentSheetObject.attributes.indent_type,
              };
            }
          );
        }

        let techpacksObjArray = [];
        if (entry.attributes.techpacks?.data?.length > 0) {
          console.log(entry.attributes.indent_sheets?.data);
          techpacksObjArray = entry.attributes.techpacks?.data.map(
            (techpackObj) => {
              console.log(techpackObj);
              return {
                techpackRefId: techpackObj.id,
                techpackReffName:
                  techpackObj.attributes.techpack_file_reference_id,
                techpackRefLink: techpackObj.attributes.techpack_file_link,
                techpackDate: techpackObj.attributes.techpack_file_date,
                techpackSKU: techpackObj.attributes.sku,
              };
            }
          );
        }

        let subColorObjArray = [];
        if (entry.attributes.sub_color_lists?.data?.length > 0) {
          console.log(entry.attributes.sub_color_lists?.data);
          subColorObjArray = entry.attributes.sub_color_lists?.data.map(
            (subColorObj) => {
              const {
                id: id = null,
                attributes: {
                  subColorName = null,
                  filter_color: filterColorObj = null,
                },
              } = subColorObj;

              const {
                id: filterColorId = null,
                attributes: { filter_color_name = null } = {},
              } = filterColorObj?.data ?? {};
              console.log(
                'sub color details destructures',
                subColorObj,
                id,
                subColorName,
                filterColorId,
                filter_color_name
              );
              return {
                subColorRefId: id,
                subColorName: subColorName,
                filterColorId,
                filterColorName: filter_color_name,
              };
            }
          );
        }

        let fitObjArray = [];
        if (entry.attributes?.fits_list?.data?.length > 0) {
          console.log(entry.attributes.fits_list?.data);
          fitObjArray = entry.attributes.fits_list?.data.map((fitObj) => {
            console.log(fitObj);
            return {
              fitRefId: fitObj?.id,
              fitName: fitObj?.attributes.fit_name,
            };
          });
        }

        let supplierDetails = {};
        if (entry?.attributes?.supplier_master) {
          let supplierObject = entry?.attributes?.supplier_master;
          supplierDetails = {
            //productCat -> product option type, example: shirt, crew, pants, etc
            supplierRefId: supplierObject?.data?.id,
            supplierName: supplierObject?.data?.attributes?.supplierName,
          };
        }

        let garmentSpecialAttributesObjectArray = [];
        if (entry?.attributes?.garment_special_attributes.data?.length > 0) {
          garmentSpecialAttributesObjectArray =
            entry?.attributes?.garment_special_attributes.data.map(
              (specialAttriObject) => {
                console.log('garment Special Attribute : ', specialAttriObject);
                return {
                  garmentSpecialAttributeRefId: specialAttriObject?.id,
                  garmentSpecialAttributeName:
                    specialAttriObject?.attributes?.specialAttribute_name,
                };
              }
            );
        }

        let garmentSpecialAttributes2ObjectArray = [];
        if (entry?.attributes?.garment_special_attributes2.data?.length > 0) {
          garmentSpecialAttributes2ObjectArray =
            entry?.attributes?.garment_special_attributes2?.data?.map(
              (specialAttriObject) => {
                console.log(
                  'garment Special Attribute2 : ',
                  specialAttriObject
                );
                return {
                  garmentSpecialAttributeRefId: specialAttriObject?.id,
                  garmentSpecialAttributeName:
                    specialAttriObject?.attributes?.specialAttribute_name,
                };
              }
            );
        }

        let sizeObjArray = [];
        if (entry.attributes?.size_list?.data?.length > 0) {
          console.log(entry.attributes?.size_list?.data);
          sizeObjArray = entry.attributes?.size_list?.data.map((sizeObj) => {
            console.log(sizeObj);
            return {
              sizeRefId: sizeObj?.id,
              sizeName: sizeObj?.attributes?.size_name,
            };
          });
        }

        let specialAttrObjArray = [];
        if (entry.attributes?.special_attributes?.data?.length > 0) {
          console.log(entry.attributes?.special_attributes?.data);
          specialAttrObjArray = entry.attributes?.special_attributes?.data.map(
            (specialAttrObj) => {
              console.log(specialAttrObj);
              return {
                specialAttrRefId: specialAttrObj?.id,
                specialAttrName:
                  specialAttrObj.attributes?.specialAttribute_name,
              };
            }
          );
        }

        let silhouetteObjArray = [];
        if (entry.attributes?.silhouettes?.data?.length > 0) {
          console.log(entry.attributes?.silhouettes?.data);
          silhouetteObjArray = entry.attributes?.silhouettes?.data.map(
            (silhouetteObj) => {
              console.log(silhouetteObj);
              return {
                silhouetteRefId: silhouetteObj?.id,
                silhouetteName: silhouetteObj?.attributes?.silhouette_name,
              };
            }
          );
        }

        let trendObjArray = [];
        if (entry.attributes?.trends?.data?.length > 0) {
          console.log(entry.attributes?.trends?.data);
          trendObjArray = entry.attributes?.trends?.data.map((trendObj) => {
            console.log(trendObj);
            return {
              trendRefId: trendObj?.id,
              trendName: trendObj?.attributes?.trend_name,
            };
          });
        }

        let productOptionTypeObject = {};
        if (entry.attributes.product_option_type) {
          let productOptionType = entry.attributes.product_option_type;
          productOptionTypeObject = {
            //productCat -> product option type, example: shirt, crew, pants, etc
            productCatRefId: productOptionType.data.id,
            productCatName:
              productOptionType.data.attributes.productCategoryName,
          };
        }

        let designSeasonObj = {};
        if (entry?.attributes?.design_season) {
          let designSeason = entry?.attributes?.design_season;
          console.log(designSeason);
          designSeasonObj = {
            designSeasonRefId: designSeason?.data?.id,
            designSeasonName: designSeason?.data?.attributes?.season_id,
          };
        }

        let launchSeasonObj = {};
        if (entry?.attributes?.launch_season) {
          let launchSeason = entry?.attributes?.launch_season;
          console.log(launchSeason);
          launchSeasonObj = {
            launchSeasonRefId: launchSeason?.data?.id,
            launchSeasonName: launchSeason?.data?.attributes?.season_id,
          };
        }

        let colorFamilyObj = {};
        if (entry?.attributes?.color_family) {
          let color = entry?.attributes?.color_family;
          console.log(color, 'Color Family in core product details');
          colorFamilyObj = {
            colorFamilyRefId: color?.data?.id,
            colorFamilyName: color?.data?.attributes?.subColorName,
          };
        }

        let collectionObj = {};
        if (entry?.attributes?.collection) {
          let collection = entry?.attributes?.collection;
          console.log(collection, 'collection in core product details');
          collectionObj = {
            collectionRefId: collection?.data?.id,
            collectionName: collection?.data?.attributes?.colorName,
          };
        }

        let patternObjArray = [];
        if (entry?.attributes?.patterns?.data?.length > 0) {
          console.log(
            entry.attributes?.patterns?.data,
            'patterns core product details'
          );
          patternObjArray = entry.attributes?.patterns?.data.map(
            (patternObj) => {
              console.log(patternObj);
              return {
                patternRefId: patternObj?.id,
                patternName: patternObj?.attributes?.patternName,
              };
            }
          );
        }

        let productVariantArray = [];
        if (entry.attributes.product_variants?.data?.length > 0) {
          console.log(entry.attributes.product_variants?.data);
          productVariantArray = entry.attributes.product_variants?.data.map(
            (productVariantObject) => {
              console.log(productVariantObject);
              return {
                variantRefId: productVariantObject?.id,
                variantSKU: productVariantObject?.attributes?.variant_sku,
                variantSize: productVariantObject?.attributes?.size,
                variantSubColor: productVariantObject?.attributes?.sub_color,
                variantFit: productVariantObject?.attributes?.fit,
                variantCost: productVariantObject?.attributes?.cost,
                variantMrp: productVariantObject?.attributes?.mrp,
              };
            }
          );
        }

        const data1 = {
          key: entry.id,
          id: entry.id,
          month: entry.attributes.month,
          launchDrop: entry.attributes.launchDrop,
          category: productOptionTypeObject?.productCatName,
          quantity: entry.attributes.qty,

          sleeve: entry.attributes.Sleeve,
          weaveStructure: entry.attributes.weave_structure,
          amrp: entry.attributes.amrp,
          assumedMRP: entry.attributes.assumedMRP,
          fashionCore: entry.attributes.fashionCore,
          description: entry.attributes.description,

          sku: entry.attributes.sku,
          new_Repeat: entry.attributes.new_Repeat,
          proj_disc: entry.attributes.proj_disc,
          cogs: entry.attributes.cogs,
          total_cogs: entry.attributes.total_cogs,
          cogs_percentage: entry.attributes.cogs_percentage,
          pp_slab: entry.attributes.pp_slab,
          asp: entry.attributes.asp,
          supplier_master: supplierDetails,

          arv: entry.attributes.arv,
          total_arv: entry.attributes.total_arv,
          target_cogs: entry.attributes.target_cogs,
          lifeOccasion: entry.attributes.lifeOccasion,
          weight: entry.attributes.weight,
          color_depth: entry.attributes.color_depth,
          gsm: entry.attributes.gsm,
          attributionCategory: entry.attributes.attributionCategory,
          knit_woven: entry.attributes.knit_woven,
          freshness: entry.attributes.freshness,
          fabric_special_attribute: entry.attributes.fabric_special_attribute,

          icons: entry.attributes.icons,
          designCategory: entry.attributes.designCategory,
          po_price: entry.attributes.po_price,
          payment_modal: entry.attributes.payment_modal,
          cost_of_finance: entry.attributes.cost_of_finance,

          pattern: patternObjArray,
          collection: collectionObj,
          launchDetails: launchObj,
          productOptionType: productOptionTypeObject,
          designSeason: designSeasonObj,
          launchSeason: launchSeasonObj,
          colorFamily: colorFamilyObj,
          indentSheetDetails: indentSheetsObjArray,
          techpackDetails: techpacksObjArray,
          colorVariantDetails: subColorObjArray,
          sizeVariantDetails: sizeObjArray,
          fitVariantDetails: fitObjArray,
          silhouetteDetails: silhouetteObjArray,
          trendDetails: trendObjArray,
          specialAttributeDetails: specialAttrObjArray,
          garmentSpecialAttributesDetails: garmentSpecialAttributesObjectArray,
          garmentSpecialAttributes2Details:
            garmentSpecialAttributes2ObjectArray,
          productVariantDetails: productVariantArray,
          status: entry.attributes.status,
          object_status: entry.attributes.object_status,
          object_status_no: entry.attributes.object_status_no,
          fabricUtilizationDetails: fabricUtilizedObjArray,
          fabricGroupDetails: fabricGroup,
        };

        //---------map end
        // });

        console.log('original Data CORE PRODUCT:', responseData);
        console.log('current Data CORE PRODUCT after modification:', data1);
        // return data1;
        setCoreProductData(data1);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSeasonList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/seasons?sort[0]=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData?.data?.map((entry) => {
        return {
          id: entry?.id,
          seasonId: entry?.attributes?.season_id,
        };
      });

      setSeasonMastersList(optionsData2);
      console.log('original season option  Data:', optionsData);
      console.log('modified season Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLaunchList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id&createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          launchDrop: entry.attributes.launchDrop,
          //   launchCat: entry.attributes.launchCat,

          seasonName: entry.attributes.season?.data?.attributes.season_id,
          seasonRefId: entry.attributes.season?.data?.id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setLaunchMastersList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFitList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/fits?sort[0]=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          fitName: entry.attributes.fit_name,
        };
      });

      setFitList(optionsData2);
      console.log('original fit option Data:', optionsData);
      console.log('modified fit Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSizeList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/sizes?sort[0]=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          sizeName: entry.attributes.size_name,
        };
      });

      setSizeList(optionsData2);
      console.log('original Size option Data:', optionsData);
      console.log('modified Size Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };
  const fetchColorList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/sub-colors?sort[0]=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          colorName: entry.attributes.subColorName,
        };
      });

      setColorList(optionsData2);
      console.log('original color option Data:', optionsData);
      console.log('modified color Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFamilyColorList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/color-families?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry?.id,
          colorName: entry?.attributes?.colorName,
        };
      });

      setFamilyColorList(optionsData2);
      console.log('original color family option Data:', optionsData);
      console.log('modified color family Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFilterColorList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/filter-colors?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry?.id,
          filterColorName: entry?.attributes?.filter_color_name,
        };
      });

      setFilterColorList(optionsData2);
      console.log('original color filter group option Data:', optionsData);
      console.log('modified color filter Group option Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFabricGroupList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/fabric-groups?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry?.id,
          fabricGroupName: entry?.attributes?.fabric_group_name,
        };
      });

      setFabricGroupList(optionsData2);
      console.log('original fabric group option Data:', optionsData);
      console.log('modified fabric Group option Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  // --Silihouettes
  const fetchSilhouetteList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/silhouettes?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry?.id,
          silhouetteName: entry?.attributes?.silhouette_name,
        };
      });

      setSilhouetteList(optionsData2);
      console.log('original Silhouette option Data:', optionsData);
      console.log('modified Silhouette Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  // Special Attribute
  const fetchSpecialAttributeList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/special-attributes?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry?.id,
          specialAttributeName: entry?.attributes?.specialAttribute_name,
        };
      });

      setSpecialAttributeList(optionsData2);
      console.log('original SpecialAttribute option Data:', optionsData);
      console.log('modified SpecialAttribute Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  // trends
  const fetchTrendList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/trends?sort[0]=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry?.id,
          trendName: entry?.attributes?.trend_name,
        };
      });

      setTrendList(optionsData2);
      console.log('original trends option Data:', optionsData);
      console.log('modified trend Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  // Pattern
  const fetchPatternList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/patterns?sort[0]=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry?.id,
          patternName: entry?.attributes?.patternName,
        };
      });

      setPatternList(optionsData2);
      console.log('original Patterns option Data:', optionsData);
      console.log('modified Pattern Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };
  // Collection
  const fetchCollectionList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/design-collections?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry?.id,
          collectionName: entry?.attributes?.collectionName,
        };
      });

      setCollectionList(optionsData2);
      console.log('original Collections option Data:', optionsData);
      console.log('modified Collection Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductTypeList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/product-option-types?populate[sizes][populate]=true&createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        let sizeObjectArray = [];
        if (entry.attributes?.sizes?.data?.length > 0) {
          console.log(entry.attributes?.sizes?.data);
          sizeObjectArray = entry.attributes?.sizes?.data.map((sizeObj) => {
            console.log(sizeObj);
            return {
              sizeRefId: sizeObj?.id,
              sizeName: sizeObj.attributes?.size_name,
            };
          });
        }

        return {
          id: entry.id,
          productCategoryName: entry.attributes.productCategoryName,
          productLifeOccasion: entry.attributes.lifeOccasion,
          productSizes: sizeObjectArray,
        };
      });

      setProductTypeList(optionsData2);
      console.log('original Product Type Data:', optionsData);
      console.log('modified Product type Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFabricList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/fabrics?populate[unit_of_measurement][populate]=true&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        let uomObject = entry?.attributes?.unit_of_measurement && {
          uomRefId: entry?.attributes?.unit_of_measurement?.data?.id,
          uom_measurement_name:
            entry.attributes?.unit_of_measurement?.data?.attributes
              ?.measurement_name,
          uom_measurement_symbol:
            entry?.attributes?.unit_of_measurement?.data?.attributes
              ?.measurement_symbol,
        };

        return {
          id: entry.id,
          fabricName: entry.attributes.fabricName,
          fabricCode: entry.attributes.fabricCode,
          fabricComposition: entry.attributes.fabricComposition,
          countAndConstruction: entry.attributes.countAndConstruction,
          weight: entry.attributes.weight,
          fabricPrice: entry.attributes.fabricPrice,

          fabric_uom_symbol: uomObject?.uom_measurement_symbol,

          consumptionPerUnit: entry.attributes.consumptionPerUnit,
          fabric_role: entry.attributes.fabricRole,
          utilizationQty: entry.attributes.utilizationQty,
          availableQuantity: entry.attributes.availableQuantity,
          uomDetails: uomObject,
        };
      });

      setFabricList(optionsData2);
      console.log('original Fabric master Data:', optionsData);
      console.log('modified Fabric master Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSupplierList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/suppler-masters?createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          supplierName: entry.attributes.supplierName,
        };
      });

      setSupplierList(optionsData2);
      console.log('original Supplier master Data:', optionsData);
      console.log('modified Supplier master Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };
  const fetchIndentSheetList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/indent-sheets?createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          Indent_file_reference_id: entry.attributes.Indent_file_reference_id,
        };
      });

      setIndentSheetList(optionsData2);
      console.log('original indent sheet Data:', optionsData);
      console.log('modified indent sheet Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };
  const fetchTechpackList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/techpacks?createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          techpack_file_reference_id:
            entry.attributes.techpack_file_reference_id,
        };
      });

      setTechPackList(optionsData2);
      console.log('original techpack  Data:', optionsData);
      console.log('modified techpack Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUnitOfMeasurement = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/unit-of-measurements?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          measurement_name: entry.attributes.measurement_name,
          measurement_symbol: entry.attributes.measurement_symbol,
        };
      });

      setUnitOfMeasurement(optionsData2);
      console.log('original unit of measure Data:', optionsData);
      console.log('modified unit of measure Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(location, 'line no 40');
    setIsLoading(true);

    fetchLaunchList(authToken);
    fetchSeasonList(authToken);
    fetchIndentSheetList(authToken);
    fetchTechpackList(authToken);
    fetchProductTypeList(authToken);
    fetchFabricList(authToken);
    fetchSupplierList(authToken);
    fetchColorList(authToken);
    fetchFamilyColorList(authToken);
    fetchFilterColorList(authToken);
    fetchFabricGroupList(authToken);
    fetchFitList(authToken);
    fetchSizeList(authToken);
    fetchSpecialAttributeList(authToken);
    // fetchSilhouetteList(authToken);
    fetchTrendList(authToken);
    fetchPatternList(authToken);
    fetchCollectionList(authToken);
    fetchUnitOfMeasurement(authToken);

    // setFabricDetails(authToken);

    // setDerivedAttributes(true);

    if (location.state === null) {
    } else {
      fetchCoreProductDetails(authToken, location?.state?.id);
    }
    setIsLoading(false);
  }, []); // setting the required data for the form based on edit / create request.

  const getFabricUtilizedAttributeArray = (fabricUtilizationDetails) => {
    return fabricUtilizationDetails.map((fabricUtilized) => ({
      fupId: fabricUtilized.fupId,
      consumptionPerUnit: fabricUtilized.consumptionPerUnit,
      fabric_role: fabricUtilized.fabric_role,
      utilizationQty: fabricUtilized.utilizationQty,

      fabric: fabricUtilized.fabricObject.fabricId,
      fabricCode: fabricUtilized.fabricObject.fabricCode,
      fabricComposition: fabricUtilized.fabricObject.fabricComposition,
      countAndConstruction: fabricUtilized.fabricObject.countAndConstruction,

      fabricPrice: fabricUtilized.fabricObject.fabricPrice,
      weight: fabricUtilized.fabricObject.weight,
      fabric_uom_symbol:
        fabricUtilized.fabricObject.uomDetails.uom_measurement_symbol,
      supplier_master: fabricUtilized.supplierObject.supplierId,
      tnaId: fabricUtilized?.tnaObject?.tnaId,
      tnaStartDate:
        (fabricUtilized?.tnaObject?.tnaStartDate &&
          moment(fabricUtilized?.tnaObject?.tnaStartDate)) ||
        null,
      tnaTentativeDate:
        (fabricUtilized?.tnaObject?.tnaTentativeDate &&
          moment(fabricUtilized?.tnaObject?.tnaTentativeDate)) ||
        null,
      actualEndDate:
        (fabricUtilized?.tnaObject?.actualEndDate &&
          moment(fabricUtilized?.tnaObject?.actualEndDate)) ||
        null,
      tnaStatus: fabricUtilized?.tnaObject?.tnaStatus,
    }));
  }; // geting nested objects fields in required pattern for easy binding

  const getAllowedSilhoutte = async (productOptionTypRefId) => {
    // get details from db and related silhoutte allowed for sub category type of product option type.
    // set it as silhoutte list array in state and it will be reflected in the dropdown as only allowed silhoutte option

    let optionsData2 = [];
    // setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/product-option-types/${productOptionTypRefId}?populate[product_sub_category][populate][silhouettes]=true`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const optionsData = await response.json();

      console.log('11original allowed silhoutte Data:', optionsData);

      let allowedSilhoutteData =
        optionsData?.data?.attributes?.product_sub_category?.data?.attributes
          ?.silhouettes;

      console.log('good -- ', allowedSilhoutteData);
      // if(11original allowed silhoutte Data)
      optionsData2 =
        allowedSilhoutteData?.data?.map((entry) => {
          return {
            id: entry?.id,
            silhouetteName: entry?.attributes?.silhouette_name,
          };
        }) ?? [];

      setSilhouetteList(optionsData2);
      console.log('original allowed silhoutte Data:', optionsData);
      console.log('modified allowed silhoutte Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      // setIsLoading(false);
    }

    return optionsData2;
  };
  // set Core Product Details

  useEffect(() => {
    if (coreProductData) {
      let allowedSilhoutte = getAllowedSilhoutte(
        coreProductData?.productOptionType?.productCatRefId
      );

      console.log('new selected silhoutte funct', allowedSilhoutte);
      // setSilhouetteList(allowedSilhoutte);
    }
  }, [coreProductData]);

  useEffect(() => {
    console.log('Core Product Data based ', coreProductData);

    if (coreProductData && coreProductData?.id && silhouetteList) {
      console.log('in Core Product use effect ', coreProductData);

      setIsLoading(true);
      let fabricUtilizationAttributeArray = getFabricUtilizedAttributeArray(
        coreProductData?.fabricUtilizationDetails
      );

      setVariantList(
        coreProductData?.productVariantDetails?.map((variantInfo) => {
          return {
            variantRefId: variantInfo.variantRefId,
            variantSKU: variantInfo.variantSKU,
          };
        }) || []
      );

      console.log(
        coreProductData?.sizeVariantDetails?.map((size) => size?.sizeRefId)
          ?.length > 0
          ? coreProductData?.sizeVariantDetails?.map((size) => size?.sizeRefId)
          : false
          ? 'true hai'
          : productTypeList
              ?.filter((productType) => {
                console.log('in size setting', productTypeList, productType);
                return (
                  productType.id ==
                  coreProductData?.productOptionType?.productCatRefId
                );
              })[0]
              ?.productSizes?.map((size) => size?.sizeRefId),
        'checkoooo'
      );

      setEditData({
        month: coreProductData?.month,
        launchDrop: coreProductData?.launchDrop,
        qty: coreProductData?.quantity?.toString(),

        Sleeve: coreProductData?.sleeve,
        weave_structure: coreProductData?.weaveStructure,
        fashionCore: coreProductData?.fashionCore,
        description: coreProductData?.description,

        sku: coreProductData?.sku,
        new_Repeat: coreProductData?.new_Repeat,
        amrp: coreProductData?.amrp?.toString(),
        proj_disc: coreProductData?.proj_disc?.toString(),
        assumedMRP: coreProductData?.assumedMRP?.toString(),
        target_cogs: coreProductData?.target_cogs?.toString(),
        total_arv: coreProductData?.total_arv?.toString(),
        arv: coreProductData?.arv?.toString(),
        asp: coreProductData?.asp?.toString(),
        pp_slab: coreProductData?.pp_slab?.toString(),

        cogs: coreProductData?.cogs?.toString(),
        total_cogs: coreProductData?.total_cogs?.toString(),
        cogs_percentage: coreProductData?.cogs_percentage?.toString(),
        po_price: coreProductData?.po_price?.toString(),
        cost_of_finance: coreProductData?.cost_of_finance?.toString(),
        payment_modal: coreProductData?.payment_modal,
        supplier_master:
          coreProductData?.supplier_master?.supplierRefId || null,

        gsm: coreProductData?.gsm?.toString(),
        // lifeOccasion: coreProductData?.lifeOccasion,
        lifeOccasion:
          coreProductData?.lifeOccasion ??
          productTypeList?.filter(
            (productType) =>
              productType.id ==
              coreProductData?.productOptionType?.productCatRefId
          )[0]?.productLifeOccasion,
        attributionCategory: coreProductData?.attributionCategory,

        knit_woven: coreProductData?.knit_woven,
        freshness: coreProductData?.freshness,
        fabric_special_attribute: coreProductData?.fabric_special_attribute,
        weight: coreProductData?.weight,
        color_depth: coreProductData?.color_depth,
        designCategory: coreProductData?.designCategory,
        icons: coreProductData?.icons,

        status: coreProductData?.status,
        object_status_no: coreProductData?.object_status_no,
        object_status: coreProductData?.object_status,
        launch_masters: coreProductData?.launchDetails?.map(
          (launch) => launch.launchRefId
        ),

        patterns: coreProductData?.pattern?.map(
          (pattern) => pattern?.patternRefId
        ),
        collection: coreProductData?.collection.collectionRefId,
        product_option_type:
          coreProductData?.productOptionType?.productCatRefId,

        design_season:
          coreProductData?.designSeason?.designSeasonRefId ??
          launchMastersList.filter(
            (launch) =>
              launch.id == coreProductData?.launchDetails[0]?.launchRefId
          )[0]?.seasonRefId,
        launch_season:
          coreProductData?.launchSeason?.launchSeasonRefId ??
          launchMastersList.filter(
            (launch) =>
              launch.id == coreProductData?.launchDetails[0]?.launchRefId
          )[0]?.seasonRefId,
        color_family: coreProductData?.colorFamily?.colorFamilyRefId,

        silhouettes: coreProductData?.silhouetteDetails?.map(
          (silhouette) => silhouette.silhouetteRefId
        ),
        trends: coreProductData?.trendDetails?.map((trend) => trend.trendRefId),

        special_attributes: coreProductData?.specialAttributeDetails?.map(
          (specialAttr) => specialAttr.specialAttrRefId
        ),
        garment_special_attributes:
          coreProductData?.garmentSpecialAttributesDetails?.map(
            (specilaAttri) => {
              return specilaAttri.garmentSpecialAttributeRefId;
            }
          ),
        garment_special_attributes2:
          coreProductData?.garmentSpecialAttributes2Details?.map(
            (specialAttriObject) => {
              return specialAttriObject?.garmentSpecialAttributeRefId;
            }
          ),
        indent_sheets: coreProductData?.indentSheetDetails?.map(
          (indentSheet) => indentSheet.indentRefId
        ),
        techpacks: coreProductData?.techpackDetails?.map(
          (techpack) => techpack?.techpackRefId
        ),

        sub_color_lists: coreProductData?.colorVariantDetails?.map(
          (subColor) => subColor?.subColorRefId
        ),
        filter_color: coreProductData?.colorVariantDetails?.map(
          (subColor) => subColor?.filterColorId
        ),
        fabric_group: coreProductData?.fabricGroupDetails?.fabricGroupId,
        fits_list: coreProductData?.fitVariantDetails?.map(
          (fit) => fit?.fitRefId
        ),
        size_list:
          coreProductData?.sizeVariantDetails?.map((size) => size?.sizeRefId)
            ?.length > 0
            ? coreProductData?.sizeVariantDetails?.map(
                (size) => size?.sizeRefId
              )
            : false
            ? 'true hai'
            : productTypeList
                ?.filter((productType) => {
                  console.log('in size setting', productTypeList, productType);
                  return (
                    productType.id ==
                    coreProductData?.productOptionType?.productCatRefId
                  );
                })[0]
                ?.productSizes?.map((size) => size?.sizeRefId),
        fabric_used_for_products: fabricUtilizationAttributeArray,
        editId: coreProductData?.id,
      });

      coreProductData?.launchDetails?.launchRefId &&
        setSelectedLaunchNew(coreProductData?.launchDetails?.launchRefId); // for selecting related launch details in the form
    }

    setIsLoading(false);
  }, [coreProductData, silhouetteList]);

  // useEffect(() => {
  //   if (editData && coreProductData && launchMastersList?.length > 0) {
  //     console.log(
  //       'launchMastersList based ',
  //       coreProductData?.designSeason,
  //       coreProductData?.designSeason?.designSeasonRefId,
  //       coreProductData?.launchDetails,
  //       coreProductData?.launchDetails?.launchRefId,
  //       launchMastersList,
  //       launchMastersList.filter(
  //         (launch) =>
  //           launch.id == coreProductData?.launchDetails[0]?.launchRefId
  //       ),
  //       editData
  //     );
  //     let designSeason =
  //       coreProductData?.designSeason?.designSeasonRefId ??
  //       launchMastersList.filter(
  //         (launch) =>
  //           launch.id == coreProductData?.launchDetails[0]?.launchRefId
  //       )[0]?.seasonRefId;

  //     let launchSeason =
  //       coreProductData?.launchSeason?.launchSeasonRefId ??
  //       launchMastersList.filter(
  //         (launch) =>
  //           launch.id == coreProductData?.launchDetails[0]?.launchRefId
  //       )[0]?.seasonRefId;

  //     setEditData({
  //       ...editData,
  //       design_season: designSeason,
  //       launch_season: launchSeason,
  //     });
  //   }
  // }, [launchMastersList]);

  // useEffect(() => {
  //   console.log(
  //     'product option type List based ',

  //     productTypeList.filter(
  //       (productType) =>
  //         productType.id == coreProductData?.productOptionType?.productCatRefId
  //     ),
  //     editData
  //   );

  //   if (editData && productTypeList?.length > 0) {
  //     let lifeOccasion =
  //       coreProductData?.lifeOccasion ??
  //       productTypeList?.filter(
  //         (productType) =>
  //           productType.id ==
  //           coreProductData?.productOptionType?.productCatRefId
  //       )[0]?.productLifeOccasion;

  //     // let launchSeason =
  //     //   location?.state?.launchSeason?.launchSeasonRefId ??
  //     //   launchMastersList.filter(
  //     //     (launch) =>
  //     //       launch.id == location?.state?.launchDetails[0]?.launchRefId
  //     //   )[0]?.seasonRefId;

  //     setEditData({
  //       ...editData,
  //       lifeOccasion: lifeOccasion,
  //     });
  //   }
  // }, [productTypeList]);

  useEffect(() => {
    console.log(editData, dataform, '121');
    setIsLoading(true);
    dataform.setFieldsValue(editData);
    setIsLoading(false);
  }, [editData, dataform]); // set form fields on change in editdata or dataform.

  // useEffect(() => {
  //   if (selectedLaunchNew) {
  //     console.log(selectedLaunchNew, 'SelectedLaunchNew');
  //   }
  // }, [selectedLaunchNew]); // no use useEffect

  // useEffect(() => {
  //   if (derivedAttributes) {
  //     console.log(
  //       'automationTesting',
  //       location?.state?.designSeason,
  //       location?.state?.designSeason?.designSeasonRefId,
  //       location?.state?.launchDetails,
  //       location?.state?.launchDetails?.launchRefId,
  //       launchMastersList
  //     );
  //   }
  // }, [derivedAttributes]);

  if (isLoading1) {
    return <Spin size="large" />;
  }

  //Other Form Details
  const setFabricDetails = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/fabrics/1`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      // optionsData2 = optionsData.data.map((entry) => {
      //   return {
      //     id: entry.id,
      //     launchName: entry.attributes.launchName,
      //     launchDate: entry.attributes.launchDate,
      //     launchDrop: entry.attributes.launchDrop,
      //     //   launchCat: entry.attributes.launchCat,

      //     seasonName: entry.attributes.season?.data?.attributes.season_id,
      //     // launch_masters: entry.attributes.launch_masters,
      //   };
      // });

      // setLaunchMastersList(optionsData2);
      // console.log('original option Data:', optionsData);
      // console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const addNewRecord = async (data) => {
    setSaving(true);

    // design data
    const color_family = data['sub_color_lists'][0];

    //planning data
    const qty = parseFloat(data['qty']);
    const amrp = parseFloat(data['amrp']);
    const assumedMRP = parseFloat(data['assumedMRP']);
    const proj_disc = parseFloat(data['proj_disc']);
    const asp = parseFloat(data['asp']);
    const arv = parseFloat(data['arv']);

    // merchandising data
    const cogs = parseFloat(data['cogs']);
    const total_cogs = parseFloat(data['total_cogs']);
    const po_price = parseFloat(data['po_price']);
    const cost_of_finance = parseFloat(data['cost_of_finance']);
    const cogs_percentage = parseFloat(data['cogs_percentage']);
    const fabric_used_for_products = data.fabric_used_for_products.map(
      (fabricUtil) => {
        return {
          ...fabricUtil,

          consumptionPerUnit: parseFloat(fabricUtil.consumptionPerUnit),
          utilizationQty: parseFloat(fabricUtil.utilizationQty),
        };
      }
    );

    delete data['qty'];

    console.log('test data', data);
    // return;
    const values = {
      ...data,
      qty: qty,
      amrp: amrp,
      proj_disc: proj_disc,
      asp: asp,
      arv: arv,
      cogs: cogs,
      total_cogs: total_cogs,
      cost_of_finance: cost_of_finance,
      po_price: po_price,
      cogs_percentage: cogs_percentage,
      assumedMRP,
    };

    console.log('data:', data, color_family);

    data = { data: values };

    console.log('values:', values);

    console.log('data edit:', data.data.editId);
    // return;

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);
      console.log('Updated Data :', updatedValues);

      const checkData = data.data;
      const dataItemKeys = Object.keys(data.data);
      const updatedFieldsKeys = Object.keys(updatedValues);

      const updatedData1 = {};
      updatedFieldsKeys.forEach((key) => {
        updatedData1[key] = checkData[key];
      });

      // console.log(
      //   'data objec recived from user:',
      //   data,
      //   'core product object initial data',
      //   coreProductData,
      //   'data item keys: ',
      //   dataItemKeys,
      //   'updated DAta',
      //   updatedData1
      // );

      // const coreProductDataKeys = Object.keys(coreProductData);
      // const coreProductDataKeysLower1 = [];
      // const dataItemKeysLower1 = [];

      // coreProductDataKeys.forEach(
      //   (keys) => (coreProductDataKeysLower1[keys.toLowerCase()] = keys)
      // );

      // const coreProductDataKeysLower = coreProductDataKeys.map((keys) => [
      //   keys.toLowerCase(),
      //   keys,
      // ]);

      // const dataItemKeysLower = dataItemKeys.map((keys) => [
      //   keys.toLowerCase(),
      //   keys,
      // ]);

      // dataItemKeys.forEach(
      //   (keys) => (dataItemKeysLower1[keys.toLowerCase()] = keys)
      // );

      // console.log(
      //   '-- core product keys --',
      //   coreProductDataKeysLower,
      //   '-- dataItemKeysLower -- ',
      //   dataItemKeysLower,
      //   'core product lower 1 ',
      //   coreProductDataKeysLower1,
      //   'dataItemKeys lower 1',
      //   dataItemKeysLower1
      // );

      // const updatedData = [];
      // dataItemKeys.forEach((dataItemkey) => {
      //   console.log(dataItemkey.toLowerCase().trim());
      //   // if (coreProductData[dataItemkey] == checkData[dataItemkey]) {
      //   //   updatedData.push(checkData[dataItemkey]);
      //   // }
      // });
      // coreProductData.map((dataItemValue, dataItemKey) => {
      //   console.log('--- data item key value ---', dataItemValue, dataItemKey);
      // });

      // return;

      let updatedDataSent = { data: updatedData1 };
      console.log('updated data sent : ', updatedDataSent);
      // return;

      console.log(updatedDataSent);
      // return;

      try {
        const response = await fetch(
          `${API}/core-products/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(updatedDataSent),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          console.log(responseData.error);
          message.error(
            `Unable to Update Record. ${responseData?.error?.name} in ${responseData?.error?.details?.errors[0]?.path[0]}`
          );
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/core-products/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const toPreviousPage = () => {
    navigate(-1);
  };

  const toVariantDetailsPage = (variantdetails) => {
    const state = {
      variantId: variantdetails?.variantId,
      variantList: variantdetails?.variantList,
    };
    // console.log(state);
    navigate('/variantProductDetail', { state });
  };

  const launchListOptions = launchMastersList.map((launch) => {
    return {
      label: launch.launchName,
      value: launch.id,
    };
  });

  const seasonListOptions = seasonMastersList.map((season) => {
    return {
      label: season.seasonId,
      value: season.id,
    };
  });

  // console.log(colorList);
  const sizeListOptions = sizeList.map((size) => {
    return {
      label: size.sizeName,
      value: size.id,
    };
  });

  const fitListOptions = fitList.map((fit) => {
    return {
      label: fit.fitName,
      value: fit.id,
    };
  });

  const colorListOptions = colorList.map((color) => {
    return {
      label: color.colorName,
      value: color.id,
    };
  });

  const familyColorListOptions = familyColorList.map((color) => {
    return {
      label: color?.colorName,
      value: color?.id,
    };
  });

  const filterColorListOptions = filterColorList.map((color) => {
    return {
      label: color?.filterColorName,
      value: color?.id,
    };
  });

  const fabricGroupListOptions = fabricGroupList.map((fabricGroup) => {
    return {
      label: fabricGroup?.fabricGroupName,
      value: fabricGroup?.id,
    };
  });

  const silhouetteListOptions = silhouetteList.map((silhouette) => {
    return {
      label: silhouette?.silhouetteName,
      value: silhouette?.id,
    };
  });

  const specialAttributeListOptions = specialAttributeList.map(
    (specialAttribute) => {
      return {
        label: specialAttribute?.specialAttributeName,
        value: specialAttribute?.id,
      };
    }
  );

  const trendListOptions = trendList.map((trend) => {
    return {
      label: trend?.trendName,
      value: trend?.id,
    };
  });

  const patternListOption = patternList.map((pattern) => {
    return {
      label: pattern.patternName,
      value: pattern.id,
    };
  });

  const collectionListOption = collectionList.map((collection) => {
    return {
      label: collection.collectionName,
      value: collection.id,
    };
  });

  const monthListOptions = monthNames.map((month, index) => {
    return {
      label: month,
      value: month,
    };
  });
  console.log('355', monthListOptions);

  const ProductTypeOptions = productTypeList.map((productType) => {
    return {
      label: productType.productCategoryName,
      value: productType.id,
    };
  });
  const FabricList = fabricList.map((fabric) => {
    return {
      label: fabric.fabricName,
      value: fabric.id,
    };
  });
  const SupplierList = supplierList.map((supplier) => {
    return {
      label: supplier.supplierName,
      value: supplier.id,
    };
  });

  const CoreProductStatusList = coreProductStatusList
    .filter((objectStatus) => !objectStatus.hide)
    .map((statusObject) => {
      return {
        label: statusObject.object_status,
        value: statusObject.object_status_no,
      };
    });

  const attributionCategoryList = attributionCategoryOptionArray.map(
    (value) => {
      return {
        label: toProperCase(value),
        value: value,
      };
    }
  );

  const paymentModalOptionList = paymentModelOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const tnaStatusOptionList = tnaStatusOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const fabricApplicationTypeList = fabricApplicationTypeArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const sleeveOptionList = sleeveOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const weaveStructureOptionList = weaveStructureOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const freshnessOptionList = freshnessOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const fabricSpecialAttributeOptionList =
    fabricSpecialAttributeOptionArray.map((value) => {
      return {
        label: toProperCase(value),
        value: value,
      };
    });

  const knitWovenOptionList = knitWovenOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const weightOptionList = weightOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const colorDepthOptionList = colorDepthOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const lifeOccasionOptionList = lifeOccasionOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const designCategoryOptionList = designCategoryOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  console.log(CoreProductStatusList, 'status list');
  const IndentSheetList = indentSheetList.map((indentSheet) => {
    // console.log(indentSheet, 'line no 339');
    return {
      label: indentSheet.Indent_file_reference_id,
      value: indentSheet.id,
    };
  });
  const TechPackList = techPackList.map((techpack) => {
    // console.log(indentSheet, 'line no 339');
    return {
      label: techpack.techpack_file_reference_id,
      value: techpack.id,
    };
  });

  const updateDependentField = (value) => {
    let selectedLaunchArray = [];

    console.log('Updating', value, launchMastersList);
    value.map((launchId) => {
      const selectedLaunch = launchMastersList.filter((launch) => {
        return launch.id === launchId && launch;
      });
      selectedLaunchArray.push(...selectedLaunch);
    });

    console.log(selectedLaunchArray);
    setEditData({
      ...editData,
      launch_masters: selectedLaunchArray.map((launch) => launch.id),
      launchDrop: selectedLaunchArray[0]?.launchDrop,
    });
  };
  const updateConsumptionPUField = (value, name) => {
    console.log(name, 'sjflkdjfkl');
    const fields = dataform.getFieldsValue();
    const { fabric_used_for_products, qty } = fields;

    // fabric_used_for_products;

    if (!(parseFloat(value.target.value) && parseFloat(qty))) {
      return;
    }
    const utilizationQtyOfFabric =
      parseFloat(value.target.value) * parseFloat(qty) || 0.0;
    console.log(utilizationQtyOfFabric);

    Object.assign(fabric_used_for_products[name], {
      utilizationQty: utilizationQtyOfFabric,
    });

    // dataform.setFieldsValue({ fabric_used_for_products });
    setEditData({ ...fields, fabric_used_for_products });

    console.log(
      fields,
      name,
      value,
      value.target.value,
      qty,
      parseFloat(value.target.value),
      parseFloat(qty),
      fabric_used_for_products
    );
  };

  // ---------------
  const updateFabricFields = (value, name) => {
    console.log(name, 'Fabric sjflkdjfkl');
    const fields = dataform.getFieldsValue();
    const { fabric_used_for_products, qty } = fields;

    console.log(name, 'Fabric value', value);

    // get the specific fabric
    let selectedFabric = getFabricDetailsByID(value);
    console.log(
      selectedFabric,
      'selected Fabric',
      fabric_used_for_products,
      'fabric used for product'
    );

    // fabric_used_for_products;

    // if (!(parseFloat(value.target.value) && parseFloat(qty))) {
    //   return;
    // }
    // const utilizationQtyOfFabric =
    //   parseFloat(value.target.value) * parseFloat(qty) || 0.0;
    // console.log(utilizationQtyOfFabric);

    Object.assign(fabric_used_for_products[name], {
      fabricCode: selectedFabric.fabricCode,

      fabricComposition: selectedFabric.fabricComposition,
      countAndConstruction: selectedFabric.countAndConstruction,
      weight: selectedFabric.weight,
      fabric_uom_symbol: selectedFabric.fabric_uom_symbol,
    });

    // dataform.setFieldsValue({ fabric_used_for_products });
    setEditData({ ...fields, fabric_used_for_products });

    // console.log(
    //   fields,
    //   name,
    //   value,
    //   value.target.value,
    //   qty,
    //   parseFloat(value.target.value),
    //   parseFloat(qty),
    //   fabric_used_for_products
    // );
  };
  // ---------------

  const getFabricDetailsByID = (fabricID) => {
    let fabricDetails = fabricList.filter(
      (fabricItem) => fabricItem.id == fabricID
    );
    console.log(fabricDetails, fabricID, 'finding fabric info by id');
    return fabricDetails[0];
  };

  const getUOMforFabric = (fabricID) => {
    let fabricDetails = fabricList.filter(
      (fabricItem) => fabricItem.id == fabricID
    );
    console.log(fabricDetails, fabricID, 'finding uom for fabric id');
    return fabricDetails[0]?.uomDetails?.uom_measurement_symbol ?? null;
  };

  const handleValuesChange = (changedValues, allValues) => {
    console.log('Changed values:', changedValues);
    console.log('All values:', allValues);
    // setUpdatedValues({ ...changedValues, ...updatedValues });

    const derivedValues = {};
    if (changedValues) {
      if (changedValues?.po_price || changedValues?.qty) {
        const poPrice =
          parseFloat(changedValues?.po_price) ||
          parseFloat(allValues?.po_price) ||
          0;
        const qty =
          parseFloat(changedValues?.qty) || parseFloat(allValues?.qty) || 0;
        const cof = poPrice * qty;

        derivedValues.cost_of_finance = cof;
        // console.log(cof);
      }

      if (changedValues?.cogs || changedValues?.qty) {
        const cogp =
          parseFloat(changedValues?.cogs) || parseFloat(allValues?.cogs) || 0;
        const qty =
          parseFloat(changedValues?.qty) || parseFloat(allValues?.qty) || 0;

        const totalcogp = cogp * qty;

        derivedValues.total_cogs = totalcogp;
        // console.log(totalcogp);
      }

      if (changedValues?.fabric_used_for_products) {
        changedValues?.fabric_used_for_products?.forEach(
          (fabricUtils, index) => {
            if (fabricUtils) {
              if (fabricUtils?.fabric) {
                console.log('Fabric changed', fabricUtils?.fabric, index);
                let selectedFabric = getFabricDetailsByID(fabricUtils?.fabric);
                const fabricUtilizedObject =
                  allValues?.fabric_used_for_products;

                console.log(
                  selectedFabric,
                  'selected Fabric',
                  allValues?.fabric_used_for_products,
                  'fabric used for product old',
                  fabricUtilizedObject,
                  'new fabric used for product'
                );

                Object.assign(fabricUtilizedObject[index], {
                  fabricCode: selectedFabric.fabricCode,

                  fabricComposition: selectedFabric.fabricComposition,
                  countAndConstruction: selectedFabric.countAndConstruction,
                  weight: selectedFabric.weight,
                  fabric_uom_symbol: selectedFabric.fabric_uom_symbol,
                });

                console.log('sdfsdffd', fabricUtilizedObject);

                Object.assign(changedValues.fabric_used_for_products, {
                  ...fabricUtilizedObject,
                });
              } else {
                console.log(
                  'Else Part - -fabric_used_for_products ',
                  changedValues?.fabric_used_for_products
                );
                const fabricUtilizedObject =
                  allValues?.fabric_used_for_products;
                Object.assign(fabricUtilizedObject[index], {
                  ...changedValues?.fabric_used_for_products[index],
                });

                console.log(
                  'Else Part - fabricUtilizationObect created- ',
                  fabricUtilizedObject
                );

                Object.assign(changedValues.fabric_used_for_products, {
                  ...fabricUtilizedObject,
                });
              }
            } else {
            }
          }
        );
      }

      if (
        changedValues?.proj_disc ||
        changedValues?.amrp ||
        changedValues?.qty
      ) {
        const proj_disc =
          parseFloat(changedValues?.proj_disc) ||
          parseFloat(allValues?.proj_disc) ||
          0;
        const qty =
          parseFloat(changedValues?.qty) || parseFloat(allValues?.qty) || 0;
        const amrp =
          parseFloat(changedValues?.amrp) || parseFloat(allValues?.amrp) || 0;

        const cogp =
          parseFloat(changedValues?.cogs) || parseFloat(allValues?.cogs) || 0;
        const auto_asp = amrp - amrp * (proj_disc / 100) || 0;
        const auto_arv = (auto_asp / 112) * 100 || 0;
        const auto_total_arv = qty * auto_arv || 0;
        const auto_cogp_percent = (cogp / auto_arv) * 100 || 0;

        derivedValues.asp = auto_asp?.toFixed(2)?.toString();
        derivedValues.arv = auto_arv?.toFixed(2)?.toString();
        derivedValues.total_arv = auto_total_arv?.toFixed(2)?.toString();
        derivedValues.cogs_percentage = auto_cogp_percent
          ?.toFixed(2)
          ?.toString();
        console.log('auto calc INPUT:: ', proj_disc, qty, amrp);
        console.log('auto calc:: ', auto_asp, auto_arv, auto_total_arv);
      }

      if (changedValues?.cogs || changedValues?.arv) {
        const cogs =
          parseFloat(changedValues?.cogs) || parseFloat(allValues?.cogs) || 0;
        const arv =
          parseFloat(changedValues?.arv) || parseFloat(allValues?.arv) || 0;

        const auto_cogp_percent = (cogs / arv) * 100 || 0;

        derivedValues.cogs_percentage = auto_cogp_percent
          ?.toFixed(2)
          ?.toString();

        console.log('auto calc INPUT:: ', cogs, arv);
        console.log('auto calc:: ', auto_cogp_percent);
      }
      setUpdatedValues({
        ...changedValues,
        ...updatedValues,
        ...derivedValues,
      });
      setEditData({
        ...allValues,
        ...derivedValues,
        ...changedValues,
      });
    }
  };

  const removeExtraField = (index) => {
    const updatedProductOptionDetails = [...productOptionDetails];
    updatedProductOptionDetails.splice(index, 1);
    setProductOptionDetails(updatedProductOptionDetails);
  }; // not used
  // --------------------Indent Sheet------------

  const showModal = () => {
    setIsModalOpen(true);
    modalform.setFieldsValue({
      launch_master: dataform.getFieldValue('launch_masters')[0] ?? null,
      indent_file_date: moment(),
    });
  };

  const handleOk = () => {
    modalform.resetFields();
    // setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    modalform.resetFields();
    // setEditData(null);
    setIsModalOpen(false);
  };

  // --------------------Indent Sheet------------

  // --------------------TechPack----------
  const showModalTechPack = () => {
    // console.log(
    //   'in show tech pack modal',
    //   dataform.getFieldValue('launch_masters'),
    //   dataform.getFieldValue('launch_masters')[0],
    //   dataform.getFieldValue('sku')
    // );
    // console.log(editData, 609);
    // console.log(dataform, 610);
    // console.log(dataform.getFieldsValue(), 611);
    // let skuValue = dataform.getFieldsValue().sku;
    // modalformTechpack.setFieldsValue({ sku: skuValue });

    setIsModalOpenTechPack(true);
    modalformTechpack.setFieldsValue({
      launch_master: dataform.getFieldValue('launch_masters')[0] ?? null,
      techpack_file_date: moment(),
      sku: dataform?.getFieldValue('sku')?.slice(0, -2) ?? null,
    });
  };

  const handleOkTechPack = () => {
    modalformTechpack.resetFields();
    // setEditData(null);
    setIsModalOpenTechPack(false);
  };
  const handleCancelTechPack = () => {
    modalformTechpack.resetFields();
    // setEditData(null);
    setIsModalOpenTechPack(false);
  };
  // -------------------------

  // ----------------- fabric master add
  // --------------------TechPack----------
  const showModalFabricMaster = () => {
    // console.log(
    //   'in show tech pack modal',
    //   dataform.getFieldValue('launch_masters'),
    //   dataform.getFieldValue('launch_masters')[0],
    //   dataform.getFieldValue('sku')
    // );
    // console.log(editData, 609);
    // console.log(dataform, 610);
    // console.log(dataform.getFieldsValue(), 611);
    // let skuValue = dataform.getFieldsValue().sku;
    // modalformTechpack.setFieldsValue({ sku: skuValue });

    setIsModalOpenFabric(true);
  };

  const handleOkFabric = () => {
    modalformFabricMaster.resetFields();
    // setEditData(null);
    setIsModalOpenFabric(false);
  };
  const handleCancelFabric = () => {
    modalformFabricMaster.resetFields();
    // setEditData(null);
    setIsModalOpenFabric(false);
  };
  // -------------------------

  const addNewEntity = async (data) => {
    setSaving(true);
    const values = {
      ...data,
      core_products: editData?.editId ? editData.editId : null,
      // launch_master: editData?.launch_masters[0]
      //   ? editData?.launch_masters[0]
      //   : null,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data.data.editEntityId);

    if (data.data.editEntityId) {
      console.log('edit id modal entity :', data.data.editEntityId);

      try {
        const response = await fetch(
          `${API}/indent-sheets/${data.data.editEntityId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record.');
        } else {
          message.success('Data saved successfully!');
          fetchIndentSheetList(authToken);
          //   toPreviousPage();
          handleOk();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        // setEditData(null);
      }
    } else {
      // create a new record in the database table
      //   console.log('edit id :', data.editEntityId);

      try {
        const response = await fetch(`${API}/indent-sheets/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          fetchIndentSheetList(authToken);
          dataform.setFieldsValue({
            indent_sheets: [
              ...dataform?.getFieldValue('indent_sheets'),
              responseData?.data?.id,
            ],
          });
          handleOk();
          //   toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const addNewEntityTechPack = async (data) => {
    setSaving(true);
    const values = {
      ...data,
      core_products: editData?.editId ? editData.editId : null,
      techpack_file_reference_id: data?.sku ? data.sku : null,

      // launch_master: editData?.launch_masters[0]
      //   ? editData?.launch_masters[0]
      //   : null,
    };

    data = { data: values };

    console.log('techpack data:', data);
    console.log('techpack values:', values);

    // return;
    console.log('techpack data edit:', data.data.editEntityId_TechPack);

    if (data.data.editEntityId_TechPack) {
      console.log(
        'techpack edit id modal entity :',
        data.data.editEntityId_TechPack
      );

      try {
        const response = await fetch(
          `${API}/techpacks/${data.data.editEntityId_TechPack}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record.');
        } else {
          message.success('Data saved successfully!');
          fetchTechpackList(authToken);
          handleOkTechPack();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        // setEditData(null);
      }
    } else {
      // create a new record in the database table
      //   console.log('edit id :', data.editEntityId_TechPack);

      try {
        const response = await fetch(`${API}/techpacks/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          fetchTechpackList(authToken);
          dataform.setFieldsValue({
            techpacks: [
              ...dataform?.getFieldValue('techpacks'),
              responseData?.data?.id,
            ],
          });
          handleOkTechPack();
          //   toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };
  const addNewEntityFabricMaster = async (data) => {
    setSaving(true);
    const values = {
      ...data,
    };

    data = { data: values };

    console.log('fabric data:', data);
    console.log('fabric values:', values);

    // return;
    console.log('fabric data edit:', data.data.editEntityId_fabric);

    if (data.data.editEntityId_fabric) {
      console.log(
        'fabric edit id modal entity :',
        data.data.editEntityId_fabric
      );

      try {
        const response = await fetch(
          `${API}/fabrics/${data.data.editEntityId_fabric}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record.');
        } else {
          message.success('Data saved successfully!');
          fetchFabricList(authToken);
          handleOkFabric();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        // setEditData(null);
      }
    } else {
      // create a new record in the database table
      //   console.log('edit id :', data.editEntityId_fabric);

      try {
        const response = await fetch(`${API}/fabrics/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          fetchFabricList(authToken);
          // dataform.setFieldsValue({
          //   fabrics: [
          //     ...dataform?.getFieldValue('fabrics'),
          //     responseData?.data?.id,
          //   ],
          // });
          handleOkFabric();
          //   toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const generateVariants = async () => {
    const fields = dataform.getFieldsValue();
    const {
      sub_color_lists,
      fits_list,
      size_list,
      assumedMRP,
      cogs,
      sku,
      editId,
    } = fields;
    console.log(sub_color_lists, fits_list, size_list);
    const variantsDetails = {
      colorsList: sub_color_lists,
      fitsList: fits_list,
      sizeList: size_list,
      mrp: assumedMRP,
      cost: cogs,
      sku: sku,
      coreProductId: editId,
    };

    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/core-product/generate-variant-products`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            data: { variantsDetails },
          }),
        }
      );
      const responseData = await response.json();
      console.log('variant created', responseData);
      const variantsCreated = responseData?.data?.completeSuccess;
      console.log(variantsCreated);
      // setVariantList(
      //   variantsCreated?.map((variantInfo) => {
      //     console.log(variantInfo);
      //     return {
      //       variantRefId: variantInfo.id,
      //       variantSKU: variantInfo.variant_sku,
      //     };
      //   }) || []
      // );
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        message.success(`Product Variants for ${sku} Successfully Created`);
        toPreviousPage();
        // fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const uomOptionList = unitOfMeasurementList?.map((uom) => {
    return {
      label: toProperCase(uom?.measurement_name),
      value: uom?.id,
    };
  });

  return (
    <div>
      {!coreProductData ? (
        <Spin size="large" />
      ) : (
        <>
          <div className="infoPage_actionButton">
            <Typography.Title level={2}>Product Details</Typography.Title>
            {/*<div>Core Product Details</div> */}
            <Button type="link" onClick={toPreviousPage}>
              Back
              <RollbackOutlined />
            </Button>
          </div>

          <div>
            <Form
              form={dataform}
              layout="vertical"
              onFinish={addNewRecord}
              onValuesChange={handleValuesChange}
              scrollToFirstError={{
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
              }}
            >
              <Row gutter={[16, 16]}>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Launch Master"
                    name="launch_masters"
                    rules={[
                      {
                        required: true,
                        message: 'Launch is Required!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      onChange={updateDependentField}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={launchListOptions}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Month"
                    name="month"
                    rules={[
                      {
                        required: true,
                        message: 'Month is Required!',
                      },
                    ]}
                  >
                    {<Select options={monthListOptions} />}
                    {/*<Input placeholder="Month" />*/}
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Product Option Type/Category"
                    name="product_option_type"
                    rules={[
                      {
                        required: true,
                        message: 'product Option Type is Required!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={ProductTypeOptions}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Quantity"
                    name="qty"
                    rules={[
                      {
                        // required: true,
                        message: 'Please enter a valid quantity',
                        pattern: '^([0-9]{0,10})$',
                      },
                    ]}
                  >
                    <Input placeholder="Quantity" />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Status" name="status" initialValue="active">
                    <Select placeholder="Select Status" disabled={true}>
                      <Select.Option value="active">Acitve</Select.Option>
                      <Select.Option value="inactive">In Acitve</Select.Option>
                      <Select.Option value="wip">WIP</Select.Option>
                      <Select.Option value="droped">Droped</Select.Option>
                      <Select.Option value="complete">complete</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Product Status" name="object_status_no">
                    <Select
                      disabled={true}
                      placeholder="Select "
                      optionFilterProp="children"
                      options={CoreProductStatusList}
                    />
                  </Form.Item>
                </Col>
                <Divider orientation="left">Variant Information</Divider>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Colours"
                    name="sub_color_lists"
                    rules={[
                      {
                        required: true,
                        message: 'Color is Required!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={colorListOptions}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Fits"
                    name="fits_list"
                    rules={[
                      {
                        required: true,
                        message: 'fit is Required!',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={fitListOptions}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Size"
                    name="size_list"
                    // rules={[
                    //   {
                    // required: true,
                    // message: 'size is Required!',
                    //   },
                    // ]}
                  >
                    <Select
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={sizeListOptions}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                {variantList?.length === 0 && (
                  <Button type="default" onClick={generateVariants}>
                    Generate Variants
                  </Button>
                )}
                {console.log('variant list', variantList)}
                {variantList.length > 0 && (
                  <div>
                    <ul>
                      {' '}
                      {variantList.map((variant) => {
                        console.log('variant details', variant);
                        return (
                          <li>
                            <a
                              onClick={() => {
                                toVariantDetailsPage({
                                  variantId: variant?.variantRefId,
                                  variantList: variantList,
                                });
                              }}
                            >
                              {variant?.variantSKU}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <Divider orientation="left">Design Data</Divider>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Fashion/Core" name="fashionCore">
                    <Select
                      placeholder="Select "
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                    >
                      <Select.Option value="Fashion">Fashion</Select.Option>
                      <Select.Option value="Core">Core</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item name="sku" label="SKU">
                    <Input
                      placeholder="SKU"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item name="new_Repeat" label="New/Repeat">
                    <Select
                      placeholder="Select "
                      defaultValue={'new'}
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                    >
                      <Select.Option value="new">New</Select.Option>
                      <Select.Option value="repeat">Repeat</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Description" name="description">
                    <TextArea
                      rows={2}
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                    {/* <Input placeholder="Enter Description" /> */}
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Collection" name="collection">
                    <Select
                      placeholder="Enter Collection"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={collectionListOption}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Color Family" name="color_family">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={colorListOptions}
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Filter Color Grouping" name="filter_color">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={filterColorListOptions}
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Fabric Group" name="fabric_group">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={fabricGroupListOptions}
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Assumed MRP"
                    name="assumedMRP"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      placeholder="Enter Assumed MRP"
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Design Season" name="design_season">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={seasonListOptions}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Launch Season" name="launch_season">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={seasonListOptions}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Life Occasion" name="lifeOccasion">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={lifeOccasionOptionList}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Sleeve" name="Sleeve">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={sleeveOptionList}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Weave/Structure" name="weave_structure">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={weaveStructureOptionList}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Silhouettes" name="silhouettes">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={silhouetteListOptions}
                      mode="single"
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Design Category" name="designCategory">
                    <Select
                      placeholder="Select "
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={designCategoryOptionList}
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Pattern" name="patterns">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={patternListOption}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Knit/Woven" name="knit_woven">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={knitWovenOptionList}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Freshness" name="freshness">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={freshnessOptionList}
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Special Attributes"
                    name="special_attributes"
                  >
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={specialAttributeListOptions}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Garment Special Attributes"
                    name="garment_special_attributes"
                  >
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={specialAttributeListOptions}
                      mode="single"
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Garment Special Attributes 2"
                    name="garment_special_attributes2"
                  >
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={specialAttributeListOptions}
                      mode="single"
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Fabric Special Attribute"
                    name="fabric_special_attribute"
                  >
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={fabricSpecialAttributeOptionList}
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Trends" name="trends">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={trendListOptions}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Attribution Category"
                    name="attributionCategory"
                  >
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={attributionCategoryList}
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Icons" name="icons">
                    <Select
                      placeholder="Select Icons"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                    >
                      <Select.Option value="Fashion">Fashion</Select.Option>
                      <Select.Option value="Core">Core</Select.Option>
                      <Select.Option value="Experimental">
                        Experimental
                      </Select.Option>
                      <Select.Option value="Indian">Indian</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Weight" name="weight">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={weightOptionList}
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Color Depth" name="color_depth">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.designAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {}}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={colorDepthOptionList}
                    />
                  </Form.Item>
                </Col>

                <Divider orientation="left">Planning Data</Divider>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="AMRP"
                    name="amrp"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter AMRP"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.planningAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Projected Discount (%)"
                    name="proj_disc"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Projected Discount"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.planningAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="ASP"
                    name="asp"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter ASP"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.planningAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="ARV"
                    name="arv"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter ARV"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.planningAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Total ARV"
                    name="total_arv"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Total ARV"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.planningAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="PP Slab" name="pp_slab">
                    <Input
                      placeholder="Enter PP Slabs"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.planningAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                {/* ---- Merchendising - section ---- */}
                <Divider orientation="left">Merchandising Data </Divider>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Target COGP"
                    name="target_cogs"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Target COGP"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.merchAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Factory" name="supplier_master">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.merchAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={SupplierList}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="COGP"
                    name="cogs"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter COGP"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.merchAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Total COGP"
                    name="total_cogs"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Total COGP"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.merchAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="COGP %"
                    name="cogs_percentage"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter COGP %"
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.merchAttribute.includes(
                            role
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="PO Price"
                    name="po_price"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.merchAttribute.includes(
                            role
                          )
                        )
                      }
                      placeholder="Enter PO Price."
                    />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Cost Of Finance"
                    name="cost_of_finance"
                    rules={[
                      {
                        message: 'Please enter a correct value',
                        pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                        message:
                          'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                      },
                    ]}
                  >
                    <Input
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.merchAttribute.includes(
                            role
                          )
                        )
                      }
                      placeholder="Enter PO Price."
                    />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item label="Payment Modal" name="payment_modal">
                    <Select
                      disabled={
                        !user.rolesAssigned.find((role) =>
                          userRolesAllowedForAttributes.edit.merchAttribute.includes(
                            role
                          )
                        )
                      }
                      showSearch
                      onSearch={(value) => {
                        // console.log('searching...', value);
                      }}
                      placeholder="Select "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={paymentModalOptionList}
                    />
                  </Form.Item>
                </Col>
                <Divider orientation="left">Common Data </Divider>
                <Divider orientation="left">Indent Attachment </Divider>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <div className="addNewMasterNSelect">
                    <Form.Item
                      label="Indent Sheets"
                      name="indent_sheets"
                      className="SelectEntity"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        onSearch={(value) => {
                          // console.log('searching...', value);
                        }}
                        placeholder="Select "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.trim().toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={IndentSheetList}
                      />
                    </Form.Item>
                    <PlusOutlined
                      width={'20px'}
                      onClick={showModal}
                      className="addIconForNewEntity"
                    />
                  </div>
                </Col>
              </Row>

              <Divider orientation="left">Tech Pack Attachment</Divider>
              <Row>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <div className="addNewMasterNSelect">
                    <Form.Item
                      label="Tech Pack"
                      name="techpacks"
                      className="SelectEntity"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        onSearch={(value) => {
                          // console.log('searching...', value);
                        }}
                        placeholder="Select "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.trim().toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={TechPackList}
                      />
                    </Form.Item>
                    <PlusOutlined
                      width={'20px'}
                      onClick={showModalTechPack}
                      className="addIconForNewEntity"
                    />
                  </div>
                </Col>
              </Row>

              <Divider orientation="left">Fabric Selection</Divider>
              <Form.List name="fabric_used_for_products">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            margin: '20px',
                          }}
                        >
                          <div class="addNewMasterNSelectInside">
                            <Form.Item
                              {...restField}
                              style={{ minWidth: '300px', marginRight: '15px' }}
                              label="Quality / Fabric Article Name"
                              name={[name, 'fabric']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Fabric Master is Required!',
                                },
                              ]}
                            >
                              <Select
                                disabled={
                                  !user.rolesAssigned.find((role) =>
                                    userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                      role
                                    )
                                  )
                                }
                                showSearch
                                onSearch={(value) => {
                                  // console.log('searching...', value);
                                }}
                                placeholder="Select "
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.trim().toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '')
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? '').toLowerCase()
                                    )
                                }
                                options={FabricList}
                              />
                            </Form.Item>
                            <PlusOutlined
                              width={'14px'}
                              onClick={showModalFabricMaster}
                              className="addIconForNewEntity"
                            />
                          </div>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Fabric Code"
                            name={[name, 'fabricCode']}
                          >
                            <Input disabled={true} />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Fabric Composition"
                            name={[name, 'fabricComposition']}
                          >
                            <Input disabled={true} />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Fabric Count And Construction"
                            name={[name, 'countAndConstruction']}
                          >
                            <Input disabled={true} />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Weight"
                            name={[name, 'weight']}
                          >
                            <Input disabled={true} />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Mill"
                            name={[name, 'supplier_master']}
                            rules={[
                              {
                                required: true,
                                message: 'Factory is Required!',
                              },
                            ]}
                          >
                            <Select
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              showSearch
                              onSearch={(value) => {
                                // console.log('searching...', value);
                              }}
                              placeholder="Select "
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.trim().toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '')
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? '').toLowerCase()
                                  )
                              }
                              options={SupplierList}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Fabric Book Date"
                            name={[name, 'tnaStartDate']}
                            rules={[
                              {
                                type: 'object',
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              format={dateFormat}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Fabric Expected Date"
                            name={[name, 'tnaTentativeDate']}
                            rules={[
                              {
                                type: 'object',
                                message: 'Tentative Receiving Date is required',
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              format={dateFormat}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Fabric Timeline Status"
                            name={[name, 'tnaStatus']}
                            initialValue="active"
                          >
                            <Select
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              placeholder="Select "
                              options={tnaStatusOptionList}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Actual Fabric Recived Date"
                            name={[name, 'actualEndDate']}
                            rules={[
                              {
                                type: 'object',
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              format={dateFormat}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Fabric Appliction"
                            name={[name, 'fabric_role']}
                            initialValue="shell"
                            rules={[
                              {
                                required: true,
                                message: 'Fabric Role must be Specified!',
                              },
                            ]}
                          >
                            <Select
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              placeholder="Select "
                              options={fabricApplicationTypeList}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Consumption Per Unit"
                            name={[name, 'consumptionPerUnit']}
                            onChange={(e) => updateConsumptionPUField(e, name)}
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp(
                                  /^([0-9]{0,10}((.)[0-9]{0,4}))$/
                                ),
                                message:
                                  'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Consumption Per Unit"
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="utilization Qty"
                            name={[name, 'utilizationQty']}
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp(
                                  /^([0-9]{0,10}((.)[0-9]{0,4}))$/
                                ),
                                message:
                                  'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                                message:
                                  'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Utilization Quantity"
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Unit Of Measurement"
                            name={[name, 'fabric_uom_symbol']}
                          >
                            <Input disabled={true} />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Available Quantity (As per PO issued)"
                            name={[name, 'availableQuantity']}
                          >
                            <Input
                              placeholder="Available Quantity"
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                            />
                          </Form.Item>

                          <Form.Item
                            label="fupId"
                            hidden={true}
                            name="fupId"
                            initialValue={0}
                          >
                            <Input type="text" />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label="tnaType"
                            hidden={true}
                            name={[name, 'tnaType']}
                            initialValue="fabric"
                          >
                            <Select placeholder="Select Type">
                              <Select.Option value="product">
                                Product
                              </Select.Option>
                              <Select.Option value="fabric">
                                Fabric
                              </Select.Option>
                              <Select.Option value="pss">PSS</Select.Option>
                              <Select.Option value="photoshoot">
                                Photo Shoots
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label="tnaId"
                            hidden={true}
                            name={[name, 'tnaId']}
                            initialValue={0}
                          >
                            <Input type="text" />
                          </Form.Item>

                          {user.rolesAssigned.find((role) =>
                            userRolesAllowedForAttributes.edit.merchAttribute.includes(
                              role
                            )
                          ) ? (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          ) : null}
                        </Space>
                        <Divider orientation="left"></Divider>
                      </>
                    ))}

                    <Form.Item label="Add Fabrics Utilized">
                      <Tooltip title="Add Fabrics Utilized">
                        <Button
                          onClick={() => add()}
                          shape="circle"
                          icon={<PlusCircleOutlined />}
                          disabled={
                            !user.rolesAssigned.find((role) =>
                              userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                role
                              )
                            )
                          }
                        />
                      </Tooltip>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* hidden items*/}
              <Form.Item hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
              <Form.Item hidden={true} name="launchDrop">
                <Input type="text" />
              </Form.Item>
              <Form.Item hidden={true} name="category">
                <Input type="text" />
              </Form.Item>
              {/* hidden items*/}
              <Button
                className="addProduct_save_btn"
                htmlType="submit"
                type="primary"
                size="large"
              >
                {saving ? (
                  <>
                    <Spin size="small" /> Saving
                  </>
                ) : (
                  'Save'
                )}
              </Button>
            </Form>
          </div>
          <Modal
            title="Add New Indent Sheet"
            visible={isModalOpen}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
          >
            <Card className="addProduct_page_card">
              <Form
                form={modalform}
                layout="vertical"
                onFinish={addNewEntity}
                disabled={
                  !user.rolesAssigned.find((role) =>
                    userRolesAllowedForAttributes.edit.designAttribute.includes(
                      role
                    )
                  )
                }
              >
                <Row gutter={[16, 16]}>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Indent Sheet Link"
                      name="Indent_file_link"
                      rules={[
                        {
                          required: true,
                          message: 'Indent Sheet Link is Required!',
                          type: 'string',
                        },
                      ]}
                    >
                      <Input placeholder="Indent Sheet URL" />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Indent File Date"
                      name="indent_file_date"
                      rules={[
                        {
                          required: true,
                          message: 'Indent Sheet Date is Required!',
                          type: 'object',
                        },
                      ]}
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Indent Reference"
                      name="Indent_file_reference_id"
                      rules={[
                        {
                          type: 'string',
                        },
                      ]}
                    >
                      <Input placeholder="Indent Reference ID" />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item name="indent_type" label="Indent Type">
                      <Select
                        placeholder="Select Indent Type"
                        options={[
                          { value: 'Print', label: 'Print' },
                          { value: 'Fabric', label: 'Fabric' },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Launch Master"
                      name="launch_master"
                      rules={[
                        {
                          required: true,
                          message: 'Launch is Required!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onSearch={() => {
                          //
                        }}
                        placeholder="Select "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.trim().toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={launchListOptions}
                      />
                    </Form.Item>
                  </Col>

                  <Form.Item label="Username" hidden={true} name="editEntityId">
                    <Input type="text" />
                  </Form.Item>
                </Row>
                <Button
                  className="addProduct_save_btn"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  {saving ? (
                    <>
                      <Spin size="small" /> Saving
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Form>
            </Card>
          </Modal>
          <Modal
            title="Add New Techpack"
            visible={isModalOpenTechPack}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={handleOkTechPack}
            onCancel={handleCancelTechPack}
            width={1000}
          >
            <Card className="addProduct_page_card">
              <Form
                form={modalformTechpack}
                layout="vertical"
                onFinish={addNewEntityTechPack}
                disabled={
                  !user.rolesAssigned.find((role) =>
                    userRolesAllowedForAttributes.edit.designAttribute.includes(
                      role
                    )
                  )
                }
              >
                <Row gutter={[16, 16]}>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Techpack File Link "
                      name="techpack_file_link"
                      rules={[
                        {
                          required: true,
                          message: 'Techpack File Link is Required!',
                          type: 'string',
                        },
                      ]}
                    >
                      <Input placeholder="Tech Pack URL" />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Techpack File Date"
                      name="techpack_file_date"
                      rules={[
                        {
                          required: true,
                          message: 'Techpack Date is Required!',
                          type: 'object',
                        },
                      ]}
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      name="sku"
                      label="Master SKU"
                      rules={[
                        {
                          required: true,
                          message: 'SKU is Required',
                        },
                      ]}
                    >
                      <Input placeholder="SKU" />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Launch Master"
                      name="launch_master"
                      rules={[
                        {
                          required: true,
                          message: 'Launch is Required!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onSearch={() => {
                          //
                        }}
                        placeholder="Select "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.trim().toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={launchListOptions}
                      />
                    </Form.Item>
                  </Col>

                  <Form.Item
                    label="Username"
                    hidden={true}
                    name="editEntityId_TechPack"
                  >
                    <Input type="text" />
                  </Form.Item>
                </Row>
                <Button
                  className="addProduct_save_btn"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  {saving ? (
                    <>
                      <Spin size="small" /> Saving
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Form>
            </Card>
          </Modal>

          <Modal
            title="Add New Fabric"
            visible={isModalOpenFabric}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={handleOkFabric}
            onCancel={handleCancelFabric}
            width={1000}
          >
            <Card className="addProduct_page_card">
              <Form
                form={modalformFabricMaster}
                layout="vertical"
                onFinish={addNewEntityFabricMaster}
                disabled={
                  !user.rolesAssigned.find((role) =>
                    userRolesAllowedForAttributes.edit.merchAttribute.includes(
                      role
                    )
                  )
                }
              >
                <Row gutter={[16, 16]}>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Fabric Article Name"
                      name="fabricName"
                      rules={[
                        {
                          required: true,
                          message: 'Fabric is Required!',
                        },
                      ]}
                    >
                      <Input placeholder="Fabric Article Name" />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Fabric Code"
                      name="fabricCode"
                      rules={[
                        {
                          required: true,
                          message: 'Fabric Code is Required!',
                        },
                      ]}
                    >
                      <Input placeholder="Fabric Code" />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Fabric Composition"
                      name="fabricComposition"
                      rules={[
                        {
                          type: 'string',
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Fabric Composition" />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Count And Construction"
                      name="countAndConstruction"
                      rules={[
                        {
                          type: 'string',
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Count And Construction " />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Fabric Price"
                      name="fabricPrice"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a correct value',
                          pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                          message:
                            'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                        },
                      ]}
                    >
                      <Input placeholder="Fabric Price" />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Weight"
                      name="weight"
                      rules={[
                        {
                          message: 'Please enter a correct value',
                          pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,4}))$/),
                          message:
                            'Please enter a valid number, max decimal points is 4 e.g., 1000.0001',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Weight" />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Fresh or Stock"
                      name="inventoryState"
                      rules={[
                        {
                          required: true,
                          message: 'Inventory Status is Required!',
                        },
                      ]}
                    >
                      <Select placeholder="Select Inventory State">
                        <Select.Option value="fresh">Fresh</Select.Option>
                        <Select.Option value="stock">Stock</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Measurement Unit"
                      name="unit_of_measurement"
                      rules={[
                        {
                          required: true,
                          message: 'Measurement Unit is Required!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onSearch={(value) => {
                          // console.log('searching...', value);
                        }}
                        placeholder="Select "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.trim().toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={uomOptionList}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Fabric Group"
                      name="fabric_group"
                      rules={[
                        {
                          required: true,
                          message: 'Fabric Group is Required!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onSearch={(value) => {
                          // console.log('searching...', value);
                        }}
                        placeholder="Select "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.trim().toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={fabricGroupListOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    label="Username"
                    hidden={true}
                    name="editEntityId_fabric"
                  >
                    <Input type="text" />
                  </Form.Item>
                </Row>
                <Button
                  className="addProduct_save_btn"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  {saving ? (
                    <>
                      <Spin size="small" /> Saving
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Form>
            </Card>
          </Modal>
        </>
      )}
    </div>
  );
};

export default CoreProductDetails;
