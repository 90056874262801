import React from 'react';
import DataTable from '../../components/DataTable/DataTable';
import EditableDataTable from '../../components/EditableDataTable/EditableDataTable';
import CityMasterSimple from '../CityMaster/CityMasterSimple';
import CityMasterSimple_1 from '../CityMaster/CityMasterSimple_1';
import CityMaster1 from '../CityMaster/CityMaster1';
const TestPage = () => {
  return (
    <div>
      <h1>This is A Testing Page</h1>
      {/*<CityMasterSimple_1 /> */}
      {/*<CityMasterSimple /> */}
      <CityMaster1 />

      {/* <EditableDataTable /> */}
    </div>
  );
};

export default TestPage;
