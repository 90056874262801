import { Outlet } from "react-router-dom"
import { Col, Layout, Row } from "antd";
import AppHeader from "./Appheader/Appheader";
import AppRoutes from "../Routes";
import SiderMenu from "./SiderMenu/SiderMenu";
import { getToken } from "../helpers";

const { Header, Content } = Layout;
const CustomLayout = () => {
    return (
        <>
            {getToken() ? (<Layout className="main-container">

                <SiderMenu />
                <Layout>
                    <Row gutter={[0, 32]}>
                        <Col span={24}>
                            <Header>
                                <AppHeader />
                            </Header>
                        </Col>
                        <Col span={22} offset={1}>
                            <Content>
                                <Outlet />
                            </Content>
                        </Col>
                    </Row>
                </Layout>

            </Layout>) : (<Layout className="main-container">

                <Layout>
                    <Row gutter={[0, 32]}>
                        <Col span={24}>
                            <Header>
                                <AppHeader />
                            </Header>
                        </Col>
                        <Col span={22} offset={1}>
                            <Content>
                                <Outlet />
                            </Content>
                        </Col>
                    </Row>
                </Layout>

            </Layout>)
            }
        </>)
}


export default CustomLayout;